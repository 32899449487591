import { FC } from "react";

interface Props {
  header: string;
  components: {
    component: JSX.Element;
    condition: boolean;
    label: string;
  }[];
  newData?: boolean;
}

const EditContextWrapper: FC<Props> = ({
  header,
  components,
  newData = false,
}) => {
  const filteredComponents = components.filter((config) => config.condition);

  return (
    <div className="w-full">
      <div className="flex flex-row items-center gap-2 mt-4">
        <p className="title-h3 text-[#545b78]">{header}</p>
      </div>

      {filteredComponents.map((filteredComponent, index) => (
        <div key={index} className="mt-4 overflow-x-auto">
          {filteredComponent.component}
        </div>
      ))}
    </div>
  );
};

export default EditContextWrapper;
