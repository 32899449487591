import Tooltip from "atoms/Tooltip/Tooltip";
import {
  BUSINESS_CATEGORY,
  BUSINESS_MODEL,
  CURRENT_COMPANY_STAGE,
  INDUSTRY_CATEGORIES_SWEDISH,
  RAISEDFROM,
  TEAM_SIZE,
} from "core/consts";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { FC } from "react";
import NumberFormat from "react-number-format";
import { GeneralInfoProps, InfoItemProps } from "./interfaces";
import cn from "classnames";

const InfoItem: FC<InfoItemProps> = ({
  header,
  tooltip,
  text,
  website,
  content,
  element,
  socials,
}) => (
  <div className="grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80">
    <div className="flex flex-row mr-2 font-medium">
      {header}
      {tooltip && tooltip}
    </div>
    {website && (
      <a
        className="inline-block hover:underline text-aqua-400 whitespace-pre-wrap"
        href={website}
        target="_blank"
        rel="noreferrer"
      >
        {website}
      </a>
    )}
    {element && (
      <span className="text-[#6b7280] dark:text-gray-400 inline-block">
        {element}
      </span>
    )}
    {(text || content || socials) && (
      <span className="text-[#6b7280] inline-block dark:text-gray-400 capitalize">
        {text} {content}
        <div className="flex flex-row items-center gap-3">
          {socials?.linkedin && (
            <a
              href={socials?.linkedin}
              rel="noreferrer noopener"
              target="_blank"
            >
              <LinkedinIcon />
            </a>
          )}
          {socials?.instagram && (
            <a
              href={socials?.instagram}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img src="assets/images/instagram-icon.svg" alt="" />
            </a>
          )}
          {socials?.facebook && (
            <a
              href={socials?.facebook}
              rel="noreferrer noopener"
              target="_blank"
            >
              <FacebookIcon />
            </a>
          )}
          {socials?.youtube && (
            <a
              href={socials?.youtube}
              rel="noreferrer noopener"
              target="_blank"
            >
              <YoutubeIcon className="h-4" />
            </a>
          )}
          {socials?.twitter && (
            <a
              href={socials?.twitter}
              rel="noreferrer noopener"
              target="_blank"
            >
              <TwitterIcon />
            </a>
          )}
        </div>
      </span>
    )}
  </div>
);

const GeneralInfo: FC<GeneralInfoProps> = ({
  website,
  industries,
  city,
  fullAddress,
  businessCategory,
  businessTypeNote,
  founded,
  businessModel,
  businessCategoryNote,
  founders,
  companyStage,
  socials,
  numberOfEmployees,
  raisedValue,
  raisedFrom,
  currencyCode,
  isProfileGeneratedByAIA,
}) => (
  <div
    className={cn(
      "dark:text-white/80 hidden md:flex flex-col w-96",
      isProfileGeneratedByAIA ? "text-sm gap-y-3" : "text-xs gap-y-2"
    )}
  >
    {website && (
      <div className="hidden md:block">
        <InfoItem header="Website" website={website}></InfoItem>
      </div>
    )}
    {industries && industries.length > 0 && (
      <div className="hidden md:block">
        <InfoItem
          header="Industry"
          content={industries?.map((industry, index) => (
            <span
              key={index}
              className="dark:text-gray-400 whitespace-pre-wrap"
            >
              {(INDUSTRY_CATEGORIES_SWEDISH as any)[industry]}
              {index < industries.length - 1 ? "," : ""}&nbsp;
            </span>
          ))}
        />
      </div>
    )}
    {(city || fullAddress) && (
      <div className="hidden md:block">
        <InfoItem
          header="Head Office"
          text={city ? city : fullAddress}
        ></InfoItem>
      </div>
    )}
    {businessCategory && businessCategory?.length > 0 && (
      <div className="hidden md:block">
        <InfoItem
          header="Product"
          content={businessCategory.map((category, index) => {
            if (category === "OTHER") {
              return <span key={index}>{businessTypeNote}</span>;
            } else {
              return (
                <span className="text-[#6b7280] dark:text-gray-400" key={index}>
                  {(BUSINESS_CATEGORY as any)[category]}
                  {index < industries.length - 1 ? "," : ""}&nbsp;
                </span>
              );
            }
          })}
        ></InfoItem>
      </div>
    )}
    {founded && (
      <div className="hidden md:block">
        <InfoItem header="Founded" text={founded}></InfoItem>
      </div>
    )}
    {businessModel && businessModel.length > 0 && (
      <div className="hidden md:block">
        <InfoItem
          header="Business Relation"
          content={businessModel?.map((key, index) => {
            if (key === "OTHERS") {
              return (
                <span className="dark:text-gray-400" key={index}>
                  {businessCategoryNote}
                </span>
              );
            } else {
              return (
                <span className="text-[#6b7280] dark:text-gray-400" key={index}>
                  {(BUSINESS_MODEL as any)[key]}
                  {index < businessModel.length - 1 ? ", " : ""}
                </span>
              );
            }
          })}
        ></InfoItem>
      </div>
    )}
    {numberOfEmployees && (
      <div className="hidden md:block">
        <InfoItem
          header="Team Size"
          text={(TEAM_SIZE as any)[numberOfEmployees]}
        ></InfoItem>
      </div>
    )}
    {founders?.name && (
      <div className="hidden md:block">
        <InfoItem
          header={founders.title || "CEO / Founder name"}
          text={founders.name}
        ></InfoItem>
      </div>
    )}
    {raisedValue && (
      <div className="hidden md:block">
        <InfoItem
          header="Fundraising History"
          element={
            <NumberFormat
              value={raisedValue}
              displayType="text"
              thousandSeparator={","}
              suffix={` ${currencyCode}`}
            />
          }
          tooltip={
            <div className="scale-75">
              {raisedFrom && (
                <Tooltip
                  content={
                    <>
                      Previously Raised From&nbsp;
                      {Object.values(raisedFrom)
                        ?.map((key) => `${(RAISEDFROM as any)[key]}`)
                        .join(", ")}
                    </>
                  }
                >
                  <HelpIcon className="h-auto ml-1" />
                </Tooltip>
              )}
            </div>
          }
        ></InfoItem>
      </div>
    )}
    {companyStage && companyStage.length > 0 && (
      <div className="hidden md:block">
        <InfoItem
          header="Startup stage"
          text={(CURRENT_COMPANY_STAGE as any)[companyStage]}
        ></InfoItem>
      </div>
    )}

    {socials && Object.values(socials).some((v) => !!v) && (
      <div className="hidden md:block">
        <InfoItem header="Social Media" socials={socials}></InfoItem>
      </div>
    )}
  </div>
);

export default GeneralInfo;
