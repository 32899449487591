import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "atoms/Tooltip/Tooltip";
import {
  AiaCompetitor,
  FieldName,
  UpdateAiaProfileAddCompetitorMutation,
  UpdateAiaProfileAddCompetitorMutationVariables,
} from "gql/graphql";
import { refetchCompetitorsData } from "pages/companyProfile/sagaActions";

import { UPDATE_AIA_PROFILE_ADD_COMPETITOR } from "queries/editAiaProfile/editAiaProfile";
import { FC } from "react";
import { useDispatch } from "react-redux";

interface Props {
  companyId: string;
  competitor: AiaCompetitor | null;
  setUpdatedFields?: (fieldName: FieldName[]) => void;
  updatedFields?: FieldName[];
}

const AddCompetitorAddedContext: FC<Props> = ({ companyId, competitor }) => {
  const dispatch = useDispatch();

  const [addCompetitor, { loading }] = useMutation<
    UpdateAiaProfileAddCompetitorMutation,
    UpdateAiaProfileAddCompetitorMutationVariables
  >(UPDATE_AIA_PROFILE_ADD_COMPETITOR, {
    onCompleted() {
      dispatch(refetchCompetitorsData({ id: companyId }));
    },
  });

  const handleAddCompetitor = () => {
    addCompetitor({
      variables: {
        companyId: companyId,
        name: competitor?.name ?? "",
        similarities: competitor?.comparison?.similarities,
        differences: competitor?.comparison?.differences,
        id: competitor?.id,
        pitch: competitor?.pitch,
      },
    });
  };

  return (
    <div
      className="z-[1000]"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        left: "99%",
        top: "5%",
        position: "absolute",
        width: "100%",
      }}
    >
      <Tooltip content={"Add competitor"} place="top">
        {
          <FontAwesomeIcon
            icon={["fal", "plus"]}
            className="text-4xl text-gray-600 cursor-pointer z-50 absolute -left-8 top-2 group:hover"
            onClick={() => handleAddCompetitor()}
          />
        }
      </Tooltip>
    </div>
  );
};

export default AddCompetitorAddedContext;
