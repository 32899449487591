import { FC, useEffect, useState } from "react";

import { AddedContextResult, Startup } from "gql/graphql";
import SDGs from "../../../../Sdgs/Sdgs";
import NewAndCurrentSDGs from "./NewAndCurrentSDGs";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
}

const SdgsAddedContext: FC<Props> = ({ company, newContext }) => {
  const [currentSdgs, setCurrentSdgs] = useState<number[]>([]);

  const newSdgs = newContext?.marketOpp?.sdgs || ([] as any);

  useEffect(() => {
    if (company?.sustainableDevelopmentGoals?.items) {
      setCurrentSdgs(company?.sustainableDevelopmentGoals?.items);
    }
  }, [company?.sustainableDevelopmentGoals]);

  return (
    <div className="w-full h-full flex flex-col mt-4 gap-6">
      <div className="flex flex-row items-center gap-2">
        <span className="title-h3 text-[#545b78]">SDGs</span>

        <span className="body-xs text-[#0038B0] !font-medium w-fit bg-[#E7EFFF] px-2 py-[2px] rounded-2xl">
          {newSdgs.length} Updates
        </span>
      </div>

      <NewAndCurrentSDGs newContext={newContext} company={company} />

      <div className="border-b border-b-[#545b78] border-opacity-20 -mx-6 w-auto" />

      <SDGs
        sustainableDevelopmentGoals={{ items: currentSdgs as any }}
        isPublic={false}
        amITeamMember={true}
        title={false}
        companyId={company.id}
      />
    </div>
  );
};

export default SdgsAddedContext;
