import { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  AddedContextResult,
  AiaAnalysisResult,
  FieldName,
  Startup,
  UiRepresentation,
} from "gql/graphql";
import ProductAddedContext from "./OnboardingContext/ProductDescriptionAddedContext";
import TractionAddedContext from "./OnboardingContext/TractionAddedContext";
import TeamMembersAddedContext from "./OnboardingContext/TeamMembersAddedContext";
import InvestorsAddedContext from "./OnboardingContext/InvestorsAddedcontext";
import MarketSizeAddedContext from "./OnboardingContext/MarketSizeAddedContext";
import SdgsAddedContext from "./OnboardingContext/SdgsAddedContext/SdgsAddedContext";
import ReAnalyzeAnalysisParts from "./OnboardingContext/ReAnalyzeAnalysisParts";
import PitchAddedContext from "./OnboardingContext/PitchAddedContext";
import GeneralInfoAddContext from "./OnboardingContext/GeneralInfoAddedContext/GeneralInfoAddedContext";
import AdvisorsAddedContext from "./OnboardingContext/AdvisorsAddedContext";
import BusinessModelAndPricingAddedContext from "./OnboardingContext/BusinessModelAndPricingAddedContext";
import CompetitorsAddedContext from "./OnboardingContext/CompetitorsAddedContext";
import FoundersAddedContext from "./OnboardingContext/FoundersAddedContext";
import EditContextHeader from "./EditContextHeader";
import EditContextFooter from "./EditContextFooter";

interface Props {
  newContext: AddedContextResult;
  analysis: AiaAnalysisResult;
  company: Startup;
  uiRepresentation: UiRepresentation | null;
  isPublic?: boolean;
  amITeamMember?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const EditContextModal: FC<Props> = ({
  newContext,
  company,
  analysis,
  uiRepresentation,
  isOpen,
  onClose,
}) => {
  // State
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [updatedFields, setUpdatedFields] = useState<FieldName[]>([]);

  const basics = newContext?.basics;

  const socials =
    basics?.socials?.facebook ||
    basics?.socials?.twitter ||
    basics?.socials?.linkedin ||
    basics?.socials?.instagram;

  const isBasicsUpdated =
    !!basics?.website ||
    (basics?.industry && basics.industry.length > 0) ||
    !!socials ||
    !!basics?.companyDomain ||
    (basics?.productCategory && basics.productCategory?.length > 0);

  const isPitchUpdated = !!newContext?.pitch;

  const product = newContext?.productDescription;
  const isProductUpdated =
    !!product?.productDescription ||
    !!product?.valueProposition ||
    !!product?.customers;

  const traction = newContext?.traction;
  const tractionSummary = newContext?.financials?.tractionSummary;

  const isTractionUpdated = !!traction || !!tractionSummary;

  const team =
    newContext?.team?.teamMembers && newContext?.team?.teamMembers.length > 0;
  const founders =
    newContext?.team?.founders && newContext?.team?.founders.length > 0;
  const investors =
    newContext?.team?.investors && newContext?.team?.investors.length > 0;
  const advisors =
    newContext?.team?.advisors && newContext?.team?.advisors.length > 0;
  const competitors =
    newContext?.competitors && newContext?.competitors?.length > 0;

  const isTeamUpdated = !!team;
  const isFoundersUpdated = !!founders;
  const isAdvisorsUpdated = !!advisors;
  const isInvestorsUpdated = !!investors;
  const isCompetitorsUpdated = !!competitors;

  const businessModelDescription =
    newContext?.marketOpp?.businessModelDescription;
  const pricing = newContext?.financials?.pricing;

  const isBusinessAndPricingUpdated = !!businessModelDescription && !!pricing;

  const isMarketSizeUpdated = !!newContext?.marketOpp?.marketOpportunity;

  const isSdgsUpdated =
    newContext?.marketOpp?.sdgs && newContext?.marketOpp?.sdgs.length > 0;

  // INFO: This is be used to create the left-sidebar sections
  const sectionsConfig = [
    {
      component: GeneralInfoAddContext,
      label: "Basics",
      condition: isBasicsUpdated,
    },
    {
      component: PitchAddedContext,
      label: "Pitch",
      condition: isPitchUpdated,
    },
    {
      component: FoundersAddedContext,
      label: "Founders",
      condition: isFoundersUpdated,
    },
    {
      component: TeamMembersAddedContext,
      label: "Team",
      condition: isTeamUpdated,
    },
    {
      component: TractionAddedContext,
      label: "Traction",
      condition: isTractionUpdated,
    },
    {
      component: ProductAddedContext,
      label: "Product",
      condition: isProductUpdated,
    },
    {
      component: AdvisorsAddedContext,
      label: "Advisors",
      condition: isAdvisorsUpdated,
    },
    {
      component: InvestorsAddedContext,
      label: "Investors",
      condition: isInvestorsUpdated,
    },
    {
      component: CompetitorsAddedContext,
      label: "Competitors",
      condition: isCompetitorsUpdated,
    },
    {
      component: BusinessModelAndPricingAddedContext,
      label: "Business Model & Pricing",
      condition: isBusinessAndPricingUpdated,
    },
    {
      component: MarketSizeAddedContext,
      label: "Market",
      condition: isMarketSizeUpdated,
    },
    {
      component: SdgsAddedContext,
      label: "SDG",
      condition: isSdgsUpdated,
    },
    {
      component: ReAnalyzeAnalysisParts,
      label: "Re-Analyze AIA estimates",
      condition: updatedFields.length > 0,
    },
  ];

  const handleOnClickNextSection = (): void => {
    if (currentSectionIndex < filteredSections.length - 1) {
      setCurrentSectionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleOnClickPreviousSection = (): void => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleOnClickIndicator = (index: number): void => {
    setCurrentSectionIndex(index);
  };

  // INFO: Sections that have been updated by the uploaded document
  const filteredSections = sectionsConfig.filter((config) => config.condition);
  // INFO: Component to show on the center content
  const CurrentComponent = filteredSections[currentSectionIndex]?.component;
  // INFO: Current label to highlight in the progress indicator
  const currentSectionLabel = filteredSections[currentSectionIndex]?.label;
  // INFO: To display the progress indicator with updated section names on child components
  const updatedSectionNames = filteredSections.map((section) => section.label);

  const hasFilteredSections = updatedSectionNames.length > 0;
  const updatedSectionCount = updatedSectionNames.length;
  const disabledBackButton = currentSectionIndex === 0;
  const disabledNextButton =
    currentSectionIndex === filteredSections.length - 1;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 opacity-70 bg-[#27364B]" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative bg-white rounded-lg shadow-xl w-full max-w-5xl min-w-lg p-6">
              {hasFilteredSections && CurrentComponent && (
                <>
                  {/* Header */}
                  <EditContextHeader
                    updatedSectionNames={updatedSectionNames}
                    currentSectionLabel={currentSectionLabel}
                    handleOnClickIndicator={handleOnClickIndicator}
                    onClose={onClose}
                  />

                  {/* Current component */}
                  <CurrentComponent
                    newContext={newContext}
                    company={company}
                    analysis={analysis}
                    uiRepresentation={uiRepresentation}
                    setUpdatedFields={setUpdatedFields}
                    updatedFields={updatedFields}
                    isEditMode={true}
                    updatedSectionCount={updatedSectionCount}
                  />

                  {/* Footer */}
                  <EditContextFooter
                    handleOnClickPreviousSection={handleOnClickPreviousSection}
                    handleOnClickNextSection={handleOnClickNextSection}
                    disabledBackButton={disabledBackButton}
                    disabledNextButton={disabledNextButton}
                    onClose={onClose}
                  />
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditContextModal;
