import { AddedContextResult, AiaCompetitor, Startup } from "gql/graphql";
import { getCompetitorsDetails } from "pages/companyProfile/selectors";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { FieldName } from "gql/graphql";
import PureHtml from "atoms/PureHtml";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import SectionHeader from "pages/companyProfile/components/shared/SectionHeader/SectionHeader";
import AddCompetitorAddedContext from "./AddCompetitor";

interface Props {
  newContext?: AddedContextResult;
  company: Startup;
}

interface ValueProps {
  content: string;
  competitorId?: string;
}

const Value: FC<ValueProps> = ({ content }) => {
  return (
    <div>
      <div className="h-6">
        {/* This div is an empty place holder to make room for the edit icon */}
      </div>
      <PureHtml content={content} tag="p" />
    </div>
  );
};

const EditableSimilarity = withContentEditable<ValueProps>(
  Value,
  FieldName.CompetitorSimilarity
);

const EditableDifferences = withContentEditable<ValueProps>(
  Value,
  FieldName.CompetitorDifference
);

const CompetitorsAddedContext: FC<Props> = ({ newContext, company }) => {
  const competitors = useSelector(getCompetitorsDetails);

  const competition = competitors || [];
  const newCompetitors = newContext?.competitors || [];

  const competitorsIds =
    competition?.aiaCompetitors?.map((c: AiaCompetitor) => c?.id) ?? [];

  return (
    <div className="flex flex-col w-full h-full px-6">
      <div className="ml-6 mb-4 mt-4">
        <SectionHeader title="Competitors" />
      </div>
      <div className="overflow-y-auto px-6">
        <h2 className="mb-4">New Competitors</h2>
        <div className="hidden border border-gray-200 rounded md:block">
          <div className="flex px-3 py-3 border-b border-b-gray-200">
            <div className="p-1 text-sm text-gray-400 basis-1/3 ml-8">
              Competitor
            </div>
            <div className="p-1 text-sm text-gray-400 basis-1/3">
              Similarity
            </div>
            <div className="p-1 text-sm text-gray-400 basis-1/3">
              Difference
            </div>
          </div>
        </div>

        {newCompetitors
          ?.filter((comp) => !competitorsIds.includes(comp?.id ?? ""))
          .map((competitor: AiaCompetitor | null) => (
            <div className="relative" key={competitor?.id}>
              <CompetitorAddedContext
                competitor={competitor}
                editable={false}
                newData={true}
              />
              <AddCompetitorAddedContext
                companyId={company.id}
                competitor={competitor}
              />
            </div>
          ))}

        <h2 className="mb-4 mt-4">Current Competitors</h2>
        <div className="hidden border border-gray-200 rounded md:block">
          <div className="flex px-3 py-3 border-b border-b-gray-200">
            <div className="p-1 text-sm text-gray-400 basis-1/3 ml-8">
              Competitor
            </div>
            <div className="p-1 text-sm text-gray-400 basis-1/3">
              Similarity
            </div>
            <div className="p-1 text-sm text-gray-400 basis-1/3">
              Difference
            </div>
          </div>
        </div>
        {competition?.aiaCompetitors?.map((competitor: AiaCompetitor) => (
          <div key={competitor.id}>
            <CompetitorAddedContext competitor={competitor} editable={true} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompetitorsAddedContext;

interface CompetitorProps {
  competitor?: AiaCompetitor | null;
  editable?: boolean;
  newData?: boolean;
}

const CompetitorAddedContext: FC<CompetitorProps> = ({
  competitor,
  editable,
  newData = false,
}) => {
  return (
    <div
      className={cn(
        newData && "bg-aqua-100",
        "my-2 text-sm font-semibold pointer text-gray-600 grid grid-cols-3 text-start items-start gap-1 rounded-md p-6 item-center border-2 border-gray-200"
      )}
    >
      <div>
        <a
          className={cn(
            competitor?.website
              ? "cursor-pointer hover:text-aqua-400"
              : "pointer-events-none cursor-default"
          )}
          href={competitor?.website || ""}
          target="_blank"
          rel="noreferrer"
        >
          {competitor?.name}{" "}
        </a>
        <div className="text-sm text-gray-500 mt-1 py-1">
          {competitor?.pitch}
        </div>
      </div>
      <div className="p-1 mr-2 text-sm text-gray-600 basis-1/3">
        {editable ? (
          <EditableSimilarity
            content={competitor?.comparison?.similarities ?? ""}
            competitorId={competitor?.id}
          />
        ) : (
          <Value content={competitor?.comparison?.similarities ?? ""} />
        )}
      </div>
      <div className="p-1 mr-2 text-sm text-gray-600 basis-1/3">
        {editable ? (
          <EditableDifferences
            content={competitor?.comparison?.differences ?? ""}
            competitorId={competitor?.id}
          />
        ) : (
          <Value content={competitor?.comparison?.differences ?? ""} />
        )}
      </div>
    </div>
  );
};
