import { FC } from "react";
import {
  AiaAnalysisMarketOpp,
  AiaAnalysisResult,
  FieldName,
} from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  analysisResults: AiaAnalysisResult;
  analysis: AiaAnalysisMarketOpp;
}

const EditableMarketSize = withContentEditable<ValueProps>(
  Value,
  FieldName.MarketOppMarketSize
);

const EditableBlueOcean = withContentEditable<ValueProps>(
  Value,
  FieldName.MarketOppBlueOcean
);

const MarketOppAndBlueOcean: FC<Props> = ({ analysisResults, analysis }) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.MarketOppMarketSize}
          title={"Market Size"}
          currentContext={analysis?.marketOpp ?? ""}
          newContext={analysisResults?.marketOpp?.marketOpp ?? ""}
          editableField={(content) => (
            <EditableMarketSize title={"Market Size"} content={content} />
          )}
        />
      ),
      condition: !!analysisResults?.marketOpp?.marketOpp,
      label: "Market Size",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.MarketOppBlueOcean}
          title={"Blue Ocean"}
          currentContext={analysis?.blueOcean ?? ""}
          newContext={analysisResults?.marketOpp?.blueOcean ?? ""}
          editableField={(content) => (
            <EditableBlueOcean title={"Blue Ocean"} content={content} />
          )}
        />
      ),
      condition: !!analysisResults?.marketOpp?.blueOcean,
      label: "Blue Ocean",
    },
  ];

  return (
    <EditContextWrapper header="Market Size / Blue Ocean" components={config} />
  );
};

export default MarketOppAndBlueOcean;
