export enum FileTypes {
  PDF = "pdf",
  DOC = "doc",
  DOCX = "docx",
}

export enum FileTypesToDisplay {
  PDF = "PDF",
  DOC = "Word",
}
