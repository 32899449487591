import { FC } from "react";
import { AddedContextResult, FieldName, Startup } from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  setUpdatedFields: (fields: FieldName[]) => void;
  updatedFields: FieldName[];
}

const EditableProductDescription = withContentEditable<ValueProps>(
  Value,
  FieldName.ProductDescription
);

const EditableValueProposition = withContentEditable<ValueProps>(
  Value,
  FieldName.ProductValueProposition
);

const EditableCustomers = withContentEditable<ValueProps>(
  Value,
  FieldName.ProductCustomers
);

const ProductAddedContext: FC<Props> = ({
  newContext,
  company,
  updatedFields,
  setUpdatedFields,
}) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ProductDescription}
          title={"Product/Service description"}
          currentContext={company?.description ?? ""}
          newContext={newContext?.productDescription?.productDescription ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditableProductDescription
              title={"Product/Service description"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.productDescription?.productDescription,
      label: "Product/Service description",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ProductValueProposition}
          title={"Main Value Proposition"}
          currentContext={company?.valueProposition ?? ""}
          newContext={newContext?.productDescription?.valueProposition ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditableValueProposition
              title={"Main Value Proposition"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.productDescription?.valueProposition,
      label: "Main Value Proposition",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ProductCustomers}
          title={"Customers"}
          currentContext={company?.customers ?? ""}
          newContext={newContext?.productDescription?.customers ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditableCustomers
              title={"Customers"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.productDescription?.customers,
      label: "Customers",
    },
  ];

  return (
    <EditContextWrapper header="Product Description" components={config} />
  );
};

export default ProductAddedContext;
