import Button from "atoms/Button/Button";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import { FC } from "react";

interface AiaHeaderProps {
  tier: string;
  showInvestmentCriteria: boolean;
  isGrantedInvestmentCriteria: boolean;
  handleOpenInvestmentCriteria: () => void;
  handleCloseInvestmentCriteria: () => void;
}

const AiaHeader: FC<AiaHeaderProps> = ({
  tier,
  showInvestmentCriteria,
  handleOpenInvestmentCriteria,
  isGrantedInvestmentCriteria,
}) => {


  return (
    <div className="flex flex-col items-center w-full">
      <div className="relative flex mb-3 md:mb-8">
        <p className="ml-12 md:ml-0 space-x-1 text-3xl sm:text-4xl font-semibold tracking-[7px] md:tracking-[10px] text-white">
          Analyze any company
        </p>
        {tier ? (
          <span className="absolute -top-4 sm:top-0 right-12 sm:-right-[80px] text-xs sm:text-sm bg-[#18BEB3] px-2 py-1 font-bold text-white h-fit rounded-2xl">
            {tier}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default AiaHeader;
