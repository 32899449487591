import { FC, MouseEvent, useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { uniq, without } from "lodash";

import Sdg from "molecules/Sdg/Sdg";
import { AddedContextResult, Startup } from "gql/graphql";

type SDGType = "new" | "current";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
}

const NewAndCurrentSDGs: FC<Props> = ({ newContext, company }) => {
  // State
  const [currentSdgs, setCurrentSdgs] = useState<number[]>([]);
  const [newSdgs, setNewSdgs] = useState<any[]>(
    newContext?.marketOpp?.sdgs || []
  );

  const allSdgs: number[] = [...newSdgs, ...currentSdgs];
  const hasSdgs = allSdgs?.length > 0;

  useEffect(() => {
    if (company?.sustainableDevelopmentGoals?.items) {
      setCurrentSdgs(company?.sustainableDevelopmentGoals?.items);
    }
  }, [company?.sustainableDevelopmentGoals]);

  const handleAddCurrentSdgFromNewSdg = (index: number): void => {
    if (!currentSdgs || currentSdgs.length === 0) {
      setCurrentSdgs([index]);
    } else if (currentSdgs && currentSdgs.length > 0) {
      setCurrentSdgs((prev: any) => uniq([...prev, index]));
    }
  };

  const handleRemoveNewOrCurrentSdg = (
    event: MouseEvent<SVGElement>,
    sdgIndex: number,
    type: SDGType
  ): void => {
    event.stopPropagation();

    if (type === "new") {
      setNewSdgs((prevNewSdgs) => without(prevNewSdgs, sdgIndex));
    }

    if (type === "current") {
      setCurrentSdgs((prevCurrentSdgs) => without(prevCurrentSdgs, sdgIndex));
    }
  };

  return (
    <>
      {hasSdgs && (
        <div className="flex flex-wrap gap-4">
          {newSdgs?.map((sdgIndex: number) => {
            return (
              <div
                className="flex flex-row items-start sm:w-[220px] w-full justify-between bg-[#F1FCF7] border border-[#0C7F6A] border-opacity-40 rounded-xl p-3 cursor-pointer"
                key={sdgIndex}
                onClick={() => handleAddCurrentSdgFromNewSdg(sdgIndex)}
              >
                <div className="flex flex-row items-start gap-2">
                  <div className=" w-[50px]">
                    <Sdg key={sdgIndex} number={sdgIndex} hideLabel />
                  </div>

                  <span className="body-caption text-[#0C7F6A] !font-medium bg-[#D0F5DF] px-3 py-[3px] rounded-2xl">
                    New
                  </span>
                </div>

                <TrashIcon
                  onClick={(e): void => {
                    handleRemoveNewOrCurrentSdg(e, sdgIndex, "new");
                  }}
                  className="w-5 h-5 text-red-400 cursor-pointer"
                />
              </div>
            );
          })}

          {currentSdgs?.map((sdgIndex: number) => {
            return (
              <div
                className="flex flex-row items-start sm:w-[220px] w-full justify-between bg-[#F1FCF7] border border-[#0C7F6A] border-opacity-40 rounded-xl p-3 cursor-pointer"
                key={sdgIndex}
                onClick={() => handleAddCurrentSdgFromNewSdg(sdgIndex)}
              >
                <div className="flex flex-row items-start gap-2">
                  <div className=" w-[70px]">
                    {/* INFO: Display only the icon without the label */}
                    <Sdg key={sdgIndex} number={sdgIndex} hideLabel />
                  </div>

                  <span className="body-caption text-[#303340] !font-medium w-fit bg-[#E4E6EB] px-3 py-[3px] rounded-2xl">
                    Current
                  </span>
                </div>

                <TrashIcon
                  onClick={(e): void => {
                    handleRemoveNewOrCurrentSdg(e, sdgIndex, "current");
                  }}
                  className="w-5 h-5 text-red-400 cursor-pointer"
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default NewAndCurrentSDGs;
