import { AiaAnalysisResult } from "gql/graphql";
import { FC } from "react";
import EditContextWrapper from "../Wrappers/EditContextWrapper";
import ExecutiveSummaryAddedContext from "./ExecutiveSummaryAddedContext";
import TeamInsightsAddedContext from "./TeamInsightsAddedContext";
import RiskOpportunityAnalysis from "./RiskOpportunityAnalysisAddedContext";
import MarketOppAndBlueOcean from "./MarketOppBlueOceanAddedContext";
import RegulationsAddedContext from "./RegulationsAddedContext";

interface Props {
  analysisResult: AiaAnalysisResult; // New results
  currentAnalysis: AiaAnalysisResult; // Current data on the company
}

const EditAnalysisResultsContext: FC<Props> = ({
  analysisResult,
  currentAnalysis,
}) => {
  const config = [
    {
      component: (
        <ExecutiveSummaryAddedContext
          analysisResults={analysisResult}
          analysis={currentAnalysis?.executiveSummary ?? {}}
        />
      ),
      condition: !!analysisResult?.executiveSummary,
      label: "Executive Summary",
    },
    {
      component: (
        <RiskOpportunityAnalysis
          analysisResults={analysisResult}
          analysis={currentAnalysis?.investment ?? {}}
        />
      ),
      condition: !!analysisResult?.investment,
      label: "Risk and Opportunity Analysis",
    },
    {
      component: (
        <TeamInsightsAddedContext
          analysisResults={analysisResult}
          analysis={currentAnalysis?.team ?? {}}
        />
      ),
      condition: !!analysisResult?.team,
      label: "Team Insights",
    },
    {
      component: (
        <MarketOppAndBlueOcean
          analysisResults={analysisResult}
          analysis={currentAnalysis?.marketOpp ?? {}}
        />
      ),
      condition:
        !!analysisResult?.marketOpp?.marketOpp ||
        !!analysisResult?.marketOpp?.blueOcean,
      label: "Market Opportunity and Blue Ocean",
    },
    {
      component: (
        <RegulationsAddedContext
          analysisResults={analysisResult}
          analysis={currentAnalysis?.legislation ?? {}}
        />
      ),
      condition:
        !!analysisResult?.legislation?.todaysLegislation ||
        !!analysisResult?.legislation?.futureLegislation,
      label: "Regulations",
    },
  ];
  return <EditContextWrapper components={config} header={"AIA Analysis"} />;
};

export default EditAnalysisResultsContext;
