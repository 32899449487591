import { FC } from "react";

import { ReactComponent as PdfIcon } from "images/pdf2-icon.svg";
import TrashIcon from "@heroicons/react/20/solid/TrashIcon";
import { MAX_FILE_SIZE_IN_MB } from "./config";

interface Props {
  fileName?: string;
  fileSize?: string;
  hasExceededMaxFileSize: boolean;
  isUploading: boolean;
  onRemoveFile: () => void;
}

const AddContextSelectedFile: FC<Props> = ({
  fileName,
  fileSize,
  hasExceededMaxFileSize,
  isUploading,
  onRemoveFile,
}) => {
  return (
    <div className="flex flex-row justify-center h-fit border border-[#B6BBC9] border-opacity-40 rounded-md items-center p-2 gap-3">
      <div className="flex justify-center items-center w-[60px] h-[55px] bg-[#E7EFFF] rounded-full">
        <PdfIcon className="w-5 h-5" />
      </div>

      <div className="flex flex-col justify-center h-[60px] w-full gap-1">
        <div className="flex flex-row justify-between items-center">
          <span className="body-xs text-[#545b78]">{fileName}</span>

          {!isUploading && (
            <TrashIcon
              onClick={onRemoveFile}
              className="w-5 h-5 text-red-400 cursor-pointer"
            />
          )}
        </div>

        <p className="body-caption text-[#545b78] opacity-60">{fileSize}</p>

        {hasExceededMaxFileSize && (
          <span className="body-caption text-red-500">
            File size {fileSize} exceeds the maximum allowed size{" "}
            {MAX_FILE_SIZE_IN_MB} mb.
          </span>
        )}
      </div>
    </div>
  );
};

export default AddContextSelectedFile;
