import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../SectionHeader/SectionHeader";
import RenderAdvisors from "./RenderAdvisors";
import { Person } from "gql/graphql";

interface Props {
  advisors: Person[];
  isLoading?: boolean;
  companyId: string;
  isPublic?: boolean;
  amITeamMember: boolean;
}

const Advisors: FC<Props> = ({
  advisors,
  isLoading,
  amITeamMember,
  isPublic,
  companyId,
}) => {
  return (
    <div className="mx-auto">
      <SectionHeader title="Advisors" />
      <div className="-mt-4">
        {isLoading ? (
          <div className="grid grid-cols-2 gap-4 w-full mb-2">
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
          </div>
        ) : (
          <RenderAdvisors
            isLoading={isLoading}
            advisors={advisors}
            companyId={companyId}
            isPublic={isPublic}
            amITeamMember={amITeamMember}
          />
        )}
      </div>
    </div>
  );
};

export default Advisors;
