import { FC } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

import { FILE_FORMATS_TO_DISPLAY, MAX_FILE_SIZE_IN_MB } from "./config";
import { cn } from "utils/cn";

interface Props {
  dropzoneRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  dropzoneInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  hasExceededMaxFileSize: boolean;
}

const AddContextFileUpload: FC<Props> = ({
  dropzoneRootProps,
  dropzoneInputProps,
  hasExceededMaxFileSize,
}) => {
  return (
    <div
      {...dropzoneRootProps({ className: "dropzone" })}
      className={cn(
        "flex flex-col gap-4 justify-center w-full cursor-pointer items-center h-[160px] bg-[#F3F5FA] border-2 border-dashed border-opacity-60 rounded-md",
        hasExceededMaxFileSize ? "border-fire-400" : "border-[#B6BBC9]"
      )}
    >
      <input {...dropzoneInputProps()} />

      <div className="flex flex-col gap-2">
        <div className="text-center">
          <span className="title-h6 text-[#303340]">Drag & Drop or </span>

          <span className="title-h6 text-[#0C7F6A] underline underline-offset-4">
            Click to upload
          </span>
        </div>

        <div className="text-center">
          <p className="body-caption ">
            Supported formats: {FILE_FORMATS_TO_DISPLAY.join(", ")}
          </p>

          <p className="body-caption ">
            The file should not be more than {MAX_FILE_SIZE_IN_MB} mb
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddContextFileUpload;
