import { FC, useState } from "react";
import {
  AddedContextResult,
  FieldName,
  MemberType,
  Startup,
} from "gql/graphql";
import SectionHeader from "pages/companyProfile/components/shared/SectionHeader/SectionHeader";
import AddMemberAddedContext from "./AddMemberAddedContext";
import MemberCard from "../../../Team/TeamMemberCard";
import AddMember from "../../../Team/AddNewMember";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  updatedFields?: FieldName[];
  setUpdatedFields?: (fields: FieldName[]) => void;
}

const FoundersAddedContext: FC<Props> = ({
  company,
  newContext,
  setUpdatedFields,
  updatedFields,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const newFounders = newContext?.team?.founders ?? [];

  const currentFounderIds = company.founders?.map((f) => f.id) ?? [];

  return (
    <div className="flex flex-col w-full h-full px-6">
      <div className="ml-6 mb-4 mt-4">
        <SectionHeader title="Founders" />
      </div>
      <div className="overflow-y-auto px-6">
        <h2 className="mb-4">New Founders</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mb-4">
          {newFounders
            ?.filter(
              (founder) => !currentFounderIds.includes(founder?.id ?? "")
            )
            .map((member, index) => {
              const memberUi =
                newContext?.team?.teamUiRepresentation?.founders?.filter(
                  (ui) => ui?.id === member?.id
                );

              return (
                <div className="relative flex" key={member?.id}>
                  <AddMemberAddedContext
                    companyId={company.id}
                    type={MemberType.Founder}
                    member={member}
                    memberUi={memberUi ? memberUi[0] : null}
                    updatedFields={updatedFields}
                    setUpdatedFields={setUpdatedFields}
                  />
                  <MemberCard
                    companyId={company.id}
                    member={member}
                    key={member?.id}
                    index={index}
                    type={MemberType.Founder}
                    isPublic={false}
                    amITeamMember={true}
                    isAddedContext={true}
                    uiRepresentationAddedContext={memberUi ? memberUi[0] : null}
                  />
                </div>
              );
            })}
          <AddMember
            companyId={company.id}
            type={MemberType.Founder}
            isEditMode={isEditMode}
            updatedFields={updatedFields}
            setUpdatedFields={setUpdatedFields}
          />
        </div>

        {company.founders && company.founders.length > 0 && (
          <>
            <h2 className="mb-4">Current Founders</h2>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 relative">
              {company?.founders?.map((member, index) => (
                <MemberCard
                  companyId={company.id}
                  member={member}
                  key={member?.id}
                  index={index}
                  type={MemberType.Founder}
                  isPublic={false}
                  amITeamMember={true}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FoundersAddedContext;
