import { useLazyQuery } from "@apollo/client";
import TextArea from "atoms/Form/TextArea";
import {
  AddContextQuery,
  AddContextQueryVariables,
  AddedContextResult,
  AiaAnalysisResult,
  Startup,
  UiRepresentation,
} from "gql/graphql";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { mixpanelAiaAddContext } from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";
import { ADD_CONTEXT } from "queries/aia/addContext";
import Modal from "atoms/Modal/Modal";
import {
  formatFileSize,
  getExtensionFromFileName,
  getFileSizeInMB,
} from "utils/file";
import { FileTypes } from "enums";
import AddContextHeader from "./AddContextHeader";
import {
  FILE_FORMAT_EXTENSIONS,
  FILE_FORMATS_FOR_DROPZONE,
  MAX_FILE_SIZE_IN_MB,
} from "./config";
import AddContextSelectedFile from "./AddContextSelectedFile";
import AddContextFileUpload from "./AddContexFileUpload";
import AddContextFooter from "./AddContextFooter";
import AddContextError from "./AddContextError";
import EditContextModal from "./EditContext/EditContext";
import { useSwitch } from "hooks/useSwitch";
import { Input } from "atoms/Form/Inputs";

interface AddContextModalProps {
  companyId: string;
  company: Startup;
  analysis: AiaAnalysisResult;
  uiRepresentation: UiRepresentation | null;
  onClose: () => void;
}

const AddContextModal: FC<AddContextModalProps> = ({
  companyId,
  company,
  analysis,
  uiRepresentation,
  onClose,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const [
    isOpenEditContextModal,
    onOpenEditContextModal,
    onCloseEditContextModal,
  ] = useSwitch(false);

  // State
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [context, setContext] = useState("");

  const [url, setUrl] = useState<string>("");
  const [hasError, setError] = useState(false);

  // Selectors
  const user = useSelector(getUser);

  const fileExtension = getExtensionFromFileName(selectedFile?.name);
  const fileSize = formatFileSize(selectedFile?.size);
  const fileName = selectedFile?.name.toUpperCase();

  // Dropzone config
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    accept: FILE_FORMATS_FOR_DROPZONE,
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const handleCloseEditContextModal = (): void => {
    onCloseEditContextModal();
    setError(false);
    onClose();
  };
  console.log("---", context);
  const hasExceededMaxFileSize = useCallback((): boolean => {
    if (!selectedFile) return false;

    const selectedFileSizeInMB = getFileSizeInMB(selectedFile.size);
    return selectedFileSizeInMB > MAX_FILE_SIZE_IN_MB;
  }, [selectedFile]);

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const [addContext, { data: newContext, loading: isLoading }] = useLazyQuery<
    AddContextQuery,
    AddContextQueryVariables
  >(ADD_CONTEXT, {
    onCompleted: (data) => {
      if (data.aia?.addContext) {
        onOpenEditContextModal();
        mixpanelAiaAddContext(user);
      }
    },
  });

  const addedContext =
    newContext?.aia?.addContext ?? ([] as AddedContextResult);

  const handleAddContext = async () => {
    setError(false);

    if (!selectedFile && !context && !url) {
      return;
    }

    const variables: AddContextQueryVariables = {
      companyId,
    };

    if (url) {
      if (!isValidUrl(url)) {
        return dispatch(
          triggerSnack({
            type: "error",
            message: "Invalid URL",
          })
        );
      }
      variables.url = url;
    }

    if (selectedFile) {
      // INFO: fileExtension is the extension of the selected file.
      if (!FILE_FORMAT_EXTENSIONS.includes(fileExtension as FileTypes)) {
        return dispatch(
          triggerSnack({
            type: "error",
            message: `Accepted file formats: ${FILE_FORMAT_EXTENSIONS.join(
              ", "
            )}`,
          })
        );
      }

      if (hasExceededMaxFileSize()) {
        return dispatch(
          triggerSnack({
            type: "error",
            message: `File size should be less than ${MAX_FILE_SIZE_IN_MB}MB`,
          })
        );
      }
      variables.file = selectedFile;
    }

    if (context) {
      variables.text = context;
    }

    try {
      await addContext({
        variables,
      });

      return;
    } catch (e) {
      setError(true);
    }
  };

  const isValidUrl = (url: string) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  };

  return (
    <>
      {/* INFO: Edit uploaded contents */}
      {isOpenEditContextModal && (
        <EditContextModal
          newContext={addedContext}
          analysis={analysis}
          company={company}
          uiRepresentation={uiRepresentation}
          isOpen={isOpenEditContextModal}
          onClose={handleCloseEditContextModal}
        />
      )}

      {/* INFO: Upload new contents */}
      {!isOpenEditContextModal && (
        <Modal
          width="45rem"
          className="!bg-[#B6BBC9] !px-0 !pb-0 !m-0 z-50"
          onClose={onClose}
          hideCloseButton
        >
          <div className="flex justify-center align-center flex-col gap-4 bg-white py-6 px-6 rounded-xl border border-gray-400 my-auto backdrop-brightness-50">
            <AddContextHeader
              companyName={company.displayName!}
              startUpName={company.displayName!}
              onClose={onClose}
            />

            {/* INFO: Content to display on initial page load */}
            {!selectedFile && (
              <AddContextFileUpload
                dropzoneRootProps={getRootProps}
                dropzoneInputProps={getInputProps}
                hasExceededMaxFileSize={hasExceededMaxFileSize()}
              />
            )}

            {/* INFO: Content to display when there is a selected file */}
            {!!selectedFile && (
              <AddContextSelectedFile
                fileName={fileName}
                fileSize={fileSize}
                hasExceededMaxFileSize={hasExceededMaxFileSize()}
                isUploading={isLoading}
                onRemoveFile={handleRemoveFile}
              />
            )}

            <div className="body-small text-[#303340] text-center">Or</div>

            <TextArea
              className="w-full h-[144px] body-small border border-[#B6BBC9] text-[#7980A0] rounded-md px-3 py-2"
              placeholder="Add text context"
              value={context}
              onChange={(e: any) => setContext(e.target.value)}
            />

            <TextArea
              type="url"
              className="w-full h-16 body-small border border-[#B6BBC9] text-[#7980A0] rounded-md px-3 py-2"
              placeholder="Enter a public url"
              value={url}
              onChange={(e: any) => setContext(e.target.value)}
            />

            {hasError && <AddContextError />}

            <AddContextFooter
              disabledNextButton={hasError || isLoading}
              isUploading={isLoading}
              onAddContext={handleAddContext}
              onClose={onClose}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddContextModal;
