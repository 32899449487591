import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../SectionHeader/SectionHeader";
import { PROFILE_SECTION_IDS } from "../../aia/ProfileNavigation/constants";
import useProfileNavigation from "../../aia/ProfileNavigation/useProfileNavigation";
import { Person } from "gql/graphql";
import RenderCapTable from "./RenderCapTable";

interface Props {
  isLoading?: boolean;
  investors: Person[];
  companyId: string;
  isPublic?: boolean;
  amITeamMember: boolean;
}

const CapTable: FC<Props> = ({
  investors,
  isLoading,
  companyId,
  isPublic,
  amITeamMember,
}) => {
  const { profileSectionRef } = useProfileNavigation();

  return (
    <div
      id={PROFILE_SECTION_IDS["Cap Table"]}
      ref={profileSectionRef}
      className="mx-auto"
    >
      <SectionHeader title="Cap Table" />
      <div className="-mt-4">
        {isLoading ? (
          <div className="grid grid-cols-2 gap-4 w-full mb-2">
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
          </div>
        ) : (
          <RenderCapTable
            investors={investors}
            companyId={companyId}
            amITeamMember={amITeamMember}
            isLoading={isLoading}
            isPublic={isPublic}
          />
        )}
      </div>
      {!isLoading && (
        <p className="text-sm text-center mt-4">
          There may be more investors on the cap table.
        </p>
      )}
    </div>
  );
};

export default CapTable;
