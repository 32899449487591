import { FC } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  sections: string[];
  currentSection: string;
  onClickIndicator: (index: number) => void;
}

const ProgressIndicator: FC<Props> = ({
  sections,
  currentSection,
  onClickIndicator,
}) => {
  return (
    <div className="flex flex-wrap gap-x-6 gap-y-4">
      {sections?.map((section, index) => {
        const isCurrentSection = currentSection === section;
        const currentSectionIndex = sections.indexOf(currentSection);
        const isCompletedSection = index < currentSectionIndex;
        const isPendingSection = index > currentSectionIndex;

        const indicatorStyles = cn({
          "text-[#23252F]": isCompletedSection,
          "text-[#0C7F6A] !font-[700]": isCurrentSection,
          "text-[#545B78]": isPendingSection,
        });

        return (
          <button
            key={section}
            className="flex w-fit flex-row items-center gap-3"
            onClick={(): void => onClickIndicator(index)}
          >
            <span className={cn("body-small", indicatorStyles)}>{section}</span>

            {sections.length - 1 !== index && (
              <FontAwesomeIcon
                icon={["fal", "chevron-right"]}
                size="xs"
                className={indicatorStyles}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default ProgressIndicator;
