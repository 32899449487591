import { FileTypes, FileTypesToDisplay } from "enums";

export const LIB_CDN_SRC =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.min.js";

export const MAX_FILE_SIZE_IN_MB = 10;

export const FILE_FORMATS_TO_DISPLAY = [FileTypesToDisplay.PDF];

export const FILE_FORMAT_EXTENSIONS = [FileTypes.PDF];

export const FILE_FORMATS_FOR_DROPZONE = {
  "application/pdf": [".pdf"],
};
