import PureHtml from "atoms/PureHtml";
import { FieldName } from "gql/graphql";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import { FC } from "react";

interface Props {
  content: string;
}

const IndustryExperiences: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div className="col-span-2">
      <h4 className="mb-2 font-bold text-gray-700">Industry experience</h4>{" "}
      <div className="text-[#24272B] dark:text-gray-400 rich-text-content-wrapper">
        <PureHtml content={content} tag="p" />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(
  IndustryExperiences,
  FieldName.TeamIndustryExperience
);
