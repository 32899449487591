import { FC } from "react";

const GeneralInfoComparisonHeader: FC = () => {
  return (
    <div className="grid grid-cols-12 mt-4 gap-x-4">
      <div className="col-span-2 flex items-center h-10 bg-[#F3F5FA] rounded-t-md border-b-2 pl-4 border-[#8B90A6] border-opacity-40 body-small">
        Category
      </div>

      <div className="col-span-5 flex items-center h-10 bg-[#F3F5FA] rounded-t-md border-b-2 pl-4 border-[#8B90A6] border-opacity-40 py-3 gap-2">
        <span className="body-small">New Data</span>

        <span className="body-caption text-[#0C7F6A] !font-medium w-fit bg-[#D0F5DF] px-3 py-[3px] rounded-2xl">
          New
        </span>
      </div>

      <div className="col-span-5 flex items-center h-10 bg-[#F3F5FA] rounded-t-md border-b-2 pl-4 border-[#8B90A6] border-opacity-40 py-3 body-small">
        Original Data
      </div>
    </div>
  );
};

export default GeneralInfoComparisonHeader;
