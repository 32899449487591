import { AddedContextResult, FieldName, Startup } from "gql/graphql";
import { FC } from "react";
import PureHtml from "atoms/PureHtml";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import EditContextWrapper from "../Wrappers/EditContextWrapper";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  isEditMode: boolean;
  setUpdatedFields: (fields: FieldName[]) => void;
  updatedFields: FieldName[];
}

const EditableBusinessModel = withContentEditable<ValueProps>(
  Value,
  FieldName.BusinessModel
);

const EditablePricing = withContentEditable<ValueProps>(
  Value,
  FieldName.Pricing
);

const BusinessModelAndPricingAddedContext: FC<Props> = ({
  newContext,
  company,
  setUpdatedFields,
  updatedFields,
}) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.BusinessModel}
          title={"Business Model"}
          currentContext={company?.businessModelDescription ?? ""}
          newContext={newContext?.marketOpp?.businessModelDescription ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditableBusinessModel
              title={"Business Model"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.marketOpp?.businessModelDescription,
      label: "Business Model",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.Pricing}
          title={"Pricing"}
          currentContext={company?.pricing ?? ""}
          newContext={newContext?.financials?.pricing ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditablePricing
              title={"Pricing"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.financials?.pricing,
      label: "Pricing",
    },
  ];

  return (
    <EditContextWrapper
      header="Business Model and Pricing"
      components={config}
    />
  );
};

export default BusinessModelAndPricingAddedContext;
