import { FC } from "react";
import withValueEditable from "organisms/ValueEditable/ValueEditable";
import { CURRENT_COMPANY_STAGE } from "core/consts";
import { FieldName } from "gql/graphql";
import { Value, ValueProps } from "./Value";

interface Props {
  companyStage: string[];
  uiRepresentation: string | null | undefined;
  editable?: boolean;
}

const EditableValue = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoStartupStage
);

const StartupStage: FC<Props> = ({
  companyStage,
  uiRepresentation,
  editable = true,
}) => {
  const getContent = () => {
    if (uiRepresentation) return uiRepresentation;
    if (companyStage && companyStage.length > 0)
      return companyStage
        .map((cs) => (CURRENT_COMPANY_STAGE as any)[cs])
        .join(", ");
    return "";
  };

  return (
    <div className="grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80">
      <h6 className="font-medium">Startup Stage</h6>
      {editable ? (
        <EditableValue content={getContent()} />
      ) : (
        <Value content={getContent()} />
      )}
    </div>
  );
};

export default StartupStage;
