import { useQuery } from "@apollo/client";
import { GetAiaChatQuery, GetAiaChatQueryVariables } from "gql/graphql";
import { GET_AIA_CHAT } from "queries/aia/chat-bot";
import { FC, Fragment, useState } from "react";
import { ChatBotProps } from "./interfaces";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "atoms/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { getUser } from "models/user/selectors";
import { mixpanelAiaChat } from "core/mixpanel/Mixpanel";
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";

import { restApiBaseUrl } from "core/config";
import { v4 as uuid } from "uuid";

const AIA_FEATURE_LIMITS = ["AIA_CHAT"];

const ChatBot: FC<ChatBotProps> = ({
  companyId,
  showAddedContext,
  showEditContextModal,
  isHidden,
  setIsHidden,
}) => {
  const dispatch = useDispatch();
  const { openModal } = useUpgradePlanModal();

  const [messages, setMessages] = useState("");
  const [showQuery, setShowQuery] = useState("");
  const [search, setSearch] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSearchingWeb, setIsSearchingWeb] = useState(false);

  const user = useSelector(getUser);

  if (!localStorage.getItem("aia_chat_sircular")) {
    localStorage.setItem("aia_chat_sircular", uuid());
  }

  const streamResponse = async () => {
    try {
      setIsSending(true);

      const response = await fetch(`${restApiBaseUrl}/chat-stream/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: search,
          companyId: companyId,
          userId: user?.id,
          sessionId: localStorage.getItem("aia_chat_sircular"),
        }),
      });

      if (!response.body) {
        return;
      }
      setShowQuery(search);

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        if (value === "runWebSearch") {
          setIsSearchingWeb(true);
          continue;
        }
        setMessages((prev) => prev + value);
        setIsSearchingWeb(false);
      }

      setMessages("");
      setSearch("");
      setIsSending(false);
      setIsSearchingWeb(false);
      refetch({ companyId });
      mixpanelAiaChat(user);
    } catch (error) {
      dispatch(
        triggerSnack({
          message: "Failed to send message",
          severity: "error",
        })
      );
    }
  };

  const {
    data: chat,
    loading: isLoading,
    refetch,
  } = useQuery<GetAiaChatQuery, GetAiaChatQueryVariables>(GET_AIA_CHAT, {
    variables: {
      companyId,
      sessionId: localStorage.getItem("aia_chat_sircular") ?? "",
    },
  });
  const aiaRates = useAiaRateLimits(user?.id, AIA_FEATURE_LIMITS);

  const chatRates =
    aiaRates?.formattedAiaRates && aiaRates.formattedAiaRates["AIA_CHAT"];

  const isChatGranted = Boolean(chatRates?.remaining);

  if (!companyId) {
    return null;
  }

  if (showEditContextModal) {
    setIsHidden(true);
  }

  return (
    <>
      <div
        className={cn(
          "fixed hidden bottom-4 right-4 lg:block transition-all duration-300 z-50 pulsate-aia-chat",
          isHidden && "translate-y-[calc(100%-25px)]"
          // TODO: Remove the below line if translate-x is no more required
          // showAddedContext && !showEditContextModal && "-translate-x-[27rem]"
        )}
      >
        <div className="w-[420px] h-[450px] bg-white rounded-lg shadow-lg border border-gray-200">
          <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200 rounded-t-lg">
            <div className="flex items-center text-sm text-gray-500">
              <FontAwesomeIcon icon={["fas", "magic"]} />
              <span className="mx-2 font-bold">AIA chat</span>
              {isLoading && <Loading size="small" />}
            </div>
            <button
              title={isHidden ? "Expand" : "Collapse"}
              type="button"
              onClick={() => setIsHidden(!isHidden)}
              className="flex p-1 text-sm text-gray-500 rounded-full hover:bg-gray-200 hover:text-gray-700 focus:text-gray-700 focus:bg-gray-200"
            >
              <FontAwesomeIcon
                icon={["fas", "chevron-up"]}
                className={cn(
                  "transition duration-300",
                  !isHidden && "transform rotate-180"
                )}
              />
            </button>
          </div>

          <div>
            <div className="h-[370px] max-h-[370px] flex flex-col-reverse overflow-y-auto px-4 pt-2">
              {isSearchingWeb && (
                <div className="mb-4 bg-gray-100 border border-gray-300 rounded-lg shadow">
                  <div className="px-4 py-2 text-sm font-bold text-aqua-400 border-b items-center text-center border-gray-300 animate-pulse flex flex-row">
                    <Loading size="small" />
                    <span className="ml-2">Searching the web...</span>
                  </div>
                </div>
              )}
              {messages && showQuery && (
                <div className="mb-4 bg-gray-100 border border-gray-300 rounded-lg shadow">
                  <div className="px-4 py-2 text-sm font-bold text-gray-500 border-b border-gray-300 ">
                    {showQuery}
                  </div>
                  <div className="px-4 py-2 text-sm whitespace-pre-wrap">
                    {messages}
                  </div>
                </div>
              )}

              {chat?.aia?.chat?.map((message) => (
                <div
                  key={message?.id}
                  className="mb-4 bg-gray-100 border border-gray-300 rounded-lg shadow"
                >
                  <div className="px-4 py-2 text-sm font-bold text-gray-500 border-b border-gray-300">
                    {message?.question}
                  </div>
                  <div className="px-4 py-2 text-sm">
                    {message?.answer?.split("\n").map((line, index) => (
                      <Fragment key={index}>
                        <span>{line}</span>
                        <br />
                      </Fragment>
                    ))}
                  </div>
                </div>
              ))}
              <div className="mb-4 bg-gray-100 border border-gray-300 rounded-lg shadow px-4 py-2 text-sm">
                Hey there! I am here to assist you with any questions or
                information you need about the startup. How can I help you?
              </div>
            </div>
            <div className="flex w-full px-2 py-1 h-[40px]">
              <input
                type="text"
                className="w-full rounded-[20px] border border-gray-200 text-sm px-4 py-1 hover:border-gray-300 focus:border-gray-300"
                placeholder="Your prompt..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    isChatGranted ? streamResponse() : openModal();
                  }
                }}
              />
              <button
                type="button"
                onClick={() => {
                  isChatGranted ? streamResponse() : openModal();
                }}
                disabled={!search || isSending}
                className="flex items-center text-gray-500 ml-2 min-w-[30px] justify-center text-sm border border-gray-200 rounded-full hover:bg-gray-200 focus:bg-gray-200"
              >
                {isSending ? (
                  <Loading size="small" />
                ) : (
                  <FontAwesomeIcon icon={["fas", "paper-plane"]} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBot;
