import { FC } from "react";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import Skeleton from "react-loading-skeleton";
import TractionContent from "./Traction";

interface Props {
  isLoading?: boolean;
  traction?: string;
}

const TractionVerbose: FC<Props> = ({ isLoading, traction }) => {
  if (isLoading && !traction)
    return (
      <div className="my-4">
        <SectionHeader title="Traction" />
        <Skeleton count={3.4} />
      </div>
    );

  if (!traction) return null;

  return <TractionContent content={traction ? traction : "No data provided"} />;
};

export default TractionVerbose;
