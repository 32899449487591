import { FC } from "react";
import {
  AiaAnalysisExecutiveSummary,
  AiaAnalysisResult,
  FieldName,
} from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  analysisResults: AiaAnalysisResult;
  analysis: AiaAnalysisExecutiveSummary;
}

const EditableExecSumBusinessModel = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryBusinessModel
);

const EditableExecSumProblem = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryProblem
);

const EditableExecSumProduct = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryProduct
);

const EditableExecSumMarketOpp = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryMarketOpportunity
);

const EditableExecSumTeam = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryTeam
);

const ExecutiveSummaryAddedContext: FC<Props> = ({
  analysisResults,
  analysis,
}) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.TeamSharedWorkExperiences}
          title={"Business Model"}
          currentContext={analysis?.businessModel ?? ""}
          newContext={analysisResults?.executiveSummary?.businessModel ?? ""}
          editableField={(content) => (
            <EditableExecSumBusinessModel
              title={"Business Model"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.executiveSummary?.businessModel,
      label: "Business Models",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ExecuteveSummaryProblem}
          title={"Problem"}
          currentContext={analysis?.problem ?? ""}
          newContext={analysisResults?.executiveSummary?.problem ?? ""}
          editableField={(content) => (
            <EditableExecSumProblem title={"Problem"} content={content} />
          )}
        />
      ),
      condition: !!analysisResults?.executiveSummary?.problem,
      label: "Problem",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ExecuteveSummaryProduct}
          title={"Product"}
          currentContext={analysis?.product ?? ""}
          newContext={analysisResults?.executiveSummary?.product ?? ""}
          editableField={(content) => (
            <EditableExecSumProduct title={"Product"} content={content} />
          )}
        />
      ),
      condition: !!analysisResults?.executiveSummary?.product,
      label: "Product",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ExecuteveSummaryMarketOpportunity}
          title={"Market Opportunity"}
          currentContext={analysis?.marketOpp ?? ""}
          newContext={analysisResults?.executiveSummary?.marketOpp ?? ""}
          editableField={(content) => (
            <EditableExecSumMarketOpp
              title={"Market Opportunity"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.executiveSummary?.marketOpp,
      label: "Market Opportunity",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ExecuteveSummaryTeam}
          title={"Team"}
          currentContext={analysis?.team ?? ""}
          newContext={analysisResults?.executiveSummary?.team ?? ""}
          editableField={(content) => (
            <EditableExecSumTeam title={"Team"} content={content} />
          )}
        />
      ),
      condition: !!analysisResults?.executiveSummary?.team,
      label: "Team",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ExecuteveSummaryGoToMarketStrategy}
          title={"Go To Market Strategy"}
          currentContext={analysis?.goToMarket ?? ""}
          newContext={analysisResults?.executiveSummary?.goToMarket ?? ""}
          editableField={(content) => (
            <EditableExecSumMarketOpp
              title={"Go To Market Strategy"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.executiveSummary?.goToMarket,
      label: "Go To Market Strategy",
    },
  ];

  return <EditContextWrapper header="Executiev Summary" components={config} />;
};

export default ExecutiveSummaryAddedContext;
