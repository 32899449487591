import { AddedContextResult, FieldName, Startup } from "gql/graphql";
import { FC } from "react";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  setUpdatedFields: (fields: FieldName[]) => void;
  updatedFields: FieldName[];
}

const EditableMarketOpp = withContentEditable<ValueProps>(
  Value,
  FieldName.MarketOppPitchDeck
);

const MarketSizeAddedContext: FC<Props> = ({
  newContext,
  company,
  setUpdatedFields,
  updatedFields,
}) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.MarketOppPitchDeck}
          title="data"
          currentContext={company?.marketOpp ?? "No current content found"}
          newContext={
            newContext?.marketOpp?.marketOpportunity ?? "No new content found"
          }
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          hidePrimaryButton
          editableField={(content) => (
            <EditableMarketOpp
              title="Current data"
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: true,
      label: "Market",
    },
  ];

  return <EditContextWrapper header="Market" components={config} />;
};

export default MarketSizeAddedContext;
