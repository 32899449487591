import { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "images/logo-white.svg";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

import { AIA_TRIAL_STATUS } from "./queries";
import { useLocation, useNavigate } from "react-router-dom";
import AiaCheckoutSuccess from "./components/AiaCheckoutSuccess";
import { getUser } from "models/user/selectors";
import { ReactComponent as TopNavGraphic } from "images/aia-top-nav.svg";
import Loading from "atoms/Loading/Loading";
import UploadPitchDeck from "./UploadPitchDeck";
import AiaHeader from "./AiaHeader";
import InvestmentCriteria from "./InvestmentCriteria";
import { Tier } from "gql/graphql";
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import { UpgradePlanModal, useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import Button from "atoms/Button/Button";
import UploadStartupList from "./UploadStartupList";

const AIA_FEATURE_LIMITS = ["WEBSITE_AS_CONTEXT", "INVESTMENT_CRITERIA"];

const Aia: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const criteriaDialogRef = useRef<HTMLDialogElement | null>(null);
  const uploadListDialogRef = useRef<HTMLDialogElement | null>(null);

  const closeCriteriaDialog = () => {
    if (criteriaDialogRef.current) criteriaDialogRef.current.close();
  };

  const openUploadListDialog = () => {
    if (uploadListDialogRef.current) uploadListDialogRef.current.showModal();
  };

  const closeUploadListDialog = () => {
    if (uploadListDialogRef.current) uploadListDialogRef.current.close();
  };

  const [showCheckoutSuccessModal, setShowCheckoutSuccessModal] =
    useState(false);
  const user = useSelector(getUser);
  const [showInvestmentCriteria, setShowInvestmentCriteria] = useState(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const tier = user.tier as Tier;

  useEffect(() => {
    if (window.location.search.includes("checkout_success=true")) {
      setShowCheckoutSuccessModal(true);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (state?.showInvestmentCriteria) {
      setShowInvestmentCriteria(true);
    }
  }, [state]);

  const { data: limitQueryData, loading: trialStatusLoading } = useQuery<{
    me: {
      company: {
        aiaTrial: {
          isOver: boolean;
          totalUploads: number;
          trialDaysLeft: number;
        };
      };
    };
  }>(AIA_TRIAL_STATUS, {
    fetchPolicy: "network-only",
  });

  const trialDaysLeft =
    limitQueryData?.me.company?.aiaTrial?.trialDaysLeft || 0;

  const isTrial = user?.subscription?.status === "TRIALING";

  const aiaRates = useAiaRateLimits(user.id, AIA_FEATURE_LIMITS);

  const websiteAsContextRates =
    aiaRates?.formattedAiaRates &&
    aiaRates?.formattedAiaRates["WEBSITE_AS_CONTEXT"];

  const investmentCriteriaRates =
    aiaRates?.formattedAiaRates &&
    aiaRates?.formattedAiaRates["INVESTMENT_CRITERIA"];

    const isGrantedInvestmentCriteria = Boolean(investmentCriteriaRates?.limit);
  
    const openCriteriaDialog = () => {
      if(!isGrantedInvestmentCriteria) {
        return setShowUpgradePlanModal(true);
      }
      if (criteriaDialogRef.current) criteriaDialogRef.current.showModal();
    };

  if (trialStatusLoading || aiaRates?.isRatesLoading) {
    return <Loading fullPage />;
  }

  if (tier === Tier.Pending) {
    navigate("/plans");
  }

  return (
    <>
      <div className="flex font-lato flex-col items-center md:min-h-full bg-[#0B0D18]">
        <div className="relative flex flex-col items-center w-full">
          <TopNavGraphic className="invisible w-full sm:visible" />
          <Logo className="absolute z-50 bottom-16 " />
        </div>

        <div className="flex flex-col items-center justify-center max-w-[600px] mx-6 lg:max-w-[950px] w-full lg:justify-start">
          <AiaHeader
            tier={tier}
            showInvestmentCriteria={showInvestmentCriteria}
            isGrantedInvestmentCriteria={isGrantedInvestmentCriteria}
            handleOpenInvestmentCriteria={() => setShowInvestmentCriteria(true)}
            handleCloseInvestmentCriteria={() =>
              setShowInvestmentCriteria(false)
            }
          />

          <div className="w-full mb-3 md:mb-8 flex">
            <div className="mx-auto" >

            <Button
              onClick={openCriteriaDialog}
              color="transparent"
              className="text-sm rounded-lg sm:text-md items-center mr-2"
            >
              <span className="font-bold"> Customize company analysis</span>
             
            </Button>
            <Button
              onClick={openUploadListDialog}
              color="transparent"
              className="text-sm rounded-lg sm:text-md items-center "
            >
              <span className="font-bold">Upload company list</span>
              
            </Button>
            </div>

          </div>

          <UploadPitchDeck
            isTrial={isTrial}
            trialDaysLeft={trialDaysLeft}
            websiteAsContextRates={websiteAsContextRates}
          />
        </div>

        <AiaCheckoutSuccess
          isShown={showCheckoutSuccessModal}
          close={() => {
            setShowCheckoutSuccessModal(false);
            navigate("/aia");
          }}
        />
      </div>
      <dialog id="criteria-modal" className="w-1/2 rounded-lg" ref={criteriaDialogRef}>
        <InvestmentCriteria
          handleCloseInvestmentCriteria={closeCriteriaDialog}
        />
      </dialog>
      <dialog id="company-list-modal" className="w-1/2 rounded-lg" ref={uploadListDialogRef}>
        <UploadStartupList openCriteriaDialog={openCriteriaDialog} closeDialog={closeUploadListDialog}/>
      </dialog>
      <UpgradePlanModal isOpen={showUpgradePlanModal} onClose={() => setShowUpgradePlanModal(false)}/>
    </>
  );
};

export default Aia;
