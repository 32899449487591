import { FC } from "react";
import SharedWorkExperiences from "./SharedWorkExperiences";
import SharedSchoolExperiences from "./SharedSchoolExperiences";
import IndustryExperiences from "./IndustryExperiences";
import CriticalCompetences from "./CriticalCompetences";

interface Props {
  workExperiences: string;
  schoolExperiences: string;
  industryExperiences: string;
  criticalCompetences: string;
}

const TeamInsights: FC<Props> = ({
  workExperiences,
  schoolExperiences,
  industryExperiences,
  criticalCompetences,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4 aiaProfile">
      <div className="col-span-2 sm:col-span-1">
        <SharedWorkExperiences content={workExperiences} />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <SharedSchoolExperiences content={schoolExperiences} />
      </div>
      <div className="col-span-2">
        <IndustryExperiences content={industryExperiences} />
      </div>
      <div className="col-span-2">
        <CriticalCompetences content={criticalCompetences} />
      </div>
    </div>
  );
};

export default TeamInsights;
