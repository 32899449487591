import { FC, useState } from "react";
import { useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "atoms/Button/Button";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import ViewIdentification from "organisms/ViewIdentification/ViewIdentification";
import { getUser } from "models/user/selectors";
import { getCountryCode } from "utils/generalUtils";
import GeneralInfo from "./GeneralInfo";
import PitchDeck from "../shared/Navbar/PitchDeck";
import AddMeetingLinkModal from "../shared/Navbar/AddMeetingLinkModal";
import ViewSettings from "../shared/Navbar/ViewSettings";
import FundraisingOverview_Aia from "./FundraisingOverView_Aia";
import GenerateEmailModal from "./GenerateEmail/GenerateEmailModal";
import DownloadProfile from "./DownloadProfile";
import AddContextModal from "./AddContext/AddContextModal";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import { AiaActivationConfig } from "organisms/AiaActivation/consts";
import { PROFILE_SECTION_IDS } from "./ProfileNavigation/constants";
import useProfileNavigation from "./ProfileNavigation/useProfileNavigation";
import WithActivation from "organisms/AiaActivation/WithActivation";
import { useAppSelector } from "hooks/useAppSelector";
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import { AiaAnalysisResult } from "gql/graphql";

const AIA_FEATURE_LIMITS = [
  "GENERATE_EMAIL",
  "ADD_CONTEXT",
  "DOWNLOAD_PROFILE_PDF",
];

interface HeroProps {
  company: any;
  // TODO: type company properly
  // company: {
  //   id: string;
  //   _id: string;
  //   slug: string;
  //   country: string;
  //   city: string;
  //   displayName: string;
  //   logo: {
  //     large: {
  //       location: string;
  //     };
  //   };
  //   meetingLink: string;
  //   pitchVideoLink: string;
  //   publicUrl: string;
  //   email: string;
  //   pitch?: string;
  //   documents?: any[];
  // };
  analysis: AiaAnalysisResult;
  isLoading?: boolean;
  currencyCode: any;
  showAddContextModal?: boolean;
  showEditContextModal?: boolean;
  setShowAddContextModal: (state: boolean) => void;
  setShowEditContextModal: (state: boolean) => void;
  setIsChatHidden: (status: boolean) => void;
}

const Hero_Aia: FC<HeroProps> = ({
  company,
  analysis,
  isLoading,
  currencyCode,
  showAddContextModal,
  showEditContextModal,
  setShowEditContextModal,
  setShowAddContextModal,
  setIsChatHidden,
}) => {
  /* Selectors */
  const user = useSelector(getUser);
  const invitation = useSelector((state: any) => state?.invitation);
  const uiRepresentation = useAppSelector(
    (state) => state.companyProfile.uiRepresentationDetails
  );

  /* States */
  const [isViewSettingsModalOpen, setIsViewSettingsModalOpen] = useState(false);
  const [showIdentification, setShowIdentification] = useState(false);
  const [isPublicShareModalOpen, setIsPublicShareModalOpen] = useState(false);
  const [isAddMeetingLinkModalOpen, setIsAddMeetingLinkModalOpen] =
    useState(false);
  const [isGenerateEmailModalOpen, setIsGenerateEmailModalOpen] =
    useState(false);

  const { openModal: openUpgradeModal } = useUpgradePlanModal();

  const { profileSectionRef } = useProfileNavigation();

  const aiaRates = useAiaRateLimits(user?.id, AIA_FEATURE_LIMITS);

  const generateEmailRates =
    aiaRates?.formattedAiaRates &&
    aiaRates?.formattedAiaRates["GENERATE_EMAIL"];
  const addContextRates =
    aiaRates?.formattedAiaRates && aiaRates?.formattedAiaRates["ADD_CONTEXT"];
  const downloadProfileRates =
    aiaRates?.formattedAiaRates &&
    aiaRates?.formattedAiaRates["DOWNLOAD_PROFILE_PDF"];

  const isGenEmailGranted = Boolean(generateEmailRates?.remaining);

  const isAddContextGranted = Boolean(addContextRates?.remaining);
  const isDownLoadProfileGranted = Boolean(downloadProfileRates?.remaining);

  /* Handlers */
  const isMe = user && user._id === company._id;

  const openMeetingLink = () => {
    window.open(company?.meetingLink, "_blank");
  };

  const handleIdentification = (isIdentified: boolean) => {
    if (isIdentified) {
      openMeetingLink();
    }
    setShowIdentification(false);
  };

  const openEmailModal = () => {
    setIsChatHidden(true);
    setIsGenerateEmailModalOpen(true);
  };

  if (isLoading || aiaRates?.isRatesLoading) {
    return (
      <div className="mx-auto my-4">
        <Skeleton circle className="!w-24 !h-24 mb-4" />
        <Skeleton count={3} />
      </div>
    );
  }

  return (
    <div
      // This section is considered as part of executive summary with regards to profile navigation
      id={PROFILE_SECTION_IDS["Executive Summary"]}
      ref={profileSectionRef}
      className="grid grid-cols-1 py-4 md:grid-cols-2 lg:gap-x-4"
    >
      <div className="flex flex-col">
        <div className="flex flex-col lg:flex-row justify-betweeen">
          <div className="rounded-full mx-auto lg:mx-0 md:w-[10rem] mb-4">
            <img
              src={
                company?.logo?.large?.location ||
                "https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-2.png"
              }
              alt={`${company?.displayName} logo`}
              className="shadow-xl h-[8rem] md:h-[10rem] min-w-[8rem] md:min-w-[10rem] mx-auto rounded-full object-cover md:outline md:outline-white/50 dark:md:outline-white/20 md:outline-[10px] md:outline-offset-[-9px]"
            />
          </div>
          <div className="my-auto lg:ml-8 md:px-0">
            <h2 className="text-[#000] dark:text-white/80 md:text-5xl">
              {company?.displayName}
            </h2>
            {company?.country && (
              <div className="pr-4 mr-4">
                <ReactCountryFlag
                  countryCode={getCountryCode(company?.country)}
                  svg={true}
                  className="mb-0.5 mr-2"
                />
                <span className="text-sm font-normal dark:text-white/80">
                  {company?.city}
                  {company?.city && ","} {company?.country}
                </span>
              </div>
            )}
            {invitation?.inviterCompanyName && (
              <p className="text-base text-[#292F2E]">
                Invited by "{invitation?.inviterCompanyName}"
              </p>
            )}
            <div className="mb-2">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setIsPublicShareModalOpen(true);
                }}
                color="primary"
                className="justify-center w-full mt-2 md:w-auto"
                size="small"
              >
                <FontAwesomeIcon
                  icon={["fas", "share-alt"]}
                  className="mr-2 text-white"
                />
                <span>Share</span>
              </Button>

              <br />

              {company?.amITeamMember && (
                <DownloadProfile
                  companyId={company?.id}
                  companyName={company?.displayName}
                  user={user}
                  isDownLoadProfileGranted={isDownLoadProfileGranted}
                />
              )}
            </div>
          </div>
        </div>

        <div className="hidden general-info-container md:block">
          <GeneralInfo
            website={company?.website}
            industries={company?.industries}
            city={company?.city}
            foundersGender={company.foundersGender}
            fullAddress={company?.fullAddress}
            businessCategory={company?.businessCategory}
            businessModel={company?.businessModel}
            founded={company?.founded}
            teamSize={company?.teamSize}
            investors={company?.investors}
            founders={company?.founders !== null && company?.founders[0]}
            raisedValue={company?.finance?.raisedValue}
            raisedFrom={company?.finance?.raisedFrom}
            currencyCode={currencyCode}
            companyStage={company?.companyStage}
            socials={company?.socials}
            businessTypeNote={company?.businessTypeNote}
            businessCategoryNote={company?.businessCategoryNote}
            isProfileGeneratedByAIA={true}
            uiRepresentation={uiRepresentation?.generalInfo}
          />

          <div className="flex gap-x-2 mt-2">
            {company?.amITeamMember && (
              /* Hide activation until add context is updated             
              <WithActivation config={AiaActivationConfig.AddFiles}>
               */ <Button
                color="transparent"
                className="justify-center w-full mt-2 md:w-auto whitespace-nowrap"
                size="small"
                onClick={() => {
                  // checks the tier of the user and shows the upgrade modal if the user is on the lower tier
                  isAddContextGranted
                    ? setShowAddContextModal(true)
                    : openUpgradeModal();
                }}
              >
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-2" />
                <span>Add Context</span>
              </Button>
              /*               </WithActivation>
               */
            )}
            {company?.amITeamMember && (
              <WithActivation config={AiaActivationConfig.GenerateEmail}>
                <Button
                  color="transparent"
                  className="justify-center w-full mt-2 md:w-auto whitespace-nowrap"
                  size="small"
                  onClick={() => {
                    isGenEmailGranted ? openEmailModal() : openUpgradeModal();
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    className="mr-2"
                  />
                  <span>Generate email</span>
                </Button>
              </WithActivation>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-full mt-2 lg:w-full">
        {company?.documents && company?.documents.length > 0 && (
          <PitchDeck
            documents={company?.documents}
            hidePdfList
            containerClassName="flex-grow"
          />
        )}

        <div className="hidden md:flex">
          <FundraisingOverview_Aia
            finance={company?.finance}
            valuation={company?.valuation}
            raisedTarget={company?.finance?.raisedTarget}
            raisedValue={company?.finance?.raisedValue}
            minTicketSize={company?.finance?.minTicketSize}
            currencyCode={currencyCode}
            fit={company?.fit}
            fundraisingCloseOn={company?.finance?.fundraisingCloseOn}
            committed={company?.finance?.committed}
            isProfileGeneratedByAIA
          />
        </div>
      </div>

      {/* ========== Modals ========== */}
      {isPublicShareModalOpen && (
        <PublicShare
          title={company?.displayName}
          publicUrl={company?.publicUrl}
          onClose={() => setIsPublicShareModalOpen(false)}
          shareAia={true}
        />
      )}

      {isMe && (
        <AddMeetingLinkModal
          isOpen={isAddMeetingLinkModalOpen}
          onClose={() => {
            setIsAddMeetingLinkModalOpen(false);
          }}
          meetingLink={company.meetingLink}
        />
      )}

      {isMe && (
        <ViewSettings
          isOpen={isViewSettingsModalOpen}
          onClose={() => {
            setIsViewSettingsModalOpen(false);
          }}
        />
      )}

      {showIdentification && (
        <ViewIdentification
          onFinish={handleIdentification}
          startupSlug={company?.slug}
          isMeetingRequest
        >
          <p>
            The owner of "{company?.displayName}" has restricted access to their
            profile.
          </p>

          <p>
            Please introduce yourself in order to get their <b>meeting link</b>.
          </p>
        </ViewIdentification>
      )}

      {showAddContextModal && (
        <AddContextModal
          company={company}
          analysis={analysis}
          uiRepresentation={uiRepresentation}
          companyId={company?.id}
          onClose={() => setShowAddContextModal(false)}
        />
      )}

      <GenerateEmailModal
        companyId={company?.id}
        user={user}
        isOpen={isGenerateEmailModalOpen}
        onClose={() => setIsGenerateEmailModalOpen(false)}
      />
    </div>
  );
};
export default Hero_Aia;
