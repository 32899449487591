import { AddedContextResult, FieldName, Startup } from "gql/graphql";
import { FC } from "react";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  setUpdatedFields: (fields: FieldName[]) => void;
  updatedFields: FieldName[];
}

const EditableTractionVerbose = withContentEditable<ValueProps>(
  Value,
  FieldName.Traction
);

const EditableTractionSummary = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryTraction
);

const TractionAddedContext: FC<Props> = ({
  newContext,
  company,
  setUpdatedFields,
  updatedFields,
}) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.ExecuteveSummaryTraction}
          title={"Traction Summary"}
          currentContext={company?.traction?.summary ?? ""}
          newContext={newContext?.financials?.tractionSummary ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          hidePrimaryButton
          editableField={(content) => (
            <EditableTractionSummary
              title={"Traction Summary"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.financials?.tractionSummary,
      label: "Traction Summary",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.Traction}
          title="Traction"
          currentContext={company?.traction?.verbose ?? ""}
          newContext={newContext?.traction?.verbose ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditableTractionVerbose
              title={"Traction"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.traction?.verbose,
      label: "Traction",
    },
  ];

  return <EditContextWrapper header="Traction" components={config} />;
};

export default TractionAddedContext;
