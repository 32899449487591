import { gql } from "@apollo/client";

export const GET_AIA_TASK = gql`
  query getAIATask(
    $id: String
    $companyId: String
    $companySlug: String
    $type: AiaTaskType
  ) {
    aia {
      task(
        id: $id
        companyId: $companyId
        companySlug: $companySlug
        type: $type
      ) {
        status
        payload {
          teamAnalysisFinished
          competitorsFinished
          investmentFinished
          marketOppFinished
          executiveSummaryFinished
          aiaCriteriaFinished
          competitorValidation
          legislationFinished
          profileLink
          profileLoaded {
            basics
            productDescription
            elevatorPitch
            team
            investors
            appStore
            industries
            businessRelation
            productCategory
            sdg
            valuation
            raisedValue
            raisedTarget
            socials
            tractionSummary
            tractionVerbose
            marketOpportunity
            timing
            businessModel
            webScrape
            perplexityCompetitors
            competitorsFromDeck
          }
          addedContext
        }
      }
    }
  }
`;

export const GET_AIA_TASK_STATUS = gql`
  query getAIATaskStatus($companySlug: String) {
    aia {
      task(companySlug: $companySlug) {
        id
        status
      }
    }
  }
`;
