import { gql } from "@apollo/client";

export const ADD_CONTEXT = gql`
  query addContext($file: Upload, $url: String,  $text: String, $companyId: String!) {
    aia {
      addContext(file: $file, url: $url, text: $text, companyId: $companyId) {
        basics {
          website
          industry
          productCategory
          socials {
            instagram
            twitter
            facebook
            linkedin
          }
        }
        productDescription {
          productDescription
          valueProposition
          customers
        }
        financials {
          tractionSummary
          pricing
          businessRelation
          raisedTarget
          investmentStage
          companyStage
        }
        marketOpp {
          sdgs
          marketOpportunity
          businessModelDescription
        }
        team {
          teamUiRepresentation {
            teamMembers {
              id
              educationHtml
              experienceHtml
            }
            founders {
              id
              educationHtml
              experienceHtml
            }
            advisors {
              id
              educationHtml
              experienceHtml
            }
            investors {
              id
              educationHtml
              experienceHtml
            }
          }
          teamMembers {
            id
            name
            title
          }
          advisors {
            name
            title
          }
          founders {
            id
            name
            title
          }
          investors {
            name
            title
          }
        }
        competitors {
          id
          name
          hq {
            iso2
            name
            emoji
          }
          presence {
            name
            iso2
            emoji
          }
          logo {
            large {
              location
            }
          }
          founders {
            name
            title
          }
          productOverview {
            customerSegment
            businessModel
            productDescription
            keyFeatures
            valueProposition
            pricing
          }
          linkedInCompany {
            companyUrl
            followerCount
          }
          pitch
          source
          comparison {
            similarities
            differences
          }
          appstore {
            appInfo {
              currentVersionScore
              numberOfRatings
              appStoreUrl
            }
          }
          competitiveLandscapeAnalysis {
            marketPositionAnalysis
          }
          finances {
            revenue {
              year
              revenue
            }
            crunchbaseUrl
            pitchbookUrl
            fundingRounds {
              name
              moneyRaised
              announcedDate
              leadInvestors
              numberOfInvestors
              investors {
                name
                partners
              }
            }
          }
          website
          isValidated
          isDeepDive
        }
      }
    }
  }
`;

export const SYNTHESIZE_FIELD = gql`
  query synthesizeField(
    $newContext: String!
    $currentContent: String!
    $fieldName: String!
  ) {
    aia {
      synthesizeField(
        newContext: $newContext
        currentContent: $currentContent
        fieldName: $fieldName
      ) {
        synthesis
        fieldName
      }
    }
  }
`;

export const RE_ANALYZE_BY_FIELDS = gql`
  query analyzeByFields($companyId: String!, $fields: [FieldName!]!) {
    aia {
      analyzeByFields(companyId: $companyId, fields: $fields) {
        analysis {
          team {
            sameIndustryExperience
            criticalCompetences
            coExperiences
            previouslyStudiedTogether
          }
          investment {
            questionsFromFounders
            riskAnalysis
            opportunityAnalysis
          }
          marketOpp {
            marketOpp
            blueOcean
            sources {
              url
              title
            }
          }
          legislation {
            todaysLegislation
            futureLegislation
          }
          executiveSummary {
            problem
            product
            team
            marketOpp
            businessModel
            goToMarket
          }
          criteriaAnalysis {
            score
            evaluations {
              category
              description
              criteriaFulfillmentStatus
              comment
              importance
            }
          }
        }
        done
      }
    }
  }
`;