//@ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { modalAnimation, modalBgAnimation } from "ui/animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "./Modal.style";
import { enableBodyScroll } from "body-scroll-lock";
import { useDispatch } from "react-redux";
import { triggerOfferModal } from "organisms/OfferModal/sagaActions";
import { setGlobalState } from "models/globalState/sagaActions";

const enableBody = (target = document.body) => enableBodyScroll(target);

export const scrollModalToTop = () => {
  const modalContainer = document.getElementById("ModalScrollableContainer");
  if (modalContainer) {
    modalContainer.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

// @TODO: use real type instead of any
//
export const CustomModal = ({
  type,
  width,
  onClick,
  children,
  ...props
}: any) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(triggerOfferModal({ isEnabled: false, data: null }));
    dispatch(
      setGlobalState({
        offerModalIsOpen: false,
        showOfferRequestVerification: false,
      })
    );
    enableBody();

    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  const modal = (
    <AnimatePresence>
      <div className="fixed inset-0 bottom-0 z-[99] flex items-center justify-center h-full max-h-full p-0 lg:h-auto lg:px-4 lg:py-12">
        <motion.div
          onClick={() => {
            closeModal();
          }}
          variants={modalBgAnimation}
          initial="hidden"
          exit="hidden"
          animate="show"
          className="fixed inset-0 transition-opacity"
        >
          <div className="absolute inset-0 opacity-70 bg-[#27364B]" />
        </motion.div>

        <Modal
          variants={modalAnimation}
          initial="hidden"
          exit="hidden"
          animate="show"
          type={type}
          width={width}
          {...props}
        >
          {!props.hideCloseButton && (
            <div className="absolute top-0 right-0 z-20 pt-4 pr-5">
              <button
                type="button"
                className="leading-none transition duration-150 ease-in-out sm:text-white hover:text-gray-300 focus:outline-none __close"
                aria-label="Close"
                onClick={() => {
                  closeModal();
                }}
              >
                <FontAwesomeIcon className="text-2xl" icon={["fal", "times"]} />
              </button>
            </div>
          )}
          {children}
        </Modal>
      </div>
    </AnimatePresence>
  );

  return ReactDOM.createPortal(modal, document.getElementById("modal-portal"));
};

export default CustomModal;
