import { FC } from "react";
import withValueEditable from "organisms/ValueEditable/ValueEditable";
import { FieldName } from "gql/graphql";
import { Value, ValueProps } from "./Value";
import { TEAM_SIZE } from "core/consts";

interface Props {
  teamSize?: string;
  uiRepresentation?: string | null | undefined;
  hideLabel?: boolean;
  fullWidth?: boolean;
  editable?: boolean;
}

const EditableValue = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoTeamSize
);

const TeamSize: FC<Props> = ({
  teamSize,
  uiRepresentation,
  hideLabel,
  fullWidth,
  editable = true,
}) => {
  const getContent = () => {
    if (uiRepresentation) return uiRepresentation;
    if (teamSize) return (TEAM_SIZE as any)[teamSize];
    return "";
  };

  const containerStyles = fullWidth
    ? ""
    : "grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80 industry-div";

  return (
    <div className={containerStyles}>
      {!hideLabel && <h6 className="font-medium">Team Size</h6>}

      {editable ? (
        <EditableValue content={getContent()} />
      ) : (
        <Value content={getContent()} />
      )}
    </div>
  );
};

export default TeamSize;
