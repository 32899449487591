// INFO: This hook was created to avoid creating separate states for every modal/dialog to maintain the open/close states.

import { useCallback, useState } from "react";

type UseSwitchReturnType = [boolean, () => void, () => void];

export const useSwitch = (defaultState: boolean): UseSwitchReturnType => {
  const [state, setState] = useState<boolean>(defaultState);

  const on = useCallback((): void => setState(true), []);
  const off = useCallback((): void => setState(false), []);

  return [state, on, off];
};
