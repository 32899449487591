import React, { useCallback, useState } from "react";
import Calendar from "react-calendar";
import { useLang } from "core/lang/LangaugeSetting";
import { gql } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { enableBodyScroll } from "body-scroll-lock";
import Button from "atoms/Button/Button";
import SingleSelect from "molecules/Form/SingleSelect";
import { APPLICATION_STATUS } from "core/consts";
import Modal from "atoms/Modal/Modal";
import xlsx from "json-as-xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormInput from "atoms/Form/FormInput";
import { useForm } from "react-hook-form";
import "react-calendar/dist/Calendar.css";
import ClickOutside from "atoms/ClickOutside";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalState } from "models/globalState/sagaActions";
import { getUser } from "models/user/selectors";
import { getGlobalState } from "models/globalState/selectors";
import { triggerSnack } from "organisms/Snack/sagaActions";

const DOWNLOAD_EXCEL_FOR_APPLICATIONS = gql`
  query downloadExcelForApplications($input: excelDataInput!) {
    downloadExcelForApplications(input: $input) {
      comments
      note
      company {
        displayName
        publicUrl
        slug
        businessCategory
        businessTypeNote
        businessCategoryNote
        businessModelDescription
        city
        state
        country
        phone
        companyStage
        currency
        email
        expectationsNote
        finance {
          raisedFrom
          raisedValue
          raisedTarget
          note
        }
        fit
        founded
        founders {
          name
        }
        fullAddress
        industries
        investorNote
        investors {
          displayName
        }
        pitch
        smartCityCategory
        socials {
          youtube
          facebook
          instagram
          linkedin
          twitter
        }
        sustainableDevelopmentGoals {
          items
        }
        teamMembers {
          name
        }
        teamSize
        valuation
        website
        completedMajorSteps
        subCategories
        businessModel
      }
      companyTraction {
        _id
        company
        activeUsers {
          amount
          frequency
        }
        activeUsersNote
        totalUsers {
          type
          count
        }
        totalUsersNote
        churnRate {
          period
          percentage
        }
        churnRateNote
        growthRate {
          period
          amount
          rate
          time
        }
        growthRateNote
        revenueRate {
          period
          amount
          rate
          time
        }
        revenueRateNote
        retentionRate {
          period
          amount
          rate
          time
        }
        retentionRateNote
        netRevenue {
          time
          amount
          currency
        }
        netRevenueNote
        recurringRevenue {
          time
          amount
          currency
        }
        recurringRevenueNote
        smv_gsv {
          time
          amount
          currency
        }
        smv_gsvNote
        salePriceAvg {
          amount
          currency
        }
        salePriceAvgNote
        ratePercentage
        ratePercentageNote
        cacPayback {
          amount
          frequency
        }
        cacPaybackNote
        ltcCacRatio
        ltcCacRatioNote
        demandFreq {
          unit
          amount
          frequency
        }
        demandFreqNote
        supplyFreq {
          unit
          amount
          frequency
        }
        supplyFreqNote
        signedContracts
        signedContractsNote
        pipelineContracts
        pipelineContractsNote
        annualValueContracts {
          amount
          currency
        }
        annualValueContractsNote
        lettersOfIntent
        lettersOfIntentNote
        additionalTraction
        annualValueContractsPipeline {
          amount
          currency
        }
        annualValueContractsPipelineNote
      }
      currency
    }
  }
`;

const enableBody = (target) => enableBodyScroll(target);

const DATE_FORMAT = "dd/MM/yyyy";

export default ({ closeModal }) => {
  const { lang } = useLang();

  const dispatch = useDispatch();
  const globalState = useSelector(getGlobalState);

  const updateGlobalState = (params) => {
    dispatch(setGlobalState(params));
  };

  const client = useApolloClient();
  const user = useSelector(getUser);

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, reset, setError } = useForm();
  const setSnack = (snackParams) => {
    dispatch(triggerSnack(snackParams));
  };
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [currentApplicationStatus, setCurrentApplicationStatus] = useState(
    APPLICATION_STATUS[0]
  );

  const [excelVariables, setExcelVariables] = useState({
    applicationType: null,
    startDate: "",
    endDate: "",
  });

  const selectDownloadApplication = async (currentApplicationStatus) => {
    let applicationType;
    if (currentApplicationStatus === lang.pending) {
      applicationType = "PENDING";
    } else if (currentApplicationStatus === lang.approved) {
      applicationType = "APPROVED";
    } else if (currentApplicationStatus === lang.all) {
      applicationType = null;
    }

    setCurrentApplicationStatus(currentApplicationStatus);

    let variableCopy = Object.assign({}, excelVariables);
    variableCopy["applicationType"] = applicationType;

    setExcelVariables(Object.assign({}, variableCopy));
  };

  const getExcelFile = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      if (
        (!excelVariables.startDate && !excelVariables.endDate) ||
        (excelVariables.startDate && excelVariables.endDate)
      ) {
        setStartDateError(false);
        setEndDateError(false);
      } else if (!excelVariables.startDate) {
        setStartDateError(true);
        return;
      } else {
        setEndDateError(true);
        return;
      }

      setLoading(true);
      let nodes;
      try {
        nodes = await client.query({
          query: DOWNLOAD_EXCEL_FOR_APPLICATIONS,
          fetchPolicy: "network-only",
          variables: { input: excelVariables },
        });
      } catch (err) {
        setSnack({
          type: "error",
          title: lang.somethingWentWrong,
          message: lang.weCouldNotSave,
        });
        setLoading(false);
        return;
      }
      nodes = [...nodes.data.downloadExcelForApplications];
      if (!nodes.length) {
        setSnack({
          type: "error",
          title: lang.somethingWentWrong,
          message: lang.weCouldNotFindDataForProvidedFilters,
        });
        setLoading(false);
        return;
      }
      let content = [];
      for (let i = 0; i < nodes.length; i++) {
        let obj = {
          comments: nodes[i].company.comments,
          displayName: nodes[i].company.displayName,
          createdAt: nodes[i].company.createdAt,
          publicUrl: nodes[i].company.publicUrl,
          slug: nodes[i].company.slug,
          businessCategory: nodes[i].company.businessCategory
            ? nodes[i].company.businessCategory
            : [],
          businessCategoryNote: nodes[i].company.businessCategoryNote,
          businessModelDescription: nodes[i].company.businessModelDescription,
          businessModel: nodes[i].company.businessModel,
          businessTypeNote: nodes[i].company.businessTypeNote,
          city: nodes[i].company.city,
          state: nodes[i].company.state,
          country: nodes[i].company.country,
          phone: nodes[i].company.phone,
          companyStage: nodes[i].company.companyStage
            ? nodes[i].company.companyStage
            : [],
          email: nodes[i].company.email,
          expectationsNote: nodes[i].company.expectationsNote,
          finance: nodes[i].company.finance ? nodes[i].company.finance : {},
          fit: nodes[i].company.fit,
          founded: nodes[i].company.founded,
          founders: nodes[i].company.founders ? nodes[i].company.founders : [],
          fullAddress: nodes[i].company.fullAddress,
          industries: nodes[i].company.industries
            ? nodes[i].company.industries
            : [],
          investorNote: nodes[i].company.investorNote,
          investors: nodes[i].company.investors
            ? nodes[i].company.investors
            : [],
          pitch: nodes[i].company.pitch,
          subCategories: nodes[i].company.subCategories
            ? nodes[i].company.subCategories
            : [],
          socials: nodes[i].company.socials,
          sustainableDevelopmentGoals:
            nodes[i].company.sustainableDevelopmentGoals,
          teamMembers: nodes[i].company.teamMembers
            ? nodes[i].company.teamMembers
            : [],
          teamSize: nodes[i].company.teamSize,
          valuation: nodes[i].company.valuation,
          website: nodes[i].company.website,
          note: nodes[i].note && nodes[i].note ? nodes[i].note : "NA",
          currency: nodes[i].currency,
          closingDate: nodes[i]?.closingDate ? nodes[i].closingDate : "NA",
          completedMajorSteps: nodes[i].company.completedMajorSteps
            ? nodes[i].company.completedMajorSteps
            : [],
          activeUsers: nodes[i].activeUsers ? nodes[i].activeUsers : [],
          activeUsersNote: nodes[i].activeUsersNote
            ? nodes[i].activeUsersNote
            : "NA",
          totalUsers: nodes[i].totalUsers ? nodes[i].totalUsers : [],
          totalUsersNote: nodes[i].totalUsersNote,
          churnRate: nodes[i].churnRate ? nodes[i].churnRate : [],
          churnRateNote: nodes[i].churnRateNote,
          growthRate: nodes[i].growthRate ? nodes[i].growthRate : [],
          growthRateNote: nodes[i].growthRateNote,
          revenueRate: nodes[i].revenueRate ? nodes[i].revenueRate : [],
          revenueRateNote: nodes[i].revenueRateNote,
          retentionRate: nodes[i].retentionRate ? nodes[i].retentionRate : [],
          retentionRateNote: nodes[i].retentionRateNote,
          netRevenue: nodes[i].netRevenue ? nodes[i].netRevenue : [],
          netRevenueNote: nodes[i].netRevenueNote,
          recurringRevenue: nodes[i].recurringRevenue
            ? nodes[i].recurringRevenue
            : [],
          recurringRevenueNote: nodes[i].recurringRevenueNote,
          salePriceAvg: nodes[i].salePriceAvg ? nodes[i].salePriceAvg : [],
          salePriceAvgNote: nodes[i].salePriceAvgNote,
          ratePercentage: nodes[i].ratePercentage,
          ratePercentageNote: nodes[i].ratePercentageNote,
          cacPayback: nodes[i].cacPayback ? nodes[i].cacPayback : [],
          cacPaybackNote: nodes[i].cacPayback,
          ltcCacRatio: nodes[i].ltcCacRatio,
          ltcCacRatioNote: nodes[i].ltcCacRatioNote,
          demandFreq: nodes[i].demandFreq ? nodes[i].demandFreq : [],
          demandFreqNote: nodes[i].demandFreqNote,
          supplyFreq: nodes[i].supplyFreq ? nodes[i].supplyFreq : [],
          supplyFreqNote: nodes[i].supplyFreqNote,
          signedContracts: nodes[i].signedContracts,
          signedContractsNote: nodes[i].signedContractsNote,
          pipelineContracts: nodes[i].pipelineContracts,
          pipelineContractsNote: nodes[i].pipelineContractsNote,
          annualValueContracts: nodes[i].annualValueContracts
            ? nodes[i].annualValueContracts
            : [],
          annualValueContractsNote: nodes[i].annualValueContractsNote,
          lettersOfIntent: nodes[i].lettersOfIntent,
          lettersOfIntentNote: nodes[i].lettersOfIntentNote,
          additionalTraction: nodes[i].additionalTraction,
          annualValueContractsPipeline: nodes[i].additionalTraction
            ? nodes[i].additionalTraction
            : [],
          annualValueContractsPipelineNote:
            nodes[i].annualValueContractsPipelineNote,
        };
        content.push(obj);
      }

      let data = [
        {
          sheet: "Applications",

          columns: [
            { label: "COMPANY NAME", value: "displayName" }, // Top level data
            {
              label: "PUBLIC URL",
              value: "publicUrl",
            }, // Run functions
            {
              label: "WEBSITE",
              value: (row) => (row.website ? row.website.trim() : "NA"),
            },
            {
              label: "PITCH",
              value: (row) => (row.pitch ? row.pitch.trim() : "NA"),
            },
            {
              label: "COMPANY STAGE",
              value: (row) =>
                row.companyStage && row.companyStage.length
                  ? row.companyStage.join(", ")
                  : "NA",
            },
            {
              label: "INDUSTRIES",
              value: (row) =>
                row.industries && row.industries.length
                  ? row.industries.join(", ")
                  : "NA",
            },
            {
              label: "SUB CATEGORIES",
              value: (row) =>
                row.subCategories && row.subCategories.length
                  ? row.subCategories.join(", ")
                  : "NA",
            },
            {
              label: "BUSINESS CATEGORIES",
              value: (row) =>
                row.businessModel && row.businessModel.length
                  ? row.businessModel.join(", ")
                  : "NA",
            }, // Deep props
            {
              label: "BUSINESS CATEGORY NOTE",
              value: (row) =>
                row.businessCategoryNote
                  ? row.businessCategoryNote.trim()
                  : "NA",
            },
            {
              label: "BUSINESS TYPE",
              value: (row) =>
                row.businessCategory && row.businessCategory.length
                  ? row.businessCategory.join(", ")
                  : "NA",
            },
            {
              label: "BUSINESS TYPE NOTE",
              value: (row) =>
                row.businessTypeNote ? row.businessTypeNote.trim() : "NA",
            },
            {
              label: "FOUNDED",
              value: (row) => (row.founded ? row.founded.trim() : "NA"),
            },
            {
              label: "CITY",
              value: (row) => (row.city ? row.city.trim() : "NA"),
            },
            {
              label: "STATE",
              value: (row) => (row.state ? row.state.trim() : "NA"),
            },
            {
              label: "COUNTRY",
              value: (row) => (row.country ? row.country.trim() : "NA"),
            },
            {
              label: "BUSINESS MODEL DESCRIPTION",
              value: (row) =>
                row.businessModelDescription
                  ? row.businessModelDescription.trim()
                  : "NA",
            },
            {
              label: "EXPECTATIONS NOTE",
              value: (row) =>
                row.expectationsNote ? row.expectationsNote.trim() : "NA",
            },
            {
              label: "SDGs",
              value: (row) =>
                row.sustainableDevelopmentGoals &&
                row.sustainableDevelopmentGoals.items &&
                row.sustainableDevelopmentGoals.items.length
                  ? row.sustainableDevelopmentGoals.items.join(", ")
                  : "NA",
            },
            {
              label: "CURRENCY",
              value: (row) => (row.currency ? row.currency.trim() : "NA"),
            },
            {
              label: "CLOSING DATE",
              value: (row) => (row.closingDate ? row.closingDate.trim() : "NA"),
            },
            {
              label: "VALUATION",
              value: (row) => (row.valuation ? row.valuation.trim() : "NA"),
            },
            {
              label: "FINANCE RAISED TARGET",
              value: (row) =>
                row.finance && row.finance.raisedTarget
                  ? row.finance.raisedTarget.trim()
                  : "NA",
            },
            {
              label: "FINANCE RAISED VALUE",
              value: (row) =>
                row.finance && row.finance.raisedValue
                  ? row.finance.raisedValue.trim()
                  : "NA",
            },
            {
              label: "FINANCE NOTE",
              value: (row) =>
                row.finance && row.finance.note
                  ? row.finance.note.trim()
                  : "NA",
            },
            {
              label: "FINANCE RAISED FROM",
              value: (row) =>
                row.finance &&
                row.finance.raisedFrom &&
                row.finance.raisedFrom.length
                  ? row.finance.raisedFrom
                      .map((name) => name?.trim())
                      .join(", ")
                  : "NA",
            },
            {
              label: "INVESTORS",
              value: (row) =>
                row.investors && row.investors.length
                  ? row.investors.map((f) => f.displayName?.trim()).join(", ")
                  : "NA",
            },
            {
              label: "INVESTOR NOTE",
              value: (row) =>
                row.investorNote ? row.investorNote.trim() : "NA",
            },
            { label: "FIT", value: (row) => (row.fit ? row.fit.trim() : "NA") },
            {
              label: "FOUNDERS",
              value: (row) =>
                row.founders && row.founders.length
                  ? row.founders.map((f) => f?.name?.trim()).join(", ")
                  : "NA",
            },
            {
              label: "TEAM SIZE",
              value: (row) => (row.teamSize ? row.teamSize.trim() : "NA"),
            },
            {
              label: "TEAM MEMBERS",
              value: (row) =>
                row.teamMembers && row.teamMembers.length
                  ? row.teamMembers.map((f) => f?.name?.trim()).join(", ")
                  : "NA",
            },
            {
              label: "SOCIALS (YOUTUBE)",
              value: (row) =>
                row.socials && row.socials.youtube
                  ? row.socials.youtube.trim()
                  : "NA",
            },
            {
              label: "SOCIALS (FACEBOOK)",
              value: (row) =>
                row.socials && row.socials.facebook
                  ? row.socials.facebook.trim()
                  : "NA",
            },
            {
              label: "SOCIALS (INSTAGRAM)",
              value: (row) =>
                row.socials && row.socials.instagram
                  ? row.socials.instagram.trim()
                  : "NA",
            },
            {
              label: "SOCIALS (LINKEDIN)",
              value: (row) =>
                row.socials && row.socials.linkedin
                  ? row.socials.linkedin.trim()
                  : "NA",
            },
            {
              label: "SOCIALS (TWITTER)",
              value: (row) =>
                row.socials && row.socials.twitter
                  ? row.socials.twitter.trim()
                  : "NA",
            },
            {
              label: "FULL ADDRESS",
              value: (row) => (row.fullAddress ? row.fullAddress.trim() : "NA"),
            },
            {
              label: "PHONE",
              value: (row) => (row.phone ? row.phone.trim() : "NA"),
            },
            {
              label: "EMAIL ID",
              value: (row) => (row.email ? row.email.trim() : "NA"),
            },
            {
              label: "NOTES",
              value: (row) => (row.note ? row.note.trim() : "NA"),
            },
            {
              label: "COMMENTS",
              value: (row) => (row.comments ? row.comments.trim() : "NA"),
            },
            {
              label: "STATUS",
              value: (row) =>
                row.completedMajorSteps &&
                row.completedMajorSteps.length &&
                row.completedMajorSteps.includes("APPLICATION_SENT")
                  ? "APPROVED"
                  : "PENDING",
            },
            {
              label: "ACTIVE USERS",
              value: (row) =>
                row.activeUsers && row.activeUsers.amount
                  ? row.activeUsers.amount.trim()
                  : "NA",
            },
            {
              label: "ACTIVE USERS FREQUENCY",
              value: (row) =>
                row.activeUsers && row.activeUsers.frequency
                  ? row.activeUsers.frequency.trim()
                  : "NA",
            },
            {
              label: "TOTAL USERS",
              value: (row) =>
                row.totalUsers && row.totalUsers.amount
                  ? row.totalUsers.amount.trim()
                  : "NA",
            },
            {
              label: "CHURN RATE",
              value: (row) =>
                row.churnRate.percentage && row.churnRate.percentage
                  ? row.churnRate.percentage.trim()
                  : "NA",
            },
            {
              label: "GROWTH RATE AMOUNT",
              value: (row) =>
                row.growthRate.amount && row.growthRate.amount
                  ? row.growthRate.amount.trim()
                  : "NA",
            },
            {
              label: "GROWTH RATE RATE",
              value: (row) =>
                row.growthRate.rate && row.growthRate.rate
                  ? row.growthRate.rate.trim()
                  : "NA",
            },
            {
              label: "REVENUE RATE AMOUNT",
              value: (row) =>
                row.revenueRate.amount && row.revenueRate.amount
                  ? row.revenueRate.amount.trim()
                  : "NA",
            },
            {
              label: "REVENUE RATE RATE",
              value: (row) =>
                row.revenueRate.rate && row.revenueRate.rate
                  ? row.revenueRate.rate.trim()
                  : "NA",
            },
            {
              label: "RETENTION RATE AMOUNT",
              value: (row) =>
                row.retentionRate.amount && row.retentionRate.amount
                  ? row.retentionRate.amount.trim()
                  : "NA",
            },
            {
              label: "RETENTION RATE RATE",
              value: (row) =>
                row.retentionRate.rate && row.retentionRate.rate
                  ? row.retentionRate.rate.trim()
                  : "NA",
            },
            {
              label: "NET REVENUE AMOUNT",
              value: (row) =>
                row.netRevenue.amount && row.netRevenue.amount
                  ? row.netRevenue.amount.trim()
                  : "NA",
            },
            {
              label: "RECURRING REVENUE AMOUNT",
              value: (row) =>
                row.recurringRevenue.amount && row.recurringRevenue.amount
                  ? row.recurringRevenue.amount.trim()
                  : "NA",
            },
            {
              label: "SALES PRICE AVERAGE",
              value: (row) =>
                row.salePriceAvg.amount && row.salePriceAvg.amount
                  ? row.salePriceAvg.amount.trim()
                  : "NA",
            },
            {
              label: "ANNUAL VALUE CONTRACTS PIPELINE",
              value: (row) =>
                row.annualValueContractsPipeline.amount &&
                row.annualValueContractsPipeline.amount
                  ? row.annualValueContractsPipeline.amount.trim()
                  : "NA",
            },
          ],
          content: content,
        },
      ];

      let fileName;
      if (
        excelVariables.applicationType === null ||
        excelVariables.applicationType === undefined
      ) {
        fileName = "All Applications";
      } else if (excelVariables.applicationType === "PENDING") {
        fileName = "Pending Applications";
      } else if (excelVariables.applicationType === "APPROVED") {
        fileName = "Approved Applications";
      }

      let settings = {
        fileName: fileName,
        extraLength: 3,
        writeOptions: {},
      };
      try {
        xlsx(data, settings);
      } catch (err) {
        console.log({ xlsxError: err });
      }
      setLoading(false);
    },
    [excelVariables, client, globalState.network.status, lang, setSnack]
  );

  const resetForm = (e) => {
    e.preventDefault();
    setStartDate(null);
    setEndDate(null);
    setEndDateError(false);
    setStartDateError(false);
    setExcelVariables({
      applicationType: null,
      endDate: "",
      startDate: "",
    });
    setCurrentApplicationStatus(APPLICATION_STATUS[0]);
  };

  return (
    <Modal
      width="32rem"
      hideCloseButton
      onClose={closeModal}
      style={{ overflow: "visible" }}
    >
      <div className="flex justify-end">
        <button
          type="button"
          className="text-gray-900 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
          aria-label="Close"
          onClick={() => closeModal()}
        >
          <FontAwesomeIcon className="text-2xl" icon={["fal", "times"]} />
        </button>
      </div>
      <form onSubmit={getExcelFile} className="pt-2">
        <p className="block h-full mb-1 font-medium text-black text-opacity-100">
          {lang.applications}
        </p>
        <SingleSelect
          showLabel={true}
          options={APPLICATION_STATUS}
          defaultValue={currentApplicationStatus}
          onChange={(e) => selectDownloadApplication(e)}
        />
        <div className="pt-2">
          <p className="block h-full mb-1 font-medium text-black text-opacity-100">
            {lang.startDate}
          </p>
          <FormInput
            name="startDate"
            id="startDate"
            readOnly={true}
            onClick={() => setShowStartDate((prevState) => !prevState)}
            iconPosition="right"
            placeholder="dd/mm/yyyy"
            defaultValue={excelVariables?.startDate}
            style={{ cursor: "context-menu" }}
            ref={{ ...register("startDate", { required: false }) }}
            error={startDateError && lang.mandatory}
            icon={
              <FontAwesomeIcon
                icon={["fas", "calendar-week"]}
                className="text-aqua-400"
                onClick={() => setShowStartDate((prevState) => !prevState)}
              />
            }
          />
          {showStartDate && (
            <ClickOutside
              onClick={() => setShowStartDate((prevState) => !prevState)}
            >
              <Calendar
                calendarType={"US"}
                maxDate={new Date()}
                value={startDate ? startDate : new Date()}
                onChange={(date) =>
                  setExcelVariables((prevState) => {
                    setStartDate(date);
                    prevState["startDate"] = format(date, DATE_FORMAT);
                    setShowStartDate((state) => !state);
                    return prevState;
                  })
                }
              />
            </ClickOutside>
          )}
        </div>
        <div className="pt-2">
          <p className="block h-full mb-1 font-medium text-black text-opacity-100">
            {lang.endDate}
          </p>
          <FormInput
            name="endDate"
            id="endDate"
            readOnly={true}
            onClick={() => setShowEndDate((prevState) => !prevState)}
            iconPosition="right"
            placeholder="dd/mm/yyyy"
            defaultValue={excelVariables?.endDate}
            style={{ cursor: "context-menu" }}
            ref={{ ...register("endDate", { required: false }) }}
            error={endDateError && lang.mandatory}
            icon={
              <FontAwesomeIcon
                icon={["fas", "calendar-week"]}
                className="text-aqua-400"
                onClick={() => setShowEndDate((prevState) => !prevState)}
              />
            }
          />
          {showEndDate && (
            <ClickOutside
              onClick={() => setShowEndDate((prevState) => !prevState)}
            >
              <Calendar
                calendarType={"US"}
                minDate={startDate ?? new Date()}
                maxDate={new Date()}
                value={endDate ? endDate : new Date()}
                onChange={(date) =>
                  setExcelVariables((prevState) => {
                    setEndDate(date);
                    prevState["endDate"] = format(date, DATE_FORMAT);
                    setShowEndDate((state) => !state);
                    return prevState;
                  })
                }
              />
            </ClickOutside>
          )}
        </div>
        <div className="flex items-end justify-center h-full pt-6">
          <Button
            type="submit"
            color={"primary"}
            disabled={loading}
            loading={loading}
            className="justify-center w-32 mx-2"
          >
            Download
          </Button>
          <Button
            type="reset"
            color={"transparent"}
            onClick={resetForm}
            disabled={loading}
            loading={loading}
            className="justify-center w-32 mx-2"
          >
            Reset
          </Button>
        </div>
      </form>
    </Modal>
  );
};
