// ? Design is required for this component

import { FC } from "react";

import Button from "atoms/Button/Button";
import { useDispatch } from "react-redux";
import { setGlobalState } from "models/globalState/sagaActions";

const AddContextError: FC = () => {
  const dispatch = useDispatch();

  const onHelpClicked = (): void => {
    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <span className="mt-4 text-center text-fire-500">
        Sorry, I was unable to read relevant information in this document.
        Please try again. If you still get an error, contact our product team
        below and we will take care of it. Please attach the pitch deck in the
        form.
      </span>

      <Button size="small" color="secondary" onClick={onHelpClicked}>
        <span className="text-center">Contact</span>
      </Button>
    </div>
  );
};

export default AddContextError;
