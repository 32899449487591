import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

import Button from "atoms/Button/Button";
import cn from "classnames";

interface Props {
  handleOnClickPreviousSection: () => void;
  handleOnClickNextSection: () => void;
  disabledBackButton: boolean;
  disabledNextButton: boolean;
  onClose: () => void;
}

const EditContextFooter: FC<Props> = ({
  handleOnClickPreviousSection,
  handleOnClickNextSection,
  disabledBackButton,
  disabledNextButton,
  onClose,
}) => (
  <div className="space-y-6 mt-6">
    <div className="border-b border-b-[#545b78] border-opacity-20 -mx-6 w-auto" />

    <div className="flex flex-row items-center justify-between">
      <Button
        className={cn(
          "rounded-md h-[36px] bg-white border !border-[#121317] gap-2",
          !disabledBackButton && "hover:opacity-70"
        )}
        onClick={handleOnClickPreviousSection}
        disabled={disabledBackButton}
      >
        <FontAwesomeIcon
          className="body-xs text-[#545B78]"
          icon={["fal", "arrow-left"]}
        />

        <span className="body-xs text-[#545B78]">Back</span>
      </Button>

      <div className="flex flex-row items-align gap-2">
        <Button
          className={cn(
            "rounded-md h-[36px] bg-white border !border-[#121317]",
            !disabledNextButton && "hover:opacity-70"
          )}
          onClick={handleOnClickNextSection}
          disabled={disabledNextButton}
        >
          <span className="body-xs text-[#545B78]">
            Continue without saving
          </span>
        </Button>

        <Button
          className={cn(
            "rounded-md h-[36px] bg-[#121317] gap-2",
            !disabledNextButton && "hover:opacity-70"
          )}
          onClick={handleOnClickNextSection}
          loading={false}
        >
          <span className="body-xs text-[#F3F5FA]">Save Updates</span>
        </Button>
      </div>
    </div>
  </div>
);

export default EditContextFooter;
