import { FC, useEffect, useState } from "react";
import {
  AiaAnalysisResult,
  AnalyzeByFieldsQuery,
  AnalyzeByFieldsQueryVariables,
  FieldName,
  Startup,
} from "gql/graphql";
import SectionHeader from "pages/companyProfile/components/shared/SectionHeader/SectionHeader";
import { useLazyQuery } from "@apollo/client";
import { RE_ANALYZE_BY_FIELDS } from "queries/aia/addContext";
import Button from "atoms/Button/Button";
import cn from "classnames";
import EditAnalysisResultsContext from "../AnalysisContext";

interface Props {
  company: Startup;
  analysis: AiaAnalysisResult;
  updatedFields: FieldName[];
}

const ReAnalyzeAnalysisParts: FC<Props> = ({
  company,
  updatedFields,
  analysis,
}) => {
  const [selected, setSelected] = useState<FieldName[]>(updatedFields);

  const [analyzeByFields, { data, loading }] = useLazyQuery<
    AnalyzeByFieldsQuery,
    AnalyzeByFieldsQueryVariables
  >(RE_ANALYZE_BY_FIELDS, {
    fetchPolicy: "no-cache",
  });

  const handleReAnalyze = () => {
    analyzeByFields({
      variables: {
        companyId: company.id,
        fields: selected,
      },
    });
  };

  useEffect(() => {
    const setFieldsToShow = setFieldNamesToShow(selected, true);
    if (setFieldsToShow && setFieldsToShow.length > 0) {
      setSelected(setFieldsToShow);
    }
  }, []);

  const addToFieldstoShow = (field: FieldName) => {
    let updatedSelection: FieldName[];
    if (selected.includes(field)) {
      updatedSelection = setFieldNamesToShow(
        selected.filter((s) => s !== field)
      );
    } else {
      updatedSelection = setFieldNamesToShow([...selected, field]);
    }
    setSelected(updatedSelection);
  };

  return (
    <div className="flex flex-col w-full justify-start h-full px-6">
      {data?.aia?.analyzeByFields?.done ? (
        <EditAnalysisResultsContext
          analysisResult={data?.aia?.analyzeByFields?.analysis ?? {}}
          currentAnalysis={analysis}
        />
      ) : (
        <>
          <div className="ml-6 mt-4">
            <SectionHeader title="Re-Analyze AIA estimates" />
          </div>
          <p className="text-base text-gray-600 px-6 py-2 mb-4">
            We have pre-selected suggestions for you to re-analyze. <br /> You
            can select or de-select any of the suggestions below to re-analyze
            the AIA estimates.
          </p>
          <div className="flex gap-6 xl:flex-row flex-col h-full overflow-y-auto px-6">
            <div className="h-full">
              <div className="flex flex-wrap gap-3 max-w-3xl">
                {Object.entries(fieldNameDisplayMapping).map(([key, value]) => (
                  <div
                    className={cn(
                      selected.includes(key as FieldName)
                        ? "bg-aqua-100 border border-aqua-400"
                        : "bg-gray-100 border border-gray-400",
                      "text-md px-6 py-2 rounded-md hover:cursor-pointer"
                    )}
                    key={key}
                    onClick={() => addToFieldstoShow(key as FieldName)}
                  >
                    {value}
                  </div>
                ))}
              </div>
              <Button
                color="primary"
                className="mt-6"
                rectangular
                onClick={handleReAnalyze}
                disabled={
                  loading ||
                  selected.length === 0 ||
                  !!data?.aia?.analyzeByFields?.analysis
                }
                loading={loading}
              >
                Re-Analyze
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReAnalyzeAnalysisParts;

const fieldNameDisplayMapping: Record<any, string> = {
  [FieldName.ExecuteveSummaryBusinessModel]: "Business Model",
  [FieldName.ExecuteveSummaryGoToMarketStrategy]: "Go To Market Strategy",
  [FieldName.ExecuteveSummaryProblem]: "Problem",
  [FieldName.ExecuteveSummaryProduct]: "Product",
  [FieldName.FiveQuestions]: "Five Questions For the Founders",
  [FieldName.MarketOppMarketSize]: "Market Size / Blue Ocean",
  [FieldName.RiskAnalysis]: "Risk / Opportunity Analysis",
  [FieldName.CurrentRegulations]: "Regulations",
  [FieldName.Team]: "Team Insights",
};

const setFieldNamesToShow = (
  fieldNames: FieldName[],
  isInitialRender?: boolean
) => {
  let fieldsToShow: FieldName[] = [];

  if (
    (fieldNames.includes(FieldName.Team) ||
      fieldNames.includes(FieldName.Founders)) &&
    isInitialRender
  ) {
    fieldsToShow.push(FieldName.Team);
    fieldsToShow.push(FieldName.RiskAnalysis);
    fieldsToShow.push(FieldName.ExecuteveSummaryTeam);
  }

  if (fieldNames.includes(FieldName.Team)) {
    fieldsToShow.push(FieldName.Team);
    fieldsToShow.push(FieldName.ExecuteveSummaryTeam);
  }

  if (fieldNames.includes(FieldName.FiveQuestions)) {
    fieldsToShow.push(FieldName.FiveQuestions);
  }

  if (fieldNames.includes(FieldName.RiskAnalysis)) {
    fieldsToShow.push(FieldName.RiskAnalysis);
  }

  if (fieldNames.includes(FieldName.BusinessModel)) {
    fieldsToShow.push(FieldName.ExecuteveSummaryBusinessModel);
    fieldsToShow.push(FieldName.ExecuteveSummaryGoToMarketStrategy);
    fieldsToShow.push(FieldName.RiskAnalysis);
    fieldsToShow.push(FieldName.FiveQuestions);
  }

  if (fieldNames.includes(FieldName.ExecuteveSummaryGoToMarketStrategy)) {
    fieldsToShow.push(FieldName.ExecuteveSummaryGoToMarketStrategy);
  }

  if (fieldNames.includes(FieldName.ExecuteveSummaryBusinessModel)) {
    fieldsToShow.push(FieldName.ExecuteveSummaryBusinessModel);
  }

  if (fieldNames.includes(FieldName.ProductDescription)) {
    fieldsToShow.push(FieldName.ExecuteveSummaryProduct);
    fieldsToShow.push(FieldName.FutureRegulations);
    fieldsToShow.push(FieldName.CurrentRegulations);
    fieldsToShow.push(FieldName.MarketOppMarketSize);
    fieldsToShow.push(FieldName.ExecuteveSummaryProblem);
    fieldsToShow.push(FieldName.RiskAnalysis);
    fieldsToShow.push(FieldName.ExecuteveSummaryElevatorPitch);
    fieldsToShow.push(FieldName.FiveQuestions);
  }

  if (fieldNames.includes(FieldName.ExecuteveSummaryProduct)) {
    fieldsToShow.push(FieldName.ExecuteveSummaryProduct);
  }
  if (fieldNames.includes(FieldName.ExecuteveSummaryProblem)) {
    fieldsToShow.push(FieldName.ExecuteveSummaryProblem);
  }

  if (fieldNames.includes(FieldName.CurrentRegulations)) {
    fieldsToShow.push(FieldName.CurrentRegulations);
    fieldsToShow.push(FieldName.FutureRegulations);
  }

  if (fieldNames.includes(FieldName.MarketOppMarketSize) && isInitialRender) {
    fieldsToShow.push(FieldName.MarketOppMarketSize);
    fieldsToShow.push(FieldName.ExecuteveSummaryMarketOpportunity);
    fieldsToShow.push(FieldName.FiveQuestions);
    fieldsToShow.push(FieldName.RiskAnalysis);
  }

  if (fieldNames.includes(FieldName.MarketOppMarketSize)) {
    fieldsToShow.push(FieldName.MarketOppMarketSize);
    fieldsToShow.push(FieldName.ExecuteveSummaryMarketOpportunity);
  }

  return fieldsToShow;
};
