import { FC } from "react";
import {
  AiaAnalysisInvestment,
  AiaAnalysisResult,
  FieldName,
} from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  analysisResults: AiaAnalysisResult;
  analysis: AiaAnalysisInvestment;
}

const EditableRiskAnalysis = withContentEditable<ValueProps>(
  Value,
  FieldName.RiskAnalysis
);

const EditableOpportunityAnalysis = withContentEditable<ValueProps>(
  Value,
  FieldName.OpportunityAnalysis
);

const EditableFiveQuestions = withContentEditable<ValueProps>(
  Value,
  FieldName.FiveQuestions
);

const RiskOpportunityAnalysis: FC<Props> = ({ analysisResults, analysis }) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.RiskAnalysis}
          title={"Potential risks"}
          currentContext={analysis?.riskAnalysis ?? ""}
          newContext={analysisResults?.investment?.riskAnalysis ?? ""}
          editableField={(content) => (
            <EditableRiskAnalysis title={"Potential risks"} content={content} />
          )}
        />
      ),
      condition: !!analysisResults?.investment?.riskAnalysis,
      label: "Potential risks",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.OpportunityAnalysis}
          title={"Potential opportunities"}
          currentContext={analysis?.opportunityAnalysis ?? ""}
          newContext={analysisResults?.investment?.opportunityAnalysis ?? ""}
          editableField={(content) => (
            <EditableOpportunityAnalysis
              title={"Potential opportunities"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.investment?.opportunityAnalysis,
      label: "Potential opportunities",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.FiveQuestions}
          title={"5 questions to ask the founders"}
          currentContext={analysis?.questionsFromFounders ?? ""}
          newContext={analysisResults?.investment?.questionsFromFounders ?? ""}
          editableField={(content) => (
            <EditableFiveQuestions
              title={"5 questions to ask the founders"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.investment?.questionsFromFounders,
      label: "5 questions to ask the founders",
    },
  ];

  return (
    <EditContextWrapper
      header="Risk / Opportunity Analysis"
      components={config}
    />
  );
};

export default RiskOpportunityAnalysis;
