import { FC } from "react";
import { AiaAnalysisResult, AiaAnalysisTeam, FieldName } from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  analysisResults: AiaAnalysisResult;
  analysis: AiaAnalysisTeam;
}

const EditableSharedExperiences = withContentEditable<ValueProps>(
  Value,
  FieldName.TeamSharedWorkExperiences
);

const EditableSharedSchoolExperience = withContentEditable<ValueProps>(
  Value,
  FieldName.TeamSharedSchoolExperiences
);

const EditableSameIndustryExp = withContentEditable<ValueProps>(
  Value,
  FieldName.TeamIndustryExperience
);

const EditableCriticalCompetences = withContentEditable<ValueProps>(
  Value,
  FieldName.TeamCriticalCompetences
);

const TeamInsightsAddedContext: FC<Props> = ({ analysisResults, analysis }) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.TeamSharedWorkExperiences}
          title={"Shared Work Experiences"}
          currentContext={analysis?.coExperiences ?? ""}
          newContext={analysisResults?.team?.coExperiences ?? ""}
          editableField={(content) => (
            <EditableSharedExperiences
              title={"Shared Work Experiences"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.team?.coExperiences,
      label: "Shared Work Experiences",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.TeamSharedSchoolExperiences}
          title={"Shared University Studies"}
          currentContext={analysis?.previouslyStudiedTogether ?? ""}
          newContext={analysisResults?.team?.previouslyStudiedTogether ?? ""}
          editableField={(content) => (
            <EditableSharedSchoolExperience
              title={"Shared University Studies"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.team?.previouslyStudiedTogether,
      label: "Shared University Studies",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.TeamIndustryExperience}
          title={"Industry Experience"}
          currentContext={analysis?.sameIndustryExperience ?? ""}
          newContext={analysisResults?.team?.sameIndustryExperience ?? ""}
          editableField={(content) => (
            <EditableSameIndustryExp
              title={"Industry Experience"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.team?.sameIndustryExperience,
      label: "Industry Experience",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.TeamCriticalCompetences}
          title={"Team assessment on key competences"}
          currentContext={analysis?.criticalCompetences ?? ""}
          newContext={analysisResults?.team?.criticalCompetences ?? ""}
          editableField={(content) => (
            <EditableCriticalCompetences
              title={"Team assessment on key competences"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.team?.criticalCompetences,
      label: "Team assessment on key competences",
    },
  ];

  return <EditContextWrapper header="Team Insights" components={config} />;
};

export default TeamInsightsAddedContext;
