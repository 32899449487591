import { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Page } from "ui/Page";

/* component imports */
import FormerInvestors from "./components/shared/About/FormerInvestors";
import ProductDescription from "./components/shared/About/ProductDescription";
import InvestmentSummaryMobile from "./components/shared/InvestmentSummaryMobile";
import KeyFigures from "./components/shared/KeyFigures/KeyFigures";
import BusinessModel from "./components/shared/BusinessModel/BusinessModel";
import SDGs from "./components/shared/SDGs/SDGs";
import ViewIdentification from "organisms/ViewIdentification/ViewIdentification";
import Hero_Regular from "./components/regular/Hero_Regular";
import MarketOpportunity_Regular from "./components/regular/MarketOpportunity_Regular";
import FundraisingOverview_Regular from "./components/regular/FundraisingOverView_Regular";
import Team_Regular from "./components/regular/Team_Regular";
import ExecutiveSummary_Regular from "./components/regular/ExecutiveSummary_Regular";
import useMySpace from "pages/mySpace/Components/hooks/useMySpace";
import CohortApplicationModal from "pages/mySpace/Components/CohortApplicationModal/CohortApplicationModal";

interface Props {
  data: any;
  traction: any;
  analysis: any;
  currencyCode: any;
  isPublic?: boolean;
}

const CompanyProfile_Regular: FC<Props> = ({
  data,
  traction,
  analysis,
  currencyCode,
  // Attention: isPublic is determined by existence of userData NOT the route it is rendered on
  // It means that if user is logged in, isPublic is false even if it is rendered on /public route
  isPublic,
}) => {
  const [isIdentitySent, setIsIdentitySent] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // We only need this here for the cohort modal to work. Must be removed when cohort is retired
  const { isShowModal, handleCloseModal } = useMySpace();

  const handleIdentification = (isIdentified: boolean) => {
    if (isIdentified) {
      setIsIdentitySent(true);
    } else {
      navigate("/");
    }
  };

  const marketTimingSection =
    data?.companyBySlug?.marketOpp ||
    data?.companyBySlug?.businessModelDescription ||
    data?.companyBySlug?.pricing ||
    data?.companyBySlug?.businessTiming ||
    data?.sustainableDevelopmentGoals?.items?.length > 0 ||
    analysis?.marketOpp.marketOpp;

  const isFromBoard = searchParams.get("us") === "board";

  const isShowIdentification =
    data?.companyBySlug?.isIdentityRequired && !isIdentitySent && !isFromBoard;

  return (
    <div className="bg-white dark:bg-neutral-900 md:w-full lg:mb-0 md:pb-36">
      <Page>
        <Hero_Regular
          company={data?.companyBySlug}
          currencyCode={currencyCode}
        />

        <FundraisingOverview_Regular
          finance={data?.companyBySlug?.finance}
          valuation={data?.companyBySlug?.valuation}
          raisedTarget={data?.companyBySlug?.finance?.raisedTarget}
          raisedValue={data?.companyBySlug?.finance?.raisedValue}
          minTicketSize={data?.companyBySlug?.finance?.minTicketSize}
          currencyCode={currencyCode}
          fit={data?.companyBySlug?.fit}
          fundraisingCloseOn={data?.companyBySlug?.finance?.fundraisingCloseOn}
          committed={data?.companyBySlug?.finance?.committed}
        />

        {/* Mobile */}
        <InvestmentSummaryMobile
          businessCategory={data?.companyBySlug?.businessCategory}
          businessTypeNote={data?.companyBySlug?.businessTypeNote}
          businessModel={data?.companyBySlug?.businessModel}
          businessCategoryNote={data?.companyBySlug?.businessCategoryNote}
          founded={data?.companyBySlug?.founded}
          companyStage={data?.companyBySlug?.companyStage}
          teamSize={data?.companyBySlug?.teamSize}
        />

        <ExecutiveSummary_Regular
          pitch={data?.companyBySlug?.pitch}
          tractionSummary={data?.companyBySlug?.traction?.summary}
        />

        <Team_Regular
          founders={data?.companyBySlug?.founders}
          members={data?.companyBySlug?.teamMembers}
          teamNote={data?.companyBySlug?.teamNote}
        />

        <FormerInvestors
          priorInvestments={data?.companyBySlug?.investors}
          investorNote={data?.companyBySlug?.investorNote}
        />

        <KeyFigures
          traction={traction}
          companyStage={data?.companyBySlug?.companyStage}
          currencyCode={currencyCode}
        />

        <ProductDescription
          customers={data?.companyBySlug?.customers}
          valueProposition={data?.companyBySlug?.valueProposition}
          description={data?.companyBySlug?.description}
          productDemo={data?.companyBySlug?.productDemo}
          productImages={data?.companyBySlug?.productImages}
        />

        {marketTimingSection && (
          <div className="sm:bg-gradient-to-r from-white bg-gray-50 sm:bg-white sm:from-neutral-100 dark:bg-neutral-900 dark:sm:from-neutral-900 dark:sm:to-neutral-800 p-4 my-2">
            <MarketOpportunity_Regular
              marketOpp={data?.companyBySlug?.marketOpp}
            />
            <BusinessModel
              businessModelDescription={
                data?.companyBySlug?.businessModelDescription
              }
              pricing={data?.companyBySlug?.pricing}
              isLoading={false}
            />
            {/* Hiding timing section temporarily */}
            {/* <Timing
                businessTiming={data?.companyBySlug?.businessTiming}
                isLoading={!profileLoadingStatus?.timing}
              /> */}
            <SDGs
              sustainableDevelopmentGoals={
                data?.companyBySlug?.sustainableDevelopmentGoals
              }
              isLoading={false}
              isPublic={isPublic}
            />
          </div>
        )}

        {isPublic && isShowIdentification && (
          <ViewIdentification
            onFinish={handleIdentification}
            startupSlug={data?.companyBySlug?.slug}
          >
            <p>
              The owner of "{data?.companyBySlug?.displayName}" has restricted
              access to their profile.
            </p>

            <p>Please introduce yourself in order to view the page.</p>
          </ViewIdentification>
        )}
        {isShowModal && (
          <CohortApplicationModal handleClose={handleCloseModal} />
        )}
      </Page>
    </div>
  );
};

export default CompanyProfile_Regular;
