import { FC, ReactElement } from "react";

/**
 * INFO:
 * - `hideLabel` and `fullWidth` are mandatory props for both new and current sections.
 * - `editable` is an optional prop specific to the current section.
 * - The Render Props pattern is utilized to reduce duplication and enhance flexibility.
 */

interface NewSectionOtherProps {
  hideLabel: boolean;
  fullWidth: boolean;
}

interface CurrentSectionOtherProps extends NewSectionOtherProps {
  editable?: boolean;
}

interface ComparisonFieldProps {
  fieldName: string;
  renderNewSection: (otherProps: NewSectionOtherProps) => ReactElement;
  renderCurrentSection: (otherProps: CurrentSectionOtherProps) => ReactElement;
}

const ComparisonField: FC<ComparisonFieldProps> = ({
  fieldName,
  renderNewSection,
  renderCurrentSection,
}) => {
  return (
    <div className="grid grid-cols-12 gap-x-4 gap-y-4">
      <div className="col-span-2 border-b py-3 pl-4 body-xs flex items-center">
        {fieldName}
      </div>

      <div className="col-span-5 border-b py-3 pl-4">
        {renderNewSection({ hideLabel: true, fullWidth: true })}
      </div>

      <div className="col-span-5 border-b py-3 pl-4">
        {renderCurrentSection({
          hideLabel: true,
          fullWidth: true,
          editable: false,
        })}
      </div>
    </div>
  );
};

export default ComparisonField;
