import { FC } from "react";
import {
  AiaAnalysisInvestment,
  AiaAnalysisLegislation,
  AiaAnalysisResult,
  FieldName,
} from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  analysisResults: AiaAnalysisResult;
  analysis: AiaAnalysisLegislation;
}

const EditableCurrentRegulations = withContentEditable<ValueProps>(
  Value,
  FieldName.CurrentRegulations
);

const EditableFutureRegulations = withContentEditable<ValueProps>(
  Value,
  FieldName.FutureRegulations
);

const RegulationsAddedContext: FC<Props> = ({ analysisResults, analysis }) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.CurrentRegulations}
          title={"Current Regulations"}
          currentContext={analysis?.todaysLegislation ?? ""}
          newContext={analysisResults?.legislation?.todaysLegislation ?? ""}
          editableField={(content) => (
            <EditableCurrentRegulations
              title={"Current Regulations"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.legislation?.todaysLegislation,
      label: "Current Regulations",
    },
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.OpportunityAnalysis}
          title={"Future Regulations"}
          currentContext={analysis?.futureLegislation ?? ""}
          newContext={analysisResults?.legislation?.futureLegislation ?? ""}
          editableField={(content) => (
            <EditableFutureRegulations
              title={"Future Regulations"}
              content={content}
            />
          )}
        />
      ),
      condition: !!analysisResults?.legislation?.futureLegislation,
      label: "Future Regulations",
    },
  ];

  return <EditContextWrapper header="Regulations" components={config} />;
};

export default RegulationsAddedContext;
