import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import {
  UpdateAiaProfileAddMemberMutation,
  UpdateAiaProfileAddMemberMutationVariables,
  MemberType,
  FieldName,
} from "gql/graphql";
import WYSIWYGEditor from "pages/applications/WYSIWYGEditor";
import { refetchCompanyData } from "pages/companyProfile/sagaActions";
import { UPDATE_AIA_PROFILE_ADD_MEMBER } from "queries/editAiaProfile/editAiaProfile";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";

interface Props {
  companyId: string;
  type: MemberType;
  isEditMode: boolean;
  setUpdatedFields?: (fields: FieldName[]) => void;
  updatedFields?: FieldName[];
}

const AddNewMember: FC<Props> = ({
  companyId,
  type,
  updatedFields,
  setUpdatedFields,
}) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [education, setEducation] = useState("");
  const [experience, setExperience] = useState("");
  const [title, setTitle] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  const [addTeamMember, { loading }] = useMutation<
    UpdateAiaProfileAddMemberMutation,
    UpdateAiaProfileAddMemberMutationVariables
  >(UPDATE_AIA_PROFILE_ADD_MEMBER, {
    onCompleted() {
      dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
      setIsEditMode(false);
      setUpdatedFields &&
        setUpdatedFields([
          ...(updatedFields?.filter(
            (field) =>
              field !==
              (type === MemberType?.Member
                ? FieldName.Team
                : FieldName.Founders)
          ) ?? []),
          type === MemberType?.Member ? FieldName.Team : FieldName.Founders,
        ]);
    },
  });

  const handleAddMember = () => {
    addTeamMember({
      variables: {
        companyId: companyId,
        type: type,
        title: title,
        name: name,
        educationHtml: education,
        experienceHtml: experience,
      },
    });
  };

  const handleEducationChange = (e: any) => {
    const str = e.replaceAll("\n", "");
    setEducation(str);
  };

  const handleExperienceChange = (e: any) => {
    const str = e.replaceAll("\n", "");
    setExperience(str);
  };
  return (
    <>
      {!isEditMode && (
        <div
          className="flex flex-col items-center min-h-[250px] justify-center p-4 border-2 bg-gray-100 border-dashed border-gray-400 rounded-md cursor-pointer"
          onClick={() => setIsEditMode(true)}
        >
          <FontAwesomeIcon
            icon={["fal", "plus"]}
            className="text-4xl text-gray-600"
          />
        </div>
      )}
      {isEditMode && (
        <div className="flex flex-col px-2 w-fit">
          <div className="flex flex-row gap-2">
            <div>
              <h6 className="text-sm font-bold mt-2 !text-[#24272B]">Name:</h6>
              <input
                className="px-2 py-1 border border-gray-400 rounded-md"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <h6 className="text-sm font-bold mt-2 !text-[#24272B]">Title:</h6>
              <input
                className="px-2 py-1 border border-gray-400 rounded-md"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div>
            <h6 className="text-sm font-bold mt-2 !text-[#24272B]">
              Education:
            </h6>

            <WYSIWYGEditor value={education} onChange={handleEducationChange} />
          </div>
          <div>
            <h6 className="text-sm font-bold mt-2 !text-[#24272B]">
              Experience:
            </h6>
            <WYSIWYGEditor
              value={experience}
              onChange={handleExperienceChange}
            />
          </div>
          <div className="mt-4 space-x-4">
            <Button rectangular color="primary" onClick={handleAddMember}>
              Add Member
            </Button>
            <Button
              rectangular
              color="primary"
              onClick={() => setIsEditMode(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewMember;
