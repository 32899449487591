import { gql } from "@apollo/client";

export const UPDATE_AIA_PROFILE_RICH_TEXT = gql`
  mutation updateAiaProfileRichText(
    $companyId: String!
    $fieldName: FieldName!
    $updatedValue: String!
    $competitorId: String
  ) {
    updateAiaProfile {
      updateRichTextData(
        companyId: $companyId
        fieldName: $fieldName
        updatedValue: $updatedValue
        competitorId: $competitorId
      )
    }
  }
`;

export const UPDATE_AIA_PROFILE_DELETE_MEMBER = gql`
  mutation updateAiaProfileDeleteMember(
    $companyId: String!
    $memberId: String!
  ) {
    updateAiaProfile {
      deleteTeamMember(companyId: $companyId, memberId: $memberId)
    }
  }
`;

export const UPDATE_AIA_PROFILE_ADD_MEMBER = gql`
  mutation updateAiaProfileAddMember(
    $companyId: String!
    $id: String
    $type: MemberType!
    $name: String!
    $title: String
    $educationHtml: String
    $experienceHtml: String
  ) {
    updateAiaProfile {
      addTeamMember(
        companyId: $companyId
        id: $id
        type: $type
        name: $name
        title: $title
        educationHtml: $educationHtml
        experienceHtml: $experienceHtml
      )
    }
  }
`;

export const UPDATE_AIA_PROFILE_ADD_COMPETITOR = gql`
  mutation updateAiaProfileAddCompetitor(
    $companyId: String!
    $name: String!
    $similarities: String
    $differences: String
    $id: String
    $pitch: String
  ) {
    updateAiaProfile {
      addCompetitor(
        companyId: $companyId
        name: $name
        similarities: $similarities
        differences: $differences
        id: $id
        pitch: $pitch
      )
    }
  }
`;

export const UPDATE_AIA_PROFILE_UPDATE_MEMBER_UI = gql`
  mutation updateAiaProfileUpdateMemberUi(
    $companyId: String!
    $memberId: String!
    $type: MemberType!
    $educationHtml: String
    $experienceHtml: String
  ) {
    updateAiaProfile {
      updateTeamMemberUiRepresentation(
        companyId: $companyId
        memberId: $memberId
        type: $type
        educationHtml: $educationHtml
        experienceHtml: $experienceHtml
      )
    }
  }
`;

export const GET_TEAM_MEMBER_UI_REPRESENTATION = gql`
  query getTeamMemberUiRepresentation(
    $companyId: String!
    $memberId: String!
    $type: MemberType!
  ) {
    getTeamMemberUiRepresentation(
      companyId: $companyId
      memberId: $memberId
      type: $type
    ) {
      id
      experienceHtml
      educationHtml
    }
  }
`;

// This query is to fetch the entire ui object, not a specific field
export const GET_UI_REPRESENTATION = gql`
  query getUiRepresentation($companyId: String!) {
    getUiRepresentation(companyId: $companyId) {
      id
      startupId
      generalInfo {
        industry
        headOffice
        product
        founded
        businessRelation
        teamSize
        startupStage
        foundersGender
        socials {
          linkedin
          twitter
          facebook
          instagram
        }
      }
    }
  }
`;

export const UPDATE_AIA_PROFILE_GENERAL_INFO = gql`
  mutation updateAiaProfileGeneralInfo(
    $companyId: String!
    $fieldName: FieldName!
    $updatedValue: String!
  ) {
    updateAiaProfile {
      updateGeneralInfo(
        companyId: $companyId
        fieldName: $fieldName
        updatedValue: $updatedValue
      )
    }
  }
`;

export const GET_NUMBER_OF_UPLOADS = gql`
  query getNumberOfUploads {
    me {
      company {
        aiaTrial {
          totalUploads
        }
      }
    }
  }
`;
