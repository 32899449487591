export const getExtensionFromFileName = (fileName?: string): string => {
  if (!fileName) return "";

  const splitFileName = fileName.split(".");
  if (splitFileName.length < 2) return "";

  // INFO: Here, returning splitFileName.pop() instead of splitFileName[1] because some file names can have multiple dots.
  return splitFileName.pop() as string;
};

/**
 * INFO: This function uses binary prefixes (1 KB = 1024 bytes).
 * Some systems (like macOS) use decimal prefixes (1 KB = 1000 bytes).
 * Hence, the file size shown on the computer and the size returned by this function might vary.
 */
export const formatFileSize = (fileSize?: number): string => {
  // Divisors for KB, MB, and GB
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (!fileSize || fileSize < 0 || isNaN(fileSize)) return "N/A";

  // Format size with one decimal place if there are any decimal values, otherwise show the value as an integer
  const formatSize = (size: number, divisor: number, unit: string): string => {
    const result = size / divisor;
    const formattedResult =
      result % 1 === 0 ? result : parseFloat(result.toFixed(1));

    return `${formattedResult} ${unit}`;
  };

  if (fileSize < KB) return `${fileSize} b`;
  if (fileSize < MB) return `${Math.floor(fileSize / KB)} kb`;
  if (fileSize < GB) return formatSize(fileSize, MB, "mb");

  return formatSize(fileSize, GB, "gb");
};

export const getFileSizeInMB = (fileSize: number): number => {
  if (!fileSize || fileSize < 0 || isNaN(fileSize)) return 0;

  return fileSize / (1024 * 1024);
};
