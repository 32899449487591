import { FC } from "react";
import withValueEditable from "organisms/ValueEditable/ValueEditable";
import { FieldName } from "gql/graphql";
import { Value, ValueProps } from "./Value";

interface Props {
  city?: string;
  fullAddress?: string;
  uiRepresentation?: string | null | undefined;
  hideLabel?: boolean;
  fullWidth?: boolean;
  editable?: boolean;
}

const EditableValue = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoHeadOffice
);

const HeadOffice: FC<Props> = ({
  city,
  fullAddress,
  uiRepresentation,
  hideLabel,
  fullWidth,
  editable = true,
}) => {
  const getContent = () => {
    if (uiRepresentation) return uiRepresentation;
    if (city) return city;
    if (fullAddress) return fullAddress;
    return "";
  };

  const containerStyles = fullWidth
    ? ""
    : "grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80 industry-div";

  return (
    <div className={containerStyles}>
      {!hideLabel && <h6 className="font-medium">Head Office</h6>}

      {editable ? (
        <EditableValue content={getContent()} />
      ) : (
        <Value content={getContent()} />
      )}
    </div>
  );
};

export default HeadOffice;
