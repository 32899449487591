// @ts-nocheck
import React, { forwardRef, useState, useEffect } from "react";

// TODO: Use this interface and replace any.
interface Props {
  onChange: () => void;
  defaultValue: string;
  maxLength?: number;
}

// @TODO: use real type instead of any
//
export default forwardRef(
  ({ onChange, maxLength, defaultValue, ...props }: any, ref) => {
    const [inputLength, setInputLength] = useState(defaultValue?.length || 0);

    useEffect(() => {
      setInputLength(defaultValue?.length || 0);
      props.setformvalue && props.setformvalue(props.name, defaultValue);
    }, [defaultValue]);

    return (
      <div>
        <textarea
          style={{ resize: "none" }}
          onKeyUp={(e) => setInputLength(e.target.value.length)}
          ref={ref}
          onChange={(e) => {
            const maxLengthIsReached = e.target.value.length >= maxLength;
            if (maxLengthIsReached) {
              e.target.value = e.target.value.slice(0, maxLength);
              setInputLength(e.target.value.length);
              return;
            }

            setInputLength(e.target.value.length);

            if (typeof onChange === "function") {
              onChange(e);
            }
          }}
          defaultValue={defaultValue}
          {...props}
        ></textarea>

        {maxLength && (
          <div className="w-full mt-2 text-right text-gray-700 sm:text-sm sm:leading-6">
            {`${inputLength}/${maxLength}`}
          </div>
        )}
      </div>
    );
  }
);
