import { useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FieldName,
  SynthesizeFieldQuery,
  SynthesizeFieldQueryVariables,
} from "gql/graphql";
import { SYNTHESIZE_FIELD } from "queries/aia/addContext";
import { FC, useEffect, useState } from "react";
import Button from "atoms/Button/Button";
import PureHtml from "atoms/PureHtml";
import WYSIWYGEditor from "pages/applications/WYSIWYGEditor";

interface FieldWrapperProps {
  fieldName: FieldName;
  currentContext: string;
  title: string;
  newContext: string;
  hidePrimaryButton?: boolean;
  updatedFields?: FieldName[];
  setUpdatedFields?: (fieldName: FieldName[]) => void;
  editableField?: (
    content: string,
    updatedField: FieldName[],
    setUpdatedFields: (fieldName: FieldName[]) => void,
    title?: string
  ) => JSX.Element;
}

export interface ValueProps {
  content: string;
  title?: string;
  setUpdatedFields?: (fields: FieldName[]) => void;
  updatedFields?: FieldName[];
}

export const Value: FC<ValueProps> = ({ content, title }) => {
  return (
    <>
      {title && <span className="title-h6 capitalize">{title}</span>}

      <div className="body-xs mt-4 rich-text-content-wrapper">
        <PureHtml content={content ?? ""} tag="p" />
      </div>
    </>
  );
};

export const FieldWrapper: FC<FieldWrapperProps> = ({
  fieldName,
  currentContext,
  title,
  newContext,
  hidePrimaryButton,
  updatedFields,
  setUpdatedFields,
  editableField,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(newContext);

  const [synthesize, { data: synthesizedData, loading: isLoading }] =
    useLazyQuery<SynthesizeFieldQuery, SynthesizeFieldQueryVariables>(
      SYNTHESIZE_FIELD,
      {
        fetchPolicy: "no-cache",
      }
    );

  const handleSynthesis = (
    newContext: string,
    currentContent: string,
    fieldName: string
  ) => {
    synthesize({
      variables: {
        newContext,
        currentContent,
        fieldName,
      },
    });
  };

  useEffect(() => {
    if (updatedValue) {
      setUpdatedValue(updatedValue);
    }
  }, [updatedValue]);

  const handleWysiwygChange = (e: any) => {
    const str = e.replaceAll("\n", "");
    setUpdatedValue(str);
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        {fieldName === synthesizedData?.aia?.synthesizeField?.fieldName &&
          synthesizedData?.aia?.synthesizeField?.synthesis && (
            <div className="basis-1/2 bg-aqua-100 px-6 text-green-800 p-4 w-full rounded-md mb-2">
              {fieldName === synthesizedData?.aia?.synthesizeField?.fieldName &&
                synthesizedData?.aia?.synthesizeField?.synthesis &&
                editableField &&
                editableField(
                  synthesizedData?.aia?.synthesizeField?.synthesis ?? "",
                  updatedFields ?? [],
                  setUpdatedFields ?? (() => {})
                )}
            </div>
          )}
        <div className="flex flex-row gap-6">
          <div className="basis-1/2 bg-aqua-100 px-6 text-[#303340] p-4 w-full rounded-md mb-6">
            <div className="relative w-full">
              {newContext && !isEditMode ? (
                <Value
                  content={updatedValue ?? newContext}
                  title={`New ${title}`}
                />
              ) : (
                <>
                  <span className="title-h6">{`New ${title}`}</span>
                  {isEditMode ? (
                    <>
                      <div className="mt-4">
                        <WYSIWYGEditor
                          value={updatedValue ?? newContext}
                          onChange={handleWysiwygChange}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}
              <button
                className="w-fit top-0 right-0 absolute"
                onClick={() => setIsEditMode((prev) => !prev)}
              >
                <FontAwesomeIcon icon={["fal", "pencil"]} className="ml-2" />
              </button>
            </div>
          </div>

          <div className="basis-1/2 p-4 bg-gray-100 rounded-md mb-6 px-6">
            {editableField &&
              editableField(
                currentContext,
                updatedFields ?? [],
                setUpdatedFields ?? (() => {})
              )}
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        {!hidePrimaryButton && (
          <Button
            color="primary"
            className="w-fit"
            rectangular
            onClick={() =>
              handleSynthesis(
                updatedValue ?? newContext,
                currentContext ?? "",
                fieldName
              )
            }
            loading={isLoading}
            disabled={isLoading || !newContext}
          >
            <span className="whitespace-nowrap">Synthesize {title}</span>
            <FontAwesomeIcon icon={["fal", "retweet"]} className="ml-2" />
          </Button>
        )}
      </div>
    </>
  );
};
