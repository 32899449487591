import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  refetchCompanyData,
  refetchUiRepresentation,
} from "pages/companyProfile/sagaActions";
import Loading from "atoms/Loading/Loading";
import { FieldName } from "gql/graphql";
import { UPDATE_AIA_PROFILE_GENERAL_INFO } from "queries/editAiaProfile/editAiaProfile";
import { mixpanelEditAiaProfile } from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";
import {
  getCompanyDetails,
  getIsPublicProfile,
} from "pages/companyProfile/selectors";
import SaveAndCancelIcons from "./SaveAndCancelIcons";
import { testUrl } from "./utils";

// HOC injected props
export interface HOCInjectedProps {}

// Necessary props, all components must send down
interface BaseProps {
  content: string;
}

// Arbitrary props, some components need to send extra props
interface Props extends BaseProps {
  competitorId?: string;
}

/**
 * This component is very similar to ContentEditable with following differences:
 * - It renders an input text instead of a rich text editor
 * - It renders check and cross icons instead of save and cancel buttons
 * _ It calls another mutation for simplicity
 */
export default function withValueEditable<T extends Props>(
  WrappedComponent: React.ComponentType<T & HOCInjectedProps>,
  fieldName: FieldName
) {
  const WithValueEditable = (props: T) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [updatedValue, setUpdatedValue] = useState("");
    const [isInvalidUrl, setIsInvalidUrl] = useState(false);

    const user = useSelector(getUser);
    const companyData = useSelector(getCompanyDetails);
    const isPublicProfile = useSelector(getIsPublicProfile);

    const companyId = companyData?.companyBySlug?.id;

    const dispatch = useDispatch();

    useEffect(() => {
      setUpdatedValue(props.content);
    }, [props.content]);

    const [updateAiaProfile, { loading }] = useMutation(
      UPDATE_AIA_PROFILE_GENERAL_INFO,
      {
        onCompleted() {
          if (fieldName === FieldName.GeneralInfoWebsite) {
            dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
          } else {
            dispatch(refetchUiRepresentation({ id: companyId }));
          }
        },
      }
    );

    const handleSave = () => {
      if (
        fieldName === FieldName.GeneralInfoSocialsLinkedin ||
        fieldName === FieldName.GeneralInfoSocialsTwitter ||
        fieldName === FieldName.GeneralInfoSocialsFacebook ||
        fieldName === FieldName.GeneralInfoSocialsInstagram
      ) {
        const urlWithProtocol = updatedValue.startsWith("http")
          ? updatedValue
          : `https://${updatedValue}`;

        const testedUrl = testUrl(urlWithProtocol);
        setIsInvalidUrl(!testedUrl);
        if (!testedUrl) return;
      }
      setIsEditMode(false);
      updateAiaProfile({
        variables: {
          companyId,
          fieldName,
          updatedValue,
        },
      });
      mixpanelEditAiaProfile(user, companyId, fieldName);
      setIsInvalidUrl(false);
    };

    const handleCancel = () => {
      setIsEditMode(false);
      setUpdatedValue(props.content);
      setIsInvalidUrl(false);
    };

    const handleInputChange = (e: any) => {
      const str = e.target.value;
      setUpdatedValue(str);
    };

    const isTainted = props.content !== updatedValue;

    if (loading) return <Loading size="small" />;

    if (isPublicProfile || !companyData?.companyBySlug?.amITeamMember) {
      return <WrappedComponent {...props} />;
    }

    return (
      <div className="relative group">
        {isEditMode && (
          <div>
            {/* // TODO: For this part, a Figma design is required */}
            <div className="flex justify-between items-center gap-x-2">
              <input
                type="text"
                className="bg-gray-100 -ml-3 px-2 py-1 grow rounded-sm"
                value={updatedValue}
                onChange={handleInputChange}
              />

              <SaveAndCancelIcons
                onSave={handleSave}
                onCancel={handleCancel}
                saveButtonDisabled={!isTainted}
                className="flex flex-row"
              />
            </div>

            {isInvalidUrl && <p className="text-red-400">Invalid url</p>}
          </div>
        )}

        {!isEditMode && (
          // INFO: mr-6 is required to set a gap between edit icon and content since icon has been set to right-0
          <div className="mr-6">
            <FontAwesomeIcon
              icon={["fal", "pen"]}
              className="absolute cursor-pointer right-0 top-1"
              size="xs"
              onClick={() => setIsEditMode(true)}
            />

            <WrappedComponent {...props} />
          </div>
        )}
      </div>
    );
  };

  return WithValueEditable;
}
