import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Sdg, { sdgIcons } from "molecules/Sdg/Sdg";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import { ReactComponent as CloseIcon } from "images/close-icon-bg.svg";
import { refetchCompanyData } from "pages/companyProfile/sagaActions";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import { useMutation } from "@apollo/client";
import { FieldName } from "gql/graphql";
import { UPDATE_AIA_PROFILE_GENERAL_INFO } from "queries/editAiaProfile/editAiaProfile";
import { cn } from "utils/cn";
import Button from "atoms/Button/Button";

interface Props {
  sustainableDevelopmentGoals: {
    items: any[];
  };
  isLoading?: boolean;
  isPublic?: boolean;
  amITeamMember?: boolean;
  title?: boolean;
  companyId?: string;
}

const SDGs: FC<Props> = ({
  sustainableDevelopmentGoals,
  isLoading,
  isPublic,
  amITeamMember,
  title = true,
  companyId,
}) => {
  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSDGs, setSelectedSDGs] = useState<number[]>([]);

  const hasSelectedSdgs = selectedSDGs.length > 0;
  const hasSelectedAllSdgs = sdgIcons.length === selectedSDGs.length;

  const handleAddSdg = (index: number) => {
    setSelectedSDGs((prev) => uniq([...prev, index + 1]));
  };

  const handleRemoveSdg = (index: number) => {
    setSelectedSDGs((prev) => prev.filter((s) => s !== index));
  };

  const dispatch = useDispatch();

  const [updateAiaProfile, { loading }] = useMutation(
    UPDATE_AIA_PROFILE_GENERAL_INFO,
    {
      onCompleted() {
        dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
      },
    }
  );

  useEffect(() => {
    if (sustainableDevelopmentGoals?.items) {
      setSelectedSDGs(sustainableDevelopmentGoals.items);
    }
  }, [sustainableDevelopmentGoals?.items]);

  if (isLoading)
    return (
      <div className="my-4">
        <SectionHeader title="SDG Goals" />
        <Skeleton count={3.6} />
      </div>
    );

  // TODO: To be implemented
  const onSave = () => {
    const updatedValue = selectedSDGs.join(",");
    updateAiaProfile({
      variables: {
        companyId,
        fieldName: FieldName.GeneralInfoSdgs,
        updatedValue,
      },
    });
    setIsEditMode(false);
  };

  // TODO: To be implemented
  const onCancel = () => {
    setIsEditMode(false);
    if (sustainableDevelopmentGoals?.items) {
      setSelectedSDGs(sustainableDevelopmentGoals.items);
    }
  };

  const handleClearSelectedSdgs = (): void => {
    setSelectedSDGs([]);
  };

  return (
    // INFO: gap-4 to set a space between selected SDGs and all SDGs
    <div className={cn("flex flex-col", hasSelectedSdgs && "gap-4")}>
      {title && <SectionHeader title="SDG Goals" />}

      {selectedSDGs ? (
        <div
          className={cn(
            "border-opacity-20 px-3 rounded-lg",
            hasSelectedSdgs && "border border-[#0C7F6A] py-3"
          )}
        >
          <div className="flex flex-col gap-4">
            {hasSelectedSdgs && (
              <div className="flex flex-row items-center gap-1">
                <span className="body-xs">Selected SDGs</span>

                <span className="body-caption text-[#0C7F6A] bg-[#D0F5DF] px-2 py-[2px] rounded-2xl">
                  {selectedSDGs.length}
                </span>

                <Button
                  onClick={handleClearSelectedSdgs}
                  className="rounded-md h-[20px] bg-red-200 border !border-red-300 hover:opacity-80 ml-4"
                >
                  <span className="body-caption text-red-500">Clear</span>
                </Button>
              </div>
            )}

            <div className="flex flex-wrap gap-x-9 gap-y-4">
              {selectedSDGs
                ?.sort((a, b) => a - b)
                .map((key, index, item) => (
                  <div
                    key={key}
                    className="w-fit relative"
                    onClick={
                      isEditMode
                        ? () => handleRemoveSdg(item[index])
                        : undefined
                    }
                  >
                    {/* INFO: Removed the label from the selected list because some labels are too lengthy */}
                    <Sdg key={index} number={item[index]} hideLabel />

                    {isEditMode && (
                      <CloseIcon className="absolute w-4 h-4 cursor-pointer -right-5 top-0" />
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div>
            {!isEditMode && !!amITeamMember && !isPublic && (
              <div
                className="cursor-pointer space-x-2"
                onClick={() => setIsEditMode(true)}
              >
                <span className="body-small">Add SDG</span>

                <FontAwesomeIcon
                  icon={["fal", "plus-circle"]}
                  className="body-small"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>
          The company does not seem to contribute to solving any Sustainable
          Development Goals.
        </p>
      )}

      {/* INFO: If all the tags are selected, hide the div since it has no data to display */}
      {isEditMode && !hasSelectedAllSdgs && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-[#B6BBC9] border-opacity-60 rounded-xl p-3 gap-4">
          {sdgIcons.map((_sdgIcon, index) => {
            if (selectedSDGs.includes(index + 1)) return null;

            return (
              <div
                className="w-full flex flex-row items-start bg-[#F1FCF7] border border-[#0C7F6A] border-opacity-40 rounded-xl p-3"
                key={index}
                onClick={() => handleAddSdg(index)}
              >
                <div className="w-full" key={index}>
                  {/* INFO: Display the icon with the label */}
                  <Sdg key={index} number={index + 1} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SDGs;
