import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FieldName,
  MemberType,
  MemberUiRepresentation,
  UpdateAiaProfileAddMemberMutation,
  UpdateAiaProfileAddMemberMutationVariables,
} from "gql/graphql";

import { refetchCompanyData } from "pages/companyProfile/sagaActions";
import { UPDATE_AIA_PROFILE_ADD_MEMBER } from "queries/editAiaProfile/editAiaProfile";
import { FC } from "react";
import { useDispatch } from "react-redux";

interface Props {
  companyId: string;
  type: MemberType;
  member: any;
  memberUi: MemberUiRepresentation | null | undefined;
  setUpdatedFields?: (fieldName: FieldName[]) => void;
  updatedFields?: FieldName[];
}

const AddMemberAddedContext: FC<Props> = ({
  companyId,
  type,
  member,
  memberUi,
  setUpdatedFields,
  updatedFields,
}) => {
  const dispatch = useDispatch();

  const [addTeamMember, { loading }] = useMutation<
    UpdateAiaProfileAddMemberMutation,
    UpdateAiaProfileAddMemberMutationVariables
  >(UPDATE_AIA_PROFILE_ADD_MEMBER, {
    onCompleted() {
      dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
      setUpdatedFields &&
        setUpdatedFields([
          ...(updatedFields?.filter(
            (field) =>
              field !==
              (type === MemberType?.Member
                ? FieldName.Team
                : FieldName.Founders)
          ) ?? []),
          type === MemberType?.Member ? FieldName.Team : FieldName.Founders,
        ]);
    },
  });

  const handleAddMember = () => {
    addTeamMember({
      variables: {
        id: member.id,
        companyId: companyId,
        type: type,
        title: member.title,
        name: member.name,
        educationHtml: memberUi?.educationHtml,
        experienceHtml: memberUi?.experienceHtml,
      },
    });
  };

  return (
    <div
      className="z-[1000]"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        left: "45%",
        top: "40%",
        position: "absolute",
        width: "100%",
      }}
    >
      <FontAwesomeIcon
        icon={["fal", "plus"]}
        className="relative text-6xl text-gray-400 hover:text-gray-600 cursor-pointer"
        onClick={handleAddMember}
      />
    </div>
  );
};

export default AddMemberAddedContext;
