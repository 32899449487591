import { FC, useState } from "react";
import MemberCard from "../../../Team/TeamMemberCard";
import {
  AddedContextResult,
  FieldName,
  MemberType,
  Startup,
} from "gql/graphql";
import SectionHeader from "pages/companyProfile/components/shared/SectionHeader/SectionHeader";
import AddMember from "../../../Team/AddNewMember";
import AddMemberAddedContext from "./AddMemberAddedContext";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  updatedFields?: FieldName[];
  setUpdatedFields?: (fields: FieldName[]) => void;
}

const TeamMembersAddedContext: FC<Props> = ({
  company,
  newContext,
  updatedFields,
  setUpdatedFields,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const newMembers = newContext?.team?.teamMembers ?? [];

  const currentMemberIds = company.teamMembers?.map((f) => f?.id) ?? [];

  return (
    <div className="flex flex-col w-full h-full px-6">
      <div className="ml-6 mb-4 mt-4">
        <SectionHeader title="Team" />
      </div>
      <div className="overflow-y-auto px-6">
        <h2 className="mb-4">New Team Members</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mb-4">
          {newMembers
            ?.filter((member) => currentMemberIds.includes(member?.id ?? ""))
            .map((member, index) => {
              const memberUi =
                newContext?.team?.teamUiRepresentation?.teamMembers?.filter(
                  (ui) => ui?.id === member?.id ?? null
                );

              return (
                <div className="inline-flex" key={member?.id}>
                  <MemberCard
                    companyId={company.id}
                    member={member}
                    key={member?.id}
                    index={index}
                    type={MemberType.Member}
                    isPublic={false}
                    amITeamMember={true}
                    isAddedContext={true}
                    uiRepresentationAddedContext={memberUi ? memberUi[0] : null}
                  />
                  <div className="relative">
                    <AddMemberAddedContext
                      companyId={company.id}
                      type={MemberType.Member}
                      member={member}
                      memberUi={memberUi ? memberUi[0] : null}
                      updatedFields={updatedFields}
                      setUpdatedFields={setUpdatedFields}
                    />
                  </div>
                </div>
              );
            })}

          <AddMember
            companyId={company.id}
            type={MemberType.Member}
            isEditMode={isEditMode}
            updatedFields={updatedFields}
            setUpdatedFields={setUpdatedFields}
          />
        </div>
        {company.teamMembers && company.teamMembers.length > 0 && (
          <>
            <h2 className="mb-4">Current Team Members</h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {company?.teamMembers?.map((member, index) => (
                <MemberCard
                  companyId={company.id}
                  member={member}
                  key={member?.id}
                  index={index}
                  type={MemberType.Member}
                  isPublic={false}
                  amITeamMember={true}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TeamMembersAddedContext;
