import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../SectionHeader/SectionHeader";
import Sdg, { sdgIcons } from "molecules/Sdg/Sdg";
import SaveAndCancelButtons from "organisms/ContentEditable/SaveAndCancelButtons";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import { ReactComponent as CloseIcon } from "images/close-icon-bg.svg";
import { refetchCompanyData } from "pages/companyProfile/sagaActions";

interface Props {
  sustainableDevelopmentGoals: {
    items: any[];
  };
  isLoading?: boolean;
  isPublic?: boolean;
  amITeamMember?: boolean;
  title?: boolean;
  companyId?: string;
}

const SDGs: FC<Props> = ({
  sustainableDevelopmentGoals,
  isLoading,
  isPublic,
  amITeamMember,
  title = true,
  companyId,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [sdgsToSave, setSdgsToSave] = useState<number[]>([]);

  const handleAddSdg = (index: number) => {
    setSdgsToSave((prev) => uniq([...prev, index + 1]));
  };
  const handleRemoveSdg = (index: number) => {
    setSdgsToSave((prev) => prev.filter((s) => s !== index));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (sustainableDevelopmentGoals?.items) {
      setSdgsToSave(sustainableDevelopmentGoals.items);
    }
  }, []);

  if (isLoading)
    return (
      <div className="my-4">
        <SectionHeader title="SDG Goals" />
        <Skeleton count={3.6} />
      </div>
    );

  const onSave = () => {
    dispatch(
      updateStartupProfile({
        business: {
          sdg: {
            items: sdgsToSave,
          },
        },
      })
    );
    dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
    setIsEditMode(false);
  };

  const onCancel = () => {
    setIsEditMode(false);
    if (sustainableDevelopmentGoals?.items) {
      setSdgsToSave(sustainableDevelopmentGoals.items);
    }
  };

  return (
    <div className="mb-2 group">
      {title && <SectionHeader title="SDG Goals" />}
      {sdgsToSave ? (
        <div className="flex flex-wrap gap-4 mb-7">
          {sdgsToSave
            ?.sort((a, b) => a - b)
            .map((key, index, item) => (
              <div className="w-28" key={key}>
                <div
                  key={index}
                  className="w-28 m-1 relative"
                  onClick={
                    isEditMode ? () => handleRemoveSdg(item[index]) : undefined
                  }
                >
                  <Sdg key={index} number={item[index]} />
                  {isEditMode && (
                    <CloseIcon className="absolute w-5 h-5 cursor-pointer -right-1 -top-1" />
                  )}
                </div>
              </div>
            ))}
          <div>
            {!isEditMode && !!amITeamMember && !isPublic && (
              <div
                className="flex flex-col items-center h-28 w-28 justify-center p-4 mt-1 border-2 bg-gray-100 border-dashed border-gray-400 rounded-md cursor-pointer"
                onClick={() => setIsEditMode(true)}
              >
                <FontAwesomeIcon
                  icon={["fal", "plus"]}
                  className="text-4xl text-gray-600"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>
          The company does not seem to contribute to solving any Sustainable
          Development Goals.
        </p>
      )}
      {isEditMode && (
        <div className="flex flex-col my-2">
          <div>Choose SDG to add:</div>
          <div className="flex flex-wrap w-full">
            {sdgIcons.map((icon, index) => {
              if (sdgsToSave.includes(index + 1)) return null;
              return (
                <div
                  className="w-14 m-1"
                  onClick={() => handleAddSdg(index)}
                  key={index}
                >
                  <Sdg key={index} number={index + 1} />
                </div>
              );
            })}
          </div>
          <SaveAndCancelButtons
            onSave={onSave}
            onCancel={onCancel}
            className="self-end mt-2"
          />
        </div>
      )}
    </div>
  );
};

export default SDGs;
