import { FC, useState } from "react";
import { Socials } from "./interfaces";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon-2.svg";
import { FieldName, GeneralInfoUiRepresentation } from "gql/graphql";
import withValueEditable from "organisms/ValueEditable/ValueEditable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  socials: Socials;
  uiRepresentation?: GeneralInfoUiRepresentation | null | undefined;
  hideLabel?: boolean;
  fullWidth?: boolean;
  editable?: boolean;
}

export interface ValueProps {
  content: string;
}

const Value: FC<ValueProps> = ({ content }) => {
  return (
    <div className="inline-block overflow-hidden whitespace-nowrap max-w-[180px] mr-12 text-gray-600 truncate">
      {content}
    </div>
  );
};

const EditableValueLinkedIn = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoSocialsLinkedin
);

const EditableValueTwitter = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoSocialsTwitter
);

const EditableValueFacebook = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoSocialsFacebook
);

const EditableValueInstagram = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoSocialsInstagram
);

const SocialMedia: FC<Props> = ({
  socials,
  uiRepresentation,
  hideLabel,
  fullWidth,
  editable = true,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const getContent = (social: string, uiRepresentation: string) => {
    if (uiRepresentation) return uiRepresentation.replace("https://", "");
    if (social) return social.replace("https://", "");
    return "";
  };

  const containerStyles = fullWidth
    ? ""
    : "grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80 industry-div";

  return (
    <>
      <div className={containerStyles}>
        {!hideLabel && <h6 className="font-medium">Social Media</h6>}

        <div className="flex flex-row items-center gap-3">
          {isEdit ? (
            <>
              <div className="flex flex-col gap-y-2">
                <div className="flex flex-row justify-between">
                  <LinkedinIcon className="mr-2 size" />
                  <EditableValueLinkedIn
                    content={getContent(
                      socials?.linkedin,
                      uiRepresentation?.socials?.linkedin ?? ""
                    )}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <FacebookIcon className="mr-2" />
                  <EditableValueFacebook
                    content={getContent(
                      socials?.facebook,
                      uiRepresentation?.socials?.facebook ?? ""
                    )}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <InstagramIcon className="-ml-1 mr-2 h-5 w-6" />
                  <EditableValueInstagram
                    content={getContent(
                      socials?.instagram,
                      uiRepresentation?.socials?.instagram ?? ""
                    )}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <TwitterIcon className="mr-2" />
                  <EditableValueTwitter
                    content={getContent(
                      socials?.twitter,
                      uiRepresentation?.socials?.twitter ?? ""
                    )}
                  />
                </div>
              </div>
              <div className="h-full">
                {isEdit && (
                  <FontAwesomeIcon
                    icon={["fas", "times-circle"]}
                    className="group-hover:block cursor-pointer top-0"
                    color="#f57c67"
                    onClick={() => setIsEdit(false)}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="w-full flex flex-row justify-between">
              <div className="flex flex-row gap-2">
                {(socials?.linkedin || uiRepresentation?.socials?.linkedin) && (
                  <a
                    href={
                      uiRepresentation?.socials?.linkedin || socials?.linkedin
                    }
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </a>
                )}
                {(socials?.instagram ||
                  uiRepresentation?.socials?.instagram) && (
                  <a
                    href={
                      uiRepresentation?.socials?.instagram || socials?.instagram
                    }
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <img src="assets/images/instagram-icon.svg" alt="" />
                  </a>
                )}
                {socials?.facebook && (
                  <a
                    href={socials?.facebook}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </a>
                )}
                {socials?.youtube && (
                  <a
                    href={socials?.youtube}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <YoutubeIcon className="h-4" />
                  </a>
                )}
                {socials?.twitter && (
                  <a
                    href={socials?.twitter}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </a>
                )}
              </div>
              <div>
                {editable && !isEdit && (
                  <FontAwesomeIcon
                    icon={["fal", "edit"]}
                    className="group-hover:block hidden cursor-pointer right-0"
                    onClick={() => setIsEdit(true)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SocialMedia;
