import { MemberType } from "gql/graphql";
import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import TeamMemberCard from "../../aia/Team/TeamMemberCard";
import AddMember from "../../aia/Team/AddNewMember";

interface Props {
  isLoading?: boolean;
  advisors: any[];
  companyId: string;
  isPublic?: boolean;
  amITeamMember: boolean;
}
const RenderAdvisors: FC<Props> = ({
  advisors,
  isLoading,
  companyId,
  isPublic,
  amITeamMember,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  if (isLoading)
    return (
      <div className="grid grid-cols-2 mt-4 gap-x-6">
        <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
        <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
        <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
        <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
      </div>
    );

  if (!advisors || advisors.length === 0) return null;

  const foundInLinkedInadvisors = advisors.filter((m) => !!m?.linkedin);
  const notFoundInLinkedInadvisors = advisors.filter((m) => !m?.linkedin);

  return (
    <div className="mt-4">
      {foundInLinkedInadvisors && foundInLinkedInadvisors.length > 0 && (
        <div className="grid grid-cols-1 gap-2 my-4 md:grid-cols-2">
          {foundInLinkedInadvisors.map((member: any, index: number) => (
            <TeamMemberCard
              companyId={companyId}
              member={member}
              key={member.id}
              index={index}
              type={MemberType.Advisor}
              isPublic={isPublic}
              amITeamMember={amITeamMember}
            />
          ))}

          <AddMember
            companyId={companyId}
            type={MemberType.Advisor}
            isEditMode={isEditMode}
          />
        </div>
      )}

      {notFoundInLinkedInadvisors && notFoundInLinkedInadvisors.length > 0 && (
        <div className="my-2">
          <p className="mb-2 italic">
            Advisors found in pitch deck but not on LinkedIn are shown below
          </p>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
            {notFoundInLinkedInadvisors.map((member: any, index: number) => {
              return (
                <TeamMemberCard
                  companyId={companyId}
                  member={member}
                  key={member.id}
                  index={index}
                  type={MemberType.Advisor}
                  isPublic={isPublic}
                  amITeamMember={amITeamMember}
                />
              );
            })}
            {foundInLinkedInadvisors &&
              foundInLinkedInadvisors.length === 0 && (
                <AddMember
                  companyId={companyId}
                  type={MemberType.Advisor}
                  isEditMode={isEditMode}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RenderAdvisors;
