// @ts-nocheck
import { gql } from "@apollo/client";

/**
 * The id param triggers findById metho in the resolver that finds the object by its mongoId.
 * Changing it breaks some old logic so we keep it for backward compatibility and send a new one called
 * realId that triggerd findByRealId in the resolver which in turn finds the object by its postgres id.
 * Order of precedence: id, readId, slug
 */
export const GET_COMPANY_BY_SLUG = gql`
  query companyBySlug($slug: String!, $id: String, $realId: String) {
    companyBySlug(slug: $slug, id: $id, realId: $realId) {
      id
      _id
      publicUrl
      displayName
      slug
      primaryColor
      pitch
      country
      city
      email
      phone
      founded
      website
      description
      valueProposition
      customers
      pricing
      sourcing {
        linkedInCompany {
          companyUpdates {
            body
            articleLink
            totalLikes
            postedOn
            image
          }
          isCorrectCompany
          companyUrl
        }
        openPositionsLinkedIn {
          id
          date
          jobUrl
          jobTitle
          location
        }
        appstore {
          appInfo {
            appStoreUrl
            description
            histogram
            icon
            numberOfReviews
            numberOfRatings
            currentVersionScore
            screenShots
          }
          reviews {
            userName
            url
            text
            score
            title
            version
          }
          similarApps {
            info {
              appStoreUrl
              description
              title
              icon
              genres
              primaryGenres
              developerWebsite
              currentVersionScore
              numberOfReviews
              reviews {
                userName
                score
                title
              }
            }
          }
        }
        investmentAnalysis {
          pitchbookUrl
          crunchbaseUrl
          fundingRounds {
            name
            moneyRaised
            announcedDate
            leadInvestors
            numberOfInvestors
            investors {
              name
              partners
            }
          }
        }
      }
      finance {
        raisedValue
        raisedTarget
        raisedFrom
        note
        minTicketSize
        investmentStage
        fundraisingCloseOn
      }
      news
      completedMajorSteps
      fullAddress
      businessModel
      businessCategory
      businessCategoryNote
      businessTypeNote
      fit
      expectationsNote
      teamSize
      foundersGender
      teamNote
      companyStage
      valuation
      currency
      businessTiming
      businessModelDescription
      marketOpp
      type
      industries
      banner {
        large {
          location
        }
      }
      logo {
        large {
          location
        }
      }
      sustainableDevelopmentGoals {
        items
        comment
      }
      impact {
        impactNote
      }
      investors {
        id
        name
        displayName
        logo
        linkedin
        image {
          large {
            location
          }
        }
        experience {
          title
          company
          endsAt
        }
      }
      advisors {
        id
        name
        displayName
        logo
        linkedin
        image {
          large {
            location
          }
        }
        experience {
          title
          company
          endsAt
        }
      }
      documents {
        id
        title
        mimetype
        file {
          filename
          location
        }
        filename
        location
      }
      pitchVideoLink
      gallery {
        images {
          large {
            location
          }
        }
      }
      experts {
        name
        areaOfExpertise
      }
      founders {
        id
        name
        email
        title
        linkedin
        employedAt
        education {
          school
          degree
          fieldOfStudy
        }
        experience {
          title
          company
          endsAt
        }
        connections
        repeatFounder {
          company
          companyUrl
        }
        image {
          large {
            location
          }
        }
      }
      investorNote
      teamMembers {
        id
        name
        title
        email
        linkedin
        employedAt
        education {
          school
          degree
          fieldOfStudy
        }
        experience {
          title
          company
          endsAt
        }
        connections
        repeatFounder {
          company
          companyUrl
        }
        image {
          large {
            location
          }
        }
      }
      socials {
        youtube
        facebook
        instagram
        linkedin
        twitter
      }
      meetingLink
      productImages {
        large {
          location
        }
      }
      productDemo
      isIdentityRequired
      amIOwner
      amITeamMember
      score
      traction {
        summary
        verbose
      }
      isAiaGenerated
    }
  }
`;

export const GET_TRACTION = gql`
  query getCompanyTractionByCompanyId($id: ID!) {
    getCompanyTractionByCompanyId(id: $id) {
      _id
      summary
      activeUsers {
        amount
        frequency
      }
      activeUsersNote
      totalUsers {
        type
        count
      }
      totalUsersNote
      churnRate {
        period
        percentage
      }
      churnRateNote
      growthRate {
        period
        amount
        rate
        time
      }
      growthRateNote
      retentionRate {
        period
        amount
        rate
        time
      }
      retentionRateNote
      salePriceAvg {
        amount
        currency
      }
      salePriceAvgNote
      demandFreqNote
      supplyFreq {
        unit
        amount
        frequency
      }
      supplyFreqNote
      signedContracts
      signedContractsNote
      pipelineContracts
      pipelineContractsNote
      annualValueContracts {
        amount
        currency
      }
      annualValueContractsNote
      lettersOfIntent
      lettersOfIntentNote
      additionalTraction
      annualValueContractsPipeline {
        amount
        currency
      }
      annualValueContractsPipelineNote
    }
  }
`;

export const GET_VIEW_SETTINGS = gql`
  query viewSettings {
    me {
      viewSettings {
        isIdentityRequired
      }
    }
  }
`;

export const SAVE_VIEW_SETTINGS = gql`
  mutation saveViewSettings($isIdentityRequired: Boolean!) {
    saveViewSettings(isIdentityRequired: $isIdentityRequired)
  }
`;

export const SAVE_MEETING_LINK = gql`
  mutation saveMeetingLink($link: String!) {
    saveMeetingLink(link: $link)
  }
`;
