import { MemberType } from "gql/graphql";
import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import TeamMemberCard from "../../aia/Team/TeamMemberCard";
import AddMember from "../../aia/Team/AddNewMember";

interface Props {
  isLoading?: boolean;
  investors: any[];
  companyId: string;
  isPublic?: boolean;
  amITeamMember: boolean;
}
const RenderCapTable: FC<Props> = ({
  investors,
  isLoading,
  companyId,
  isPublic,
  amITeamMember,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  if (isLoading)
    return (
      <div className="grid grid-cols-2 mt-4 gap-x-6">
        <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
        <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
        <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
        <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
      </div>
    );

  if (!investors || investors.length === 0) return null;

  const foundInLinkedInInvestors = investors.filter((m) => !!m?.linkedin);
  const notFoundInLinkedInInvestors = investors.filter((m) => !m?.linkedin);

  return (
    <div className="mt-4">
      {foundInLinkedInInvestors && foundInLinkedInInvestors.length > 0 && (
        <div className="grid grid-cols-1 gap-2 my-4 md:grid-cols-2">
          {foundInLinkedInInvestors.map((member: any, index: number) => (
            <TeamMemberCard
              companyId={companyId}
              member={member}
              key={member.id}
              index={index}
              type={MemberType.Investor}
              isPublic={isPublic}
              amITeamMember={amITeamMember}
            />
          ))}
          <AddMember
            companyId={companyId}
            type={MemberType.Investor}
            isEditMode={isEditMode}
          />
        </div>
      )}

      {notFoundInLinkedInInvestors &&
        notFoundInLinkedInInvestors.length > 0 && (
          <div className="my-2">
            <p className="mb-2 italic">
              Investors found in pitch deck but not on LinkedIn are shown below
            </p>
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
              {notFoundInLinkedInInvestors.map((member: any, index: number) => {
                return (
                  <TeamMemberCard
                    companyId={companyId}
                    member={member}
                    key={member.id}
                    index={index}
                    type={MemberType.Investor}
                    isPublic={isPublic}
                    amITeamMember={amITeamMember}
                  />
                );
              })}
              {foundInLinkedInInvestors &&
                foundInLinkedInInvestors.length === 0 && (
                  <AddMember
                    companyId={companyId}
                    type={MemberType.Investor}
                    isEditMode={isEditMode}
                  />
                )}
            </div>
          </div>
        )}
    </div>
  );
};

export default RenderCapTable;
