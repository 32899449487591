import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

interface Props {
  companyName: string;
  startUpName: string;
  onClose: () => void;
}

const AddContextHeader: FC<Props> = ({ companyName, startUpName, onClose }) => (
  <div className="space-y-3 mb-1">
    <div className="space-y-1">
      <div className="flex flex-row justify-between items-center">
        <p className="title-h3">Add context to {companyName}</p>

        <button className="absolute right-5" onClick={onClose}>
          <FontAwesomeIcon
            className="text-[16px] text-[#8B90A6]"
            icon={["fal", "times"]}
          />
        </button>
      </div>

      <p className="body-xs text-[#545b78]">
        Upload documents to update the data of {startUpName}
      </p>
    </div>

    <div className="border-b border-b-[545b78] -mx-6 w-auto" />
  </div>
);

export default AddContextHeader;
