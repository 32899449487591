import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressIndicator from "pages/companyProfile/components/shared/ProgressIndicator/ProgressIndicator";
import { FC } from "react";

interface Props {
  updatedSectionNames: string[];
  currentSectionLabel: string;
  handleOnClickIndicator: (index: number) => void;
  onClose: () => void;
}

const EditContextHeader: FC<Props> = ({
  updatedSectionNames,
  currentSectionLabel,
  handleOnClickIndicator,
  onClose,
}) => (
  <div className="space-y-3 mb-1">
    <div className="flex flex-row justify-center items-center mr-6">
      <ProgressIndicator
        sections={updatedSectionNames}
        currentSection={currentSectionLabel}
        onClickIndicator={handleOnClickIndicator}
      />

      <button className="absolute top-5 right-5" onClick={onClose}>
        <FontAwesomeIcon
          className="text-[16px] text-[#8B90A6]"
          icon={["fal", "times"]}
        />
      </button>
    </div>

    <div className="border-b border-b-[#545b78] border-opacity-20 -mx-6 w-auto" />
  </div>
);

export default EditContextHeader;
