import { FC, useState } from "react";
import MemberAnalysisResult from "./MemberAnalysisResult";
import MemberInfo from "./MemberInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_TEAM_MEMBER_UI_REPRESENTATION,
  UPDATE_AIA_PROFILE_DELETE_MEMBER,
} from "queries/editAiaProfile/editAiaProfile";
import { useDispatch, useSelector } from "react-redux";
import { refetchCompanyData } from "pages/companyProfile/sagaActions";
import Loading from "atoms/Loading/Loading";
import {
  GetTeamMemberUiRepresentationQuery,
  GetTeamMemberUiRepresentationQueryVariables,
  MemberType,
  MemberUiRepresentation,
  UiRepresentation,
} from "gql/graphql";
import { getUser } from "models/user/selectors";
import { mixpanelEditAiaProfile } from "core/mixpanel/Mixpanel";

interface Props {
  companyId: string;
  member: any;
  index: number;
  type: MemberType;
  isPublic?: boolean;
  amITeamMember?: boolean;
  isAddedContext?: boolean;
  uiRepresentationAddedContext?: MemberUiRepresentation | null;
}

const MemberCard: FC<Props> = ({
  companyId,
  member,
  index,
  type,
  isPublic,
  amITeamMember,
  isAddedContext,
  uiRepresentationAddedContext,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const user = useSelector(getUser);

  const dispatch = useDispatch();

  const {
    data: uiData,
    loading: uiLoading,
    refetch,
  } = useQuery<
    GetTeamMemberUiRepresentationQuery,
    GetTeamMemberUiRepresentationQueryVariables
  >(GET_TEAM_MEMBER_UI_REPRESENTATION, {
    skip: isAddedContext,
    variables: {
      memberId: member.id,
      companyId,
      type,
    },
  });

  const [deleteTeamMember, { loading }] = useMutation(
    UPDATE_AIA_PROFILE_DELETE_MEMBER,
    {
      onCompleted() {
        dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
      },
    }
  );

  const handleDelete = () => {
    deleteTeamMember({
      variables: {
        companyId,
        memberId: member.id,
      },
    });
    mixpanelEditAiaProfile(user, companyId, "DeleteTeamMember");
  };

  if (loading || uiLoading) return <Loading />;

  const renderEditableContent = () => {
    return (
      <>
        {!isAddedContext && (
          <div>
            {isEditMode ? (
              <FontAwesomeIcon
                icon={["fal", "trash-alt"]}
                className="absolute right-2 top-2 cursor-pointer text-red-400"
                onClick={() => setShowDeleteConfirmation(true)}
              />
            ) : (
              <FontAwesomeIcon
                icon={["fal", "edit"]}
                className="absolute right-2 top-2 cursor-pointer hidden group-hover:block"
                onClick={() => setIsEditMode(true)}
              />
            )}
          </div>
        )}

        {showDeleteConfirmation && (
          <div className="absolute top-2 right-2 bg-white p-4 text-sm rounded text-center border-2 border-red-200">
            <p>Are you sure you want to delete this company member?</p>
            <button
              className="text-red-400 px-2 pt-2"
              onClick={() => handleDelete()}
            >
              Yes
            </button>
            <button
              className="text-gray-500 px-2 pt-2"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              No
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={cn(
        "flex flex-col p-4 rounded-lg relative group w-full",
        isEditMode && "border border-gray-400 bg-gray-100",
        isAddedContext && "border border-gray-400 bg-aqua-100",
        !isAddedContext && "border border-gray-200 bg-gray-100"
      )}
    >
      {!isPublic && !!amITeamMember && renderEditableContent()}

      <MemberInfo member={member} index={index} />

      <MemberAnalysisResult
        user={user}
        member={member}
        companyId={companyId}
        type={type}
        uiRepresentation={
          uiRepresentationAddedContext || uiData?.getTeamMemberUiRepresentation
        }
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        refetch={refetch}
      />
    </div>
  );
};

export default MemberCard;
