import { FC } from "react";
import { FieldName } from "gql/graphql";
import withValueEditable from "organisms/ValueEditable/ValueEditable";

interface Props {
  content: string;
  hideLabel?: boolean;
  fullWidth?: boolean;
  editable?: boolean;
}

const Value: FC<Props> = ({ content }) => {
  return (
    <a
      className="inline-block hover:underline text-aqua-400 whitespace-pre-wrap"
      href={content}
      target="_blank"
      rel="noreferrer"
    >
      {content}
    </a>
  );
};

const EditableValue = withValueEditable<Props>(
  Value,
  FieldName.GeneralInfoWebsite
);

const Website: FC<Props> = ({
  content,
  hideLabel,
  fullWidth,
  editable = true,
}) => {
  const containerStyles = fullWidth
    ? ""
    : "grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80 industry-div";

  return (
    <div className={containerStyles}>
      {!hideLabel && <h6 className="font-medium">Website</h6>}

      {editable ? (
        <EditableValue content={content} />
      ) : (
        <Value content={content} />
      )}
    </div>
  );
};

export default Website;
