import { FC } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "atoms/Modal/Modal";
import Button from "atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditStatusTable from "./EditStatusList";
import useEditStatusModal from "./useEditStatusModal";
import { EditStatusModalProps } from "./interfaces";

const MAXIMUM_CHARACTER_AMOUNT = 20;

const EditStatusModal: FC<EditStatusModalProps> = (props) => {
  const { onClose } = props;
  const {
    lang,
    newStatus,
    errorMsg,
    isStatusInList,
    listLengthError,
    remaining,
    isUpdating,
    isLoading,
    statusList,
    handleStatusChange,
    onKeyPressed,
    onAddButtonClicked,
    onDeleteStatusClicked,
    handleSubmit,
    handleKeyUp,
    onDragEnd,
  } = useEditStatusModal(props);

  const renderErrorMsg = () => (
    <p className="mt-1 ml-6 text-xs text-fire-400">{errorMsg}</p>
  );

  return (
    <Modal
      width="27rem"
      className="!bg-gray-100 !px-0 !pb-0 !m-0 z-50"
      onClose={onClose}
      hideCloseButton
    >
      <div className="mx-6 font-sans content-container">
        <div className="p-4 border-b border-gray-300 rounded-t">
          <div className="flex justify-between">
            <span className="ml-1 font-bold opacity-75">
              {lang.customizeStatuses}
            </span>
            <button
              type="button"
              className="text-gray-900 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
              aria-label="Close"
              onClick={() => onClose()}
            >
              <FontAwesomeIcon className="text-2xl" icon={["fal", "times"]} />
            </button>
          </div>
          <p className="mt-1 mb-4 ml-1 text-sm">
            Add or remove a status to your dealflow. <br /> Use drag-and-drop to
            change the order of the statuses.
          </p>

          <div className="flex justify-between">
            <span className="ml-1 opacity-75">{lang.addANewStatus}</span>
            <p className="mr-1 text-sm italic text-gray-400">
              {remaining} characters left
            </p>
          </div>
          <div className="flex items-center p-0.5 pl-3 bg-white border border-gray-300 rounded">
            <input
              className="placeholder:italic placeholder:opacity-50 w-full text-sm !text-gray-700 opacity-75"
              type="text"
              placeholder={lang.enterYourNewStatus}
              value={newStatus}
              maxLength={MAXIMUM_CHARACTER_AMOUNT}
              onKeyDown={onKeyPressed}
              onChange={handleStatusChange}
              onKeyUp={handleKeyUp}
            />
            <Button
              className="p-1 !h-8"
              color="primary"
              size="small"
              rectangular
              onClick={onAddButtonClicked}
              disabled={!newStatus || newStatus.trim().length === 0}
            >
              Add
            </Button>
          </div>

          {isStatusInList && (
            <p className="mt-1 ml-2 text-xs text-fire-400">
              {lang.statusAlreadyInList}
            </p>
          )}
          {listLengthError && (
            <p className="mt-1 ml-2 text-xs text-fire-400">
              {lang.youCanOnlyAdd30Statuses}
            </p>
          )}
        </div>
        {renderErrorMsg()}
        {statusList.length > 0 && (
          <div className="p-4 max-h-72 min-h-[14rem] overflow-x-auto no-scrollbar invisible-scroll">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list-container" key="">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {statusList.map((item, index) => (
                      <Draggable
                        key={index.toString()}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <EditStatusTable
                              item={item}
                              onClick={() => onDeleteStatusClicked(item)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>
      <div className="flex justify-center p-4 bg-white border-t border-gray-300 sm:pr-8 sm:justify-end">
        <Button
          className="justify-center mx-2 w-28"
          color="transparent"
          size="small"
          rectangular
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="justify-center mx-2 w-28"
          color="primary"
          size="small"
          rectangular
          loading={isLoading || isUpdating}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
export default EditStatusModal;
