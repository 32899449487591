import { AddedContextResult, FieldName, Startup } from "gql/graphql";
import { FC } from "react";
import withContentEditable from "organisms/ContentEditable/ContentEditable";
import { FieldWrapper, Value, ValueProps } from "../Wrappers/FieldWrapper";
import EditContextWrapper from "../Wrappers/EditContextWrapper";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  setUpdatedFields: (fields: FieldName[]) => void;
  updatedFields: FieldName[];
}

const EditablePitchDeck = withContentEditable<ValueProps>(
  Value,
  FieldName.ExecuteveSummaryElevatorPitch
);

const PitchAddedContext: FC<Props> = ({
  newContext,
  company,
  setUpdatedFields,
  updatedFields,
}) => {
  const config = [
    {
      component: (
        <FieldWrapper
          fieldName={FieldName.MarketOppPitchDeck}
          title={"Elevator Pitch"}
          currentContext={company?.marketOpp ?? ""}
          newContext={newContext?.pitch ?? ""}
          updatedFields={updatedFields}
          setUpdatedFields={setUpdatedFields}
          editableField={(content) => (
            <EditablePitchDeck
              title={"Elevator Pitch"}
              setUpdatedFields={setUpdatedFields}
              updatedFields={updatedFields}
              content={content}
            />
          )}
        />
      ),
      condition: !!newContext?.pitch,
      label: "Elevator Pitch",
    },
  ];

  return <EditContextWrapper header="Elevator Pitch" components={config} />;
};

export default PitchAddedContext;
