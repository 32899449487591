import { FC, useState } from "react";
import { AddedContextResult, MemberType, Startup } from "gql/graphql";
import SectionHeader from "pages/companyProfile/components/shared/SectionHeader/SectionHeader";
import MemberCard from "../../../Team/TeamMemberCard";
import AddMemberAddedContext from "./AddMemberAddedContext";
import AddMember from "../../../Team/AddNewMember";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
}

const AdvisorsAddedContext: FC<Props> = ({ company, newContext }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const newAdvisors = newContext?.team?.advisors ?? [];
  const currentAdvisorIds = company.advisors?.map((f) => f?.id) ?? [];

  return (
    <div className="flex flex-col w-full h-full px-6">
      <div className="ml-6 mb-4 mt-4">
        <SectionHeader title="Advisors" />
      </div>
      <div className="overflow-y-auto px-6">
        <h2 className="mb-4">New Advisors</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mb-4">
          {newAdvisors
            ?.filter((advisor) => currentAdvisorIds.includes(advisor?.id ?? ""))
            .map((member, index) => {
              const memberUi =
                newContext?.team?.teamUiRepresentation?.advisors?.filter(
                  (ui) => ui?.id === member?.id ?? null
                );

              return (
                <div className="inline-flex" key={member?.id}>
                  <MemberCard
                    companyId={company.id}
                    member={member}
                    key={member?.id}
                    index={index}
                    type={MemberType.Advisor}
                    isPublic={false}
                    amITeamMember={true}
                    isAddedContext={true}
                    uiRepresentationAddedContext={memberUi ? memberUi[0] : null}
                  />
                  <div className="relative">
                    <AddMemberAddedContext
                      companyId={company.id}
                      type={MemberType.Advisor}
                      member={member}
                      memberUi={memberUi ? memberUi[0] : null}
                    />
                  </div>
                </div>
              );
            })}
          <AddMember
            companyId={company.id}
            type={MemberType.Advisor}
            isEditMode={isEditMode}
          />
        </div>

        {company.advisors && company.advisors.length > 0 && (
          <>
            <h2 className="mb-4">Current Advisors</h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {company?.advisors?.map((member, index) => (
                <MemberCard
                  companyId={company.id}
                  member={member}
                  key={member?.id}
                  index={index}
                  type={MemberType.Advisor}
                  isPublic={false}
                  amITeamMember={true}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdvisorsAddedContext;
