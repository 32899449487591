import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

import Button from "atoms/Button/Button";
import { cn } from "utils/cn";

interface Props {
  disabledNextButton: boolean;
  isUploading: boolean;
  onAddContext: () => void;
  onClose: () => void;
}

const AddContextFooter: FC<Props> = ({
  disabledNextButton,
  isUploading,
  onAddContext,
  onClose,
}) => (
  <div className="flex flex-row justify-end gap-3 mt-4">
    <Button
      onClick={onClose}
      className="rounded-md h-[36px] bg-white border !border-[#121317] hover:opacity-80"
    >
      <span className="body-xs text-[#545B78]">Cancel</span>
    </Button>

    <Button
      onClick={(): void => onAddContext()}
      disabled={disabledNextButton}
      loading={isUploading}
      className={cn(
        "rounded-md h-[36px] bg-[#121317] gap-2",
        !disabledNextButton && "hover:opacity-80"
      )}
    >
      <span className="body-xs text-[#F3F5FA]">Next</span>
      <FontAwesomeIcon
        className="body-xs text-[#FFFFFF]"
        icon={["fal", "arrow-right"]}
      />
    </Button>
  </div>
);

export default AddContextFooter;
