import { FC, useMemo } from "react";
import { isEqual } from "lodash";

import { AddedContextResult, Startup, UiRepresentation } from "gql/graphql";

import Website from "../../../../GeneralInfo/Website";
import Industry from "../../../../GeneralInfo/Industry";
import HeadOffice from "../../../../GeneralInfo/HeadOffice";
import Product from "../../../../GeneralInfo/Product";
import Founded from "../../../../GeneralInfo/Founded";
import TeamSize from "../../../../GeneralInfo/TeamSize";
import SocialMedia from "../../../../GeneralInfo/SocialMedia";
import GeneralInfoComparisonHeader from "./GeneralInfoComparisonHeader";
import ComparisonField from "./GeneralInfoComparisonField";

interface Props {
  newContext: AddedContextResult;
  company: Startup;
  uiRepresentation: UiRepresentation | null;
  updatedSectionCount?: number;
}

const GeneralInfoAddContext: FC<Props> = ({
  newContext,
  company,
  uiRepresentation,
}) => {
  // INFO: To get only the changed fields from the 'newContext.basics'.
  const changedBasicFields = useMemo((): string[] => {
    let changedFields: string[] = [];

    const getUpdatedBasicInfo = (
      key: string,
      newValue: any,
      originalValue: any
    ) => {
      if (!(!newValue && !originalValue) && !isEqual(newValue, originalValue)) {
        changedFields = [...changedFields, key];
      }
    };

    const newBasicInfo = newContext.basics || {};

    getUpdatedBasicInfo("Website", newBasicInfo?.website, company?.website);
    getUpdatedBasicInfo(
      "Industry",
      newBasicInfo?.industry,
      company?.industries
    );
    getUpdatedBasicInfo("Head Office", "", company?.city);
    getUpdatedBasicInfo(
      "Product",
      newBasicInfo?.productCategory,
      company?.businessCategory
    );
    getUpdatedBasicInfo("Founded", "", company?.founded);
    getUpdatedBasicInfo("Team Size", "", company?.teamSize);
    getUpdatedBasicInfo("Socials", newBasicInfo?.socials, company?.socials);

    return changedFields;
  }, [company, newContext.basics]);

  return (
    <div className="w-full h-full">
      <div className="flex flex-row items-center gap-2 mt-4">
        <p className="title-h3 text-[#545b78]">
          Comparison of new and current data
        </p>

        <p className="body-xs text-[#0038B0] !font-medium w-fit bg-[#E7EFFF] px-2 py-[2px] rounded-2xl">
          {changedBasicFields?.length} Updates
        </p>
      </div>

      <GeneralInfoComparisonHeader />

      {/* Website */}
      <ComparisonField
        fieldName="Website"
        renderNewSection={(otherProps) => (
          <Website
            content={newContext?.basics?.website ?? ""}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <Website content={company?.website ?? ""} {...otherProps} />
        )}
      />

      {/* Industry */}
      <ComparisonField
        fieldName="Industry"
        renderNewSection={(otherProps) => (
          <Industry
            industries={newContext?.basics?.industry ?? []}
            uiRepresentation={uiRepresentation?.generalInfo?.industry}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <Industry industries={company?.industries ?? []} {...otherProps} />
        )}
      />

      {/* Head Office */}
      <ComparisonField
        fieldName="Head Office"
        renderNewSection={(otherProps) => (
          <HeadOffice
            city=""
            fullAddress=""
            uiRepresentation={uiRepresentation?.generalInfo?.headOffice}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <HeadOffice
            city={company?.city ?? ""}
            fullAddress={company?.fullAddress ?? ""}
            {...otherProps}
          />
        )}
      />

      {/* Product */}
      <ComparisonField
        fieldName="Product"
        renderNewSection={(otherProps) => (
          <Product
            businessCategory={newContext?.basics?.productCategory ?? []}
            uiRepresentation={uiRepresentation?.generalInfo?.product}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <Product
            businessCategory={company?.businessCategory ?? []}
            businessCategoryNote={company?.businessCategoryNote ?? ""}
            {...otherProps}
          />
        )}
      />

      {/* Founded */}
      <ComparisonField
        fieldName="Founded"
        renderNewSection={(otherProps) => (
          <Founded
            founded=""
            uiRepresentation={uiRepresentation?.generalInfo?.founded}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <Founded founded={company?.founded ?? ""} {...otherProps} />
        )}
      />

      {/* Team Size */}
      <ComparisonField
        fieldName="Team Size"
        renderNewSection={(otherProps) => (
          <TeamSize
            teamSize=""
            uiRepresentation={uiRepresentation?.generalInfo?.teamSize}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <TeamSize teamSize={company?.teamSize ?? ""} {...otherProps} />
        )}
      />

      {/* Social Media */}
      <ComparisonField
        fieldName="Social Media"
        renderNewSection={(otherProps) => (
          <SocialMedia
            socials={newContext?.basics?.socials ?? ({} as any)}
            {...otherProps}
          />
        )}
        renderCurrentSection={(otherProps) => (
          <SocialMedia
            socials={company?.socials ?? ({} as any)}
            {...otherProps}
          />
        )}
      />
    </div>
  );
};

export default GeneralInfoAddContext;
